import React from "react";

import AppointmintsModal from "../../components/Appointments/ApointmintsModalBox";
import Counts from "./Counts";
import "./dashboard.css";
import Filters from "./Filters";

const DashBoardPage = () => {
  return (
    <div className="dashBoardMainPage">
      {/* <TopSearchBar /> */}
      <h1>Dashboard</h1>
      <Counts />
      <div className="borderDash">
        <hr />
      </div>
      {/* <Filters /> */}
      <div className="appointments">
        <AppointmintsModal
          name="Upcoming Appointment"
          heading2="Date"
          heading3="Specialty"
          src="/images/upcoming.svg"
          endpoint="upcoming-appointments"
        />
        <AppointmintsModal
          name="Missed Appointments"
          heading2="Appt.date"
          heading3="Phone Number"
          src="/images/calling.svg"
          endpoint="missed-appointments"
        />

        <AppointmintsModal
          name="Calling List"
          heading2="Time"
          heading3="Specialty"
          src="/images/missed.svg"
          endpoint="calling-list"
        />
        <AppointmintsModal
          name="Flag Analysis"
          heading2="Status"
          src="/images/missed.svg"
          endpoint="flag-analysis"
        />
      </div>
    </div>
  );
};

export default DashBoardPage;
