import { AxiosBase } from ".";

function getToken() {
  return localStorage.getItem("token");
}

const headerToken = () => ({
  headers: {
    "x-access-token": getToken(),
  },
});

export const loginUser = (data) => AxiosBase.post("/auth/login", data);

export const myProfile = (token) =>
  AxiosBase.get("/auth/my-profile", headerToken());

export const getAllPatients = () =>
  AxiosBase.post("/appointments/all-patients", {}, headerToken());

export const getDashboardCounts = (get) =>
  AxiosBase.get(`/dashboard/${get}`, headerToken());

export const getPatientId = () =>
  AxiosBase.post("/appointments/pid", {}, headerToken());

export const getUtilities = (endpoint) =>
  AxiosBase.get(`/utilities/${endpoint}`, headerToken());

export const addPatient = (data) =>
  AxiosBase.post("/appointments/new-patient", data, headerToken());

export const updatePatient = (data, id) =>
  AxiosBase.patch(`/appointments/update-patient/${id}`, data, headerToken());

export const getAppointments = () =>
  AxiosBase.get("/appointments/list", headerToken());

export const getSpecialistsForAppointments = (id) =>
  AxiosBase.get(`/appointments/list-patients/${id}`, headerToken());

export const getSpecialistsForFlaganalysis = (id, appointmentType) =>
  AxiosBase.get(
    `/appointments/filter-specialists/${id}&${appointmentType}`,
    headerToken()
  );

  export const getSpecialitiesForFlaganalysis = (id, appointmentType) =>
  AxiosBase.get(
    `/appointments/filter-speciality/${id}&${appointmentType}`,
    headerToken()
  );

  export const getAllStatusPatients = () =>
  AxiosBase.get("/appointments/get-discharged-patients", {}, headerToken());

export const updatePatientStatus = (id, status) =>
  AxiosBase.patch(
    `/appointments/update-patient-status/${id}&${status}`,
    headerToken()
  );

export const getAllSpecialist = () =>
  AxiosBase.get("/utilities/specialists", headerToken());

export const getAllSpeciality = () =>
  AxiosBase.get("/utilities/specialities", headerToken());

export const getMriPatients = () =>
  AxiosBase.get("/dashboard/get-mri-appointment", headerToken());

export const getMriAttorny = () =>
  AxiosBase.get("/dashboard/get-attorney", headerToken());

export const newSpecialist = (data) =>
  AxiosBase.post("/utilities/create-specialist", data, headerToken());

export const newSpeciality = (data) =>
  AxiosBase.post("/utilities/create-speciality", data, headerToken());

export const updateSpecialist = (data) =>
  AxiosBase.patch(`/utilities/update-util`, data, headerToken());

export const updateSpeciality = (data, id) =>
  AxiosBase.patch(`/utilities/update-speciality/${id}`, data, headerToken());

export const deleteSpecialist = (id) =>
  AxiosBase.delete(`/utilities/delete-specialist/${id}`, headerToken());

export const deleteSpeciality = (id) =>
  AxiosBase.delete(`/utilities/delete-speciality/${id}`, headerToken());

export const deleteMri = (id) =>
  AxiosBase.delete(`/utilities/delete-mri/${id}`, headerToken());

export const deleteAttorney = (id) =>
  AxiosBase.delete(`/utilities/delete-attorney/${id}`, headerToken());

export const addUtil = (data, endpoint) =>
  AxiosBase.post(`/utilities/${endpoint}`, data, headerToken());

export const updateMri = (data, id) =>
  AxiosBase.patch(`/utilities/update-mrifacility/${id}`, data, headerToken());

export const updateAttornity = (data, id) =>
  AxiosBase.patch(`/utilities/update-attorney/${id}`, data, headerToken());

export const getDashboarddList = (endpoint) =>
  AxiosBase.get(`/dashboard/${endpoint}`, headerToken());

export const createAppointment = (type, data) =>
  AxiosBase.post(
    `/appointments/create-appointment/${type}`,
    data,
    headerToken()
  );

export const UpdateAppointmentById = (type, data) =>
  AxiosBase.put(
    `/appointments/update-appointment/${type}`,
    data,
    headerToken()
  );

export const updateAppointmentStatus = (type, data) =>
  AxiosBase.patch(`/appointments/${type}`, data, headerToken());

export const createNueroAppointment = (type, data) =>
  AxiosBase.post(
    `/appointments/nuero-appointment/${type}`,
    data,
    headerToken()
  );

export const updateNueroAppointmentById = (type, data) =>
  AxiosBase.post(
    `/appointments/update-appointment/${type}`,
    data,
    headerToken()
  );

export const createMriAppointment = (type, data) =>
  AxiosBase.post(`/appointments/mri-Appointment/${type}`, data, headerToken());

export const createOrthuAppointment = (type, data) =>
  AxiosBase.post(
    `/appointments/ortho-appointment/${type}`,
    data,
    headerToken()
  );

export const UpdateOrthuAppointmentById = (type, data) =>
  AxiosBase.put(
    `/appointments/update-appointment/${type}`,
    data,
    headerToken()
  );

export const createPainAppointment = (type, data) =>
  AxiosBase.post(`/appointments/pain-appointment/${type}`, data, headerToken());

export const updatePainAppointmentbyId = (type, data) =>
  AxiosBase.post(
    `/appointments/update-appointment/${type}`,
    data,
    headerToken()
  );

export const createSpinalAppointment = (type, data) =>
  AxiosBase.post(
    `/appointments/spinal-appointment/${type}`,
    data,
    headerToken()
  );
export const createNurseAppointment = (type, data) =>
  AxiosBase.post(
    `/appointments/nurse-appointment/${type}`,
    data,
    headerToken()
  );

export const updateNurseAppointmentbyId = (type, data) =>
  AxiosBase.post(
    `/appointments/update-appointment/${type}`,
    data,
    headerToken()
  );

export const updateAppointment = (data) =>
  AxiosBase.patch(
    `/appointments/update/${data?._id}`,
    data.appointment,
    headerToken()
  );

export const getAllReports = (key) =>
  AxiosBase.get(`/dashboard/all-${key}-appointments`, headerToken());

export const getReports = (key) =>
  AxiosBase.get(`/dashboard/all-appointments`, headerToken());
