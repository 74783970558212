import axios from "axios";

export const AxiosBase = axios.create({
  baseURL:"http://app.spinesportschiro.com/api"
  // baseURL: "http://54.227.197.145/api",
  // baseURL: "http://10.10.50.212:3500/api",
});

export const setAuthToken = (token) => {    
  if (token) {
    AxiosBase.defaults.headers.common["x-access-token"] = token;

    return AxiosBase;
  } else {
    return AxiosBase.defaults.headers;
  }
};
