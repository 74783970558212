import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox } from "antd";
import DatePicker from "../../../components/datePicker/DatePicker";
import MriFacility from "../../../components/MriFacility";
import { useAlert } from "react-alert";
import {
  createMriAppointment,
  updateAppointment,
  updateAppointmentStatus,
} from "../../../api/functions";
import { getAllAppointments, getPatients } from "../../../redux/records";
import { set } from "lodash";
const MakeMRIappt = () => {
  const {
    selectedPatient: { appointment },
  } = useSelector((state) => state.records);
  const { selectedPatient } = useSelector((state) => state.records);
  console.log("appointment", appointment);

  const [data, setData] = useState({});
  const [cervicalFacility, setCervicalFacility] = useState(
    appointment?.cervical?.facility
  );
  const [lumberalFacility, setLumberalFacility] = useState(
    appointment?.lumberal?.facility
  );
  const [initial1Facility, setinitial1Facility] = useState(
    appointment?.mriAppointment?.initial[0]?.facility
  );
  const [initial2Facility, setinitial2Facility] = useState(
    appointment?.mriAppointment?.initial[1]?.facility
  );
  const [initial3Facility, setinitial3Facility] = useState(
    appointment?.mriAppointment?.initial[2]?.facility
  );
  const [dates, setDates] = useState({
    CervicalDate: appointment?.cervical?.date,
    LumberlDate: appointment?.lumberal?.date,
    ExtremityDate: null,
  });
  const dateCallBack = (key) => (value) =>
    setDates((prevDates) => ({ ...prevDates, [key]: value }));
  const [extremityData, setextremityData] = useState({});
  const [lumberData, setLumberData] = useState({});
  const onFacilityChange = ({ target: { value, name } }) =>
    setData((prev) => ({ ...prev, [name]: value }));
  const [currentApp, setCurrentApp] = useState({});

  useEffect(() => {
    if (appointment?.mri) setData(appointment.mri);
    if (appointment?.lumber) setLumberData(appointment?.lumber);
    else setData({});
  }, [appointment]);

  const toast = useAlert();
  const dispatch = useDispatch();

  const createUpdateAppointment = async (
    type,
    date,
    followUp,
    service,
    facility,
    scanDone,
    reportReceived
  ) => {
    try {
      await createMriAppointment(type, {
        patientId: selectedPatient?._id,
        date: date,
        // initial:followUp,
        service: service,
        status: scanDone ? "attended" : "upcoming",
        facility: facility,
        scanDone: scanDone,
        reportReceived: reportReceived,
      });
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };
  const createUpdateStatusAppointment = async (
    type,
    AppointmentType,
    followUpid,
    status
  ) => {
    try {
      await updateAppointmentStatus(type, {
        patientId: selectedPatient?._id,
        type: AppointmentType,
        service: "extremity",
        initialId: followUpid,
        status: status,
      });
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  return (
    <div className="mriApptMain">
      <form className="formMri">
        <div className="firstMriRow">
          <h3>Cervical</h3>

          <div className="mriCheckBox">
            <label>No Cervical MRI</label>
            <Checkbox
              id="cervical"
              for="cervical"
              onChange={async ({ target: { checked } }) => {
                try {
                  await createMriAppointment("mriAppointment", {
                    patientId: selectedPatient?._id,
                    service: "cervical",
                    skip: checked,
                    NocervicalRequired: checked,
                  });
                  toast.success("Success");
                  dispatch(getAllAppointments());
                  dispatch(getPatients());
                } catch (error) {
                  toast.error(error.response?.data?.message || error.message);
                }
              }}
              disabled={appointment?.cervical?.date}
              checked={appointment?.mriAppointment?.NocervicalRequired}
            />
            {/* <Checkbox
              id="lumber"
              for="lumber"
              onChange={async ({ target: { checked } }) => {
                try {
                  await createMriAppointment("mriAppointment", {
                    patientId: selectedPatient?._id,
                    service: "lumberal",
                    skip: "true",
                    NolumberalRequired: checked,
                  });
                  toast.success("Success");
                  dispatch(getAllAppointments());
                  dispatch(getPatients());
                } catch (error) {
                  toast.error(error.response?.data?.message || error.message);
                }
              }}
              disabled={appointment?.lumberal?.date}
              checked={appointment?.mriAppointment?.NolumberalRequired}
            /> */}
          </div>

          <div className="firstRowInput">
            <div className="inputsMriFirstRow">
              <div className="inputsmri">
                <MriFacility
                  defultValue={appointment?.cervical?.facility}
                  disabled={
                    appointment?.mriAppointment?.NocervicalRequired ||
                    appointment?.cervical?.date
                  }
                  name={"cervicalMri"}
                  onChange={({ target: { value, name } }) =>
                    setCervicalFacility(value)
                  }
                />
              </div>
              <div className="inputsmri">
                <label>Scheduled date</label>

                <DatePicker
                  selected={dates?.CervicalDate || appointment?.cervical?.date}
                  // minDate={new Date(appointment?.date)}
                  className="iconHeightformsmri"
                  onChange={dateCallBack("CervicalDate")}
                  disabled={
                    appointment?.mriAppointment?.NocervicalRequired ||
                    appointment?.cervical?.date
                  }
                />
              </div>
              <div
                className="inputsmriCheckBox"
                style={{
                  width: "5%",
                }}
              >
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    createUpdateAppointment(
                      "mriAppointment",
                      dates?.CervicalDate,
                      "initial",
                      "cervical",
                      cervicalFacility,
                      data?.CervicalscanDone,
                      data?.CervicalreportReceived
                    );
                  }}
                  disabled={
                    appointment?.mriAppointment?.NocervicalRequired ||
                    appointment?.cervical?.date
                  }
                >
                  Save
                </Button>
              </div>
              <div className="inputsmriCheckBox">
                <label>Scan done</label>
                <Checkbox
                  id="scan1"
                  for="scan1"
                  onChange={
                    ({ target: { checked } }) =>
                      createUpdateAppointment(
                        "mriAppointment",
                        appointment?.cervical?.date,
                        "initial",
                        "cervical",
                        appointment?.cervical?.facility,
                        checked,
                        appointment?.cervical?.reportReceived
                      )

                    // setData((prev) => ({ ...prev, CervicalscanDone: checked }))
                  }
                  checked={
                    data?.CervicalscanDone || appointment?.cervical?.scanDone
                  }
                  disabled={appointment?.mriAppointment?.NocervicalRequired}
                />
                <label style={{ marginLeft: "30px" }}>Report received</label>
                <Checkbox
                  checked={
                    data?.CervicalreportReceived ||
                    appointment?.cervical?.reportReceived
                  }
                  id="report1"
                  for="report1"
                  onChange={({ target: { checked } }) =>
                    createUpdateAppointment(
                      "mriAppointment",
                      appointment?.cervical?.date,
                      "initial",
                      "cervical",
                      appointment?.cervical?.facility,
                      appointment?.cervical?.scanDone,
                      checked
                    )
                  }
                  disabled={appointment?.mriAppointment?.NocervicalRequired}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="barwithDateMRI"></div>
        <div className="firstMriRow">
          <h3>Lumberl</h3>

          <div className="mriCheckBox">
            <label>No Lumber MRI</label>
            <Checkbox
              id="lumber"
              for="lumber"
              onChange={async ({ target: { checked } }) => {
                try {
                  await createMriAppointment("mriAppointment", {
                    patientId: selectedPatient?._id,
                    service: "lumberal",
                    skip: "true",
                    NolumberalRequired: checked,
                  });
                  toast.success("Success");
                  dispatch(getAllAppointments());
                  dispatch(getPatients());
                } catch (error) {
                  toast.error(error.response?.data?.message || error.message);
                }
              }}
              disabled={appointment?.lumberal?.date}
              checked={appointment?.mriAppointment?.NolumberalRequired}
            />
          </div>

          <div className="firstRowInput">
            <div className="inputsMriFirstRow">
              <div className="inputsmri">
                <MriFacility
                  defultValue={lumberalFacility}
                  disabled={
                    appointment?.mriAppointment?.NolumberalRequired ||
                    appointment?.lumberal?.date
                  }
                  onChange={({ target: { value, name } }) =>
                    setLumberalFacility(value)
                  }
                />
              </div>
              <div className="inputsmri">
                <label>Scheduled date</label>
                <DatePicker
                  selected={dates?.LumberlDate || appointment?.lumberal?.date}
                  disabled={
                    appointment?.mriAppointment?.NolumberalRequired ||
                    appointment?.lumberal?.date
                  }
                  onChange={dateCallBack("LumberlDate")}
                  className="iconHeightformsmri"
                />
              </div>
              <div
                className="inputsmriCheckBox"
                style={{
                  width: "5%",
                }}
              >
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    createUpdateAppointment(
                      "mriAppointment",
                      dates?.CervicalDate,
                      "initial",
                      "lumberal",
                      lumberalFacility,
                      data?.lumberalcanDone,
                      data?.lumberalreportReceived
                    );
                  }}
                  disabled={
                    appointment?.mriAppointment?.NolumberalRequired ||
                    appointment?.lumberal?.date
                  }
                >
                  Save
                </Button>
              </div>
              <div className="inputsmriCheckBox">
                <label>Scan done</label>
                <Checkbox
                  disabled={appointment?.mriAppointment?.NolumberalRequired}
                  checked={
                    data?.lumberalScanDone || appointment?.lumberal?.scanDone
                  }
                  onChange={({ target: { checked } }) =>
                    createUpdateAppointment(
                      "mriAppointment",
                      appointment?.lumberal?.date,
                      "initial",
                      "lumberal",
                      appointment?.lumberal?.facility,
                      checked,
                      appointment?.lumberal?.reportReceived
                    )
                  }
                  name="scanDone"
                  id="scan23"
                  for="scan23"
                />

                <label style={{ marginLeft: "30px" }}>Report received</label>
                <Checkbox
                  disabled={appointment?.mriAppointment?.NolumberalRequired}
                  checked={
                    data?.lumberalreportReceived ||
                    appointment?.lumberal?.reportReceived
                  }
                  onChange={({ target: { checked } }) =>
                    createUpdateAppointment(
                      "mriAppointment",
                      appointment?.lumberal?.date,
                      "initial",
                      "lumberal",
                      appointment?.lumberal?.facility,
                      appointment?.lumberal?.scanDone,
                      checked
                    )
                  }
                  name="reportReceived"
                  id="report23"
                  for="report23"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="barwithDateMRI"></div>
        <div className="firstMriRow">
          <h3>Extremity/Thoracic/Brain</h3>

          <div className="mriCheckBox">
            <label>No extremity MRI</label>
            <Checkbox
              onChange={async ({ target: { checked } }) => {
                try {
                  await createMriAppointment("mriAppointment", {
                    patientId: selectedPatient?._id,
                    service: "extremity",
                    skip: "true",
                    NoextremityRequired: checked,
                  });
                  toast.success("Success");
                  dispatch(getAllAppointments());
                  dispatch(getPatients());
                } catch (error) {
                  toast.error(error.response?.data?.message || error.message);
                }
              }}
              disabled={appointment?.mriAppointment?.initial[0]}
              checked={appointment?.mriAppointment?.NoextremityRequired}
              id="extermicity"
              for="extermicity"
            />
          </div>
          <div className="firstRowInput">
            <div className="inputsMriFirstRow">
              <div className="inputsmriExtra">
                <MriFacility
                  defultValue={
                    appointment?.mriAppointment?.initial[0]?.facility ||
                    initial1Facility
                  }
                  disabled={
                    appointment?.mriAppointment?.NoextremityRequired ||
                    appointment?.mriAppointment?.initial[0]
                  }
                  onChange={({ target: { value, name } }) =>
                    setinitial1Facility(value)
                  }
                />
              </div>
              <div className="inputsmriExtra">
                <label>Scheduled date</label>
                <DatePicker
                  selected={
                    dates?.intial1 ||
                    appointment?.mriAppointment?.initial[0]?.date
                  }
                  disabled={
                    appointment?.mriAppointment?.NoextremityRequired ||
                    appointment?.mriAppointment?.initial[0]
                  }
                  onChange={dateCallBack("intial1")}
                  className="iconHeightformsmri"
                />
              </div>
              <div
                className="inputsmriCheckBox"
                style={{
                  width: "5%",
                }}
              >
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    createUpdateAppointment(
                      "mriAppointment",
                      dates?.intial1,
                      "initial1",
                      "extremity",
                      initial1Facility,
                      false,
                      false
                    );
                  }}
                  disabled={
                    appointment?.mriAppointment?.NoextremityRequired ||
                    appointment?.mriAppointment?.initial[0]
                  }
                >
                  Save
                </Button>
              </div>
              <div className="inputsmriCheckBox">
                <label>Scan done</label>
                <Checkbox
                  checked={appointment?.mriAppointment?.initial[0]?.scanDone}
                  disabled={appointment?.mriAppointment?.NoextremityRequired}
                  onChange={({ target: { checked } }) =>
                    createUpdateStatusAppointment(
                      "updateMRI",
                      "mriAppointment",
                      appointment?.mriAppointment?.initial[0]?._id,
                      checked ? "attended" : "missed"
                    )
                  }
                  id="scan3"
                  for="scan3"
                />

                <label style={{ marginLeft: "30px" }}>Report received</label>
                <Checkbox
                  checked={
                    appointment?.mriAppointment?.initial[0]?.reportReceived
                  }
                  disabled={appointment?.mriAppointment?.NoextremityRequired}
                  onChange={async ({ target: { checked } }) => {
                    try {
                      await updateAppointmentStatus("updateMRI", {
                        patientId: selectedPatient?._id,
                        type: "mriAppointment",
                        status: appointment?.mriAppointment?.initial[0]?.status,
                        service: "extremity",
                        initialId: appointment?.mriAppointment?.initial[0]?._id,
                        reportReceived: checked,
                      });
                      toast.success("Success");
                      dispatch(getAllAppointments());
                      dispatch(getPatients());
                    } catch (error) {
                      toast.error(
                        error.response?.data?.message || error.message
                      );
                    }
                  }}
                  id="report3"
                  for="report3"
                />
              </div>
            </div>
          </div>
          <div className="firstRowInput">
            <div className="inputsMriFirstRow">
              <div className="inputsmriExtra">
                <MriFacility
                  defultValue={
                    appointment?.mriAppointment?.initial[1]?.facility ||
                    initial2Facility
                  }
                  disabled={
                    appointment?.mriAppointment?.NoextremityRequired ||
                    appointment?.mriAppointment?.initial[1]
                  }
                  onChange={({ target: { value, name } }) =>
                    setinitial2Facility(value)
                  }
                />
              </div>
              <div className="inputsmriExtra">
                <label>Scheduled date</label>
                <DatePicker
                  selected={
                    dates?.intial2 ||
                    appointment?.mriAppointment?.initial[1]?.date
                  }
                  disabled={
                    appointment?.mriAppointment?.NoextremityRequired ||
                    appointment?.mriAppointment?.initial[1]
                  }
                  onChange={dateCallBack("intial2")}
                  className="iconHeightformsmri"
                />
              </div>
              <div
                className="inputsmriCheckBox"
                style={{
                  width: "5%",
                }}
              >
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    createUpdateAppointment(
                      "mriAppointment",
                      dates?.intial2,
                      "initial2",
                      "extremity",
                      initial2Facility,
                      false,
                      false
                    );
                  }}
                  disabled={
                    appointment?.mriAppointment?.NoextremityRequired ||
                    appointment?.mriAppointment?.initial[1]
                  }
                >
                  Save
                </Button>
              </div>
              <div className="inputsmriCheckBox">
                <label>Scan done</label>
                <Checkbox
                  checked={appointment?.mriAppointment?.initial[1]?.scanDone}
                  disabled={appointment?.mriAppointment?.NoextremityRequired}
                  onChange={({ target: { checked } }) =>
                    createUpdateStatusAppointment(
                      "updateMRI",
                      "mriAppointment",
                      appointment?.mriAppointment?.initial[1]?._id,
                      checked ? "attended" : "missed"
                    )
                  }
                  id="scan3"
                  for="scan3"
                />

                <label style={{ marginLeft: "30px" }}>Report received</label>
                <Checkbox
                  checked={
                    appointment?.mriAppointment?.initial[1]?.reportReceived
                  }
                  disabled={appointment?.mriAppointment?.NoextremityRequired}
                  onChange={async ({ target: { checked } }) => {
                    try {
                      await updateAppointmentStatus("updateMRI", {
                        patientId: selectedPatient?._id,
                        type: "mriAppointment",
                        status: appointment?.mriAppointment?.initial[1]?.status,
                        service: "extremity",
                        initialId: appointment?.mriAppointment?.initial[1]?._id,
                        reportReceived: checked,
                      });
                      toast.success("Success");
                      dispatch(getAllAppointments());
                      dispatch(getPatients());
                    } catch (error) {
                      toast.error(
                        error.response?.data?.message || error.message
                      );
                    }
                  }}
                  id="report3"
                  for="report3"
                />
              </div>
            </div>
          </div>
          <div className="firstRowInput">
            <div className="inputsMriFirstRow">
              <div className="inputsmriExtra">
                <MriFacility
                  defultValue={
                    appointment?.mriAppointment?.initial[2]?.facility ||
                    initial3Facility
                  }
                  disabled={
                    appointment?.mriAppointment?.NoextremityRequired ||
                    appointment?.mriAppointment?.initial[2]
                  }
                  onChange={({ target: { value, name } }) =>
                    setinitial3Facility(value)
                  }
                />
              </div>
              <div className="inputsmriExtra">
                <label>Scheduled date</label>
                <DatePicker
                  selected={
                    dates?.intial3 ||
                    appointment?.mriAppointment?.initial[2]?.date
                  }
                  disabled={
                    appointment?.mriAppointment?.NoextremityRequired ||
                    appointment?.mriAppointment?.initial[2]
                  }
                  onChange={dateCallBack("intial3")}
                  className="iconHeightformsmri"
                />
              </div>
              <div
                className="inputsmriCheckBox"
                style={{
                  width: "5%",
                }}
              >
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    createUpdateAppointment(
                      "mriAppointment",
                      dates?.intial3,
                      "initial3",
                      "extremity",
                      initial3Facility,
                      false,
                      false
                    );
                  }}
                  disabled={
                    appointment?.mriAppointment?.NoextremityRequired ||
                    appointment?.mriAppointment?.initial[2]
                  }
                >
                  Save
                </Button>
              </div>
              <div className="inputsmriCheckBox">
                <label>Scan done</label>
                <Checkbox
                  checked={appointment?.mriAppointment?.initial[2]?.scanDone}
                  disabled={appointment?.mriAppointment?.NoextremityRequired}
                  onChange={({ target: { checked } }) =>
                    createUpdateStatusAppointment(
                      "updateMRI",
                      "mriAppointment",
                      appointment?.mriAppointment?.initial[2]?._id,
                      checked ? "attended" : "missed"
                    )
                  }
                  id="scan3"
                  for="scan3"
                />

                <label style={{ marginLeft: "30px" }}>Report received</label>
                <Checkbox
                  checked={
                    appointment?.mriAppointment?.initial[2]?.reportReceived
                  }
                  disabled={appointment?.mriAppointment?.NoextremityRequired}
                  onChange={async ({ target: { checked } }) => {
                    try {
                      await updateAppointmentStatus("updateMRI", {
                        patientId: selectedPatient?._id,
                        type: "mriAppointment",
                        status: appointment?.mriAppointment?.initial[2]?.status,
                        service: "extremity",
                        initialId: appointment?.mriAppointment?.initial[2]?._id,
                        reportReceived: checked,
                      });
                      toast.success("Success");
                      dispatch(getAllAppointments());
                      dispatch(getPatients());
                    } catch (error) {
                      toast.error(
                        error.response?.data?.message || error.message
                      );
                    }
                  }}
                  id="report3"
                  for="report3"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mdBtnSave" type="button"></div>
      </form>
    </div>
  );
};

export default MakeMRIappt;
