import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Outlet } from "react-router";
import ReactDatePicker from "react-datepicker";

import TypeOfAppointment from "./appointTypes/TypeOfAppointment";

import "./appointTypes/appointtype.css";
import PatientDropDown from "../../components/PatientDropDown/PatientDropDown";

const CreateNewAppointment = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      {/* <TopSearchBar /> */}
      <div className="appointHeading">
        <div className="headingApp">
          <div className="arrowBack">
            <Link to="/appointment" className="imgLink">
              <img src="/images/arrowLeft.svg" alt="back Arrow" />
            </Link>
            <h1>Appointments</h1>
          </div>
        </div>
        <div className="appointUrl">
          <p>
            Appointment /All Appointment /<span> Create New Appointment </span>
          </p>
        </div>
        <PatientDropDown />
      </div>

      <TypeOfAppointment />

      <Outlet />
    </>
  );
};

export default CreateNewAppointment;
