import { configureStore } from "@reduxjs/toolkit";
import { recordsSlice } from "./records";
import { showModal } from "./showingModel";
import { authSlice, redirectActions } from "./slices";
const store = configureStore({
  reducer: {
    modal: showModal.reducer,
    auth: authSlice.reducer,
    redirect: redirectActions.reducer,
    records: recordsSlice.reducer,
  },
  devTools: true,
});
export default store;
