import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { getAllReports, getReports } from "../../api/functions";
// import './report.css'
import {
  getSpecialistsForFlaganalysis,
  getSpecialitiesForFlaganalysis,
} from "../../api/functions";

const ReportTop = (props, { setData }) => {
  const { appointments, specialist, specialities } = useSelector(
    (state) => state.records
  );
  const [appointmentType, setappointmentType] = useState("all");
  const [specialistId, setSpecialistId] = useState(specialist[0]?._id);
  const [specialitytId, setSpecialitytId] = useState("all");
  const [missedAppointments, setMissedAppointments] = useState([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const toast = useAlert();

  // console.log("upcomingAppointments", upcomingAppointments);
  console.log("specialities", specialities);
  console.log("specialist", specialist);

  const formate = (data) => {
    let arr = data?.map((item) => {
      let value = {};
      value = {
        patientId: item?.patientId?.patientId,
        specialist: item?.patientId?.specialist,
        firstName: item?.patientId?.firstName,
        lastName: item?.patientId?.lastName,
        phoneNumber: item?.patientId?.phoneNumber,
        internal:
          item?.mdAppointment?.initial.length > 0
            ? new Date(
                item?.mdAppointment?.initial[
                  item?.mdAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[
                item?.mdAppointment?.initial?.length - 1
              ]?.status
            : "",
        OMRI1:
          item?.mriAppointment?.initial.length > 0
            ? new Date(
                item?.mriAppointment?.initial[0]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[0]?.status
            : "",
        OMRI2:
          item?.mriAppointment?.initial.length > 1
            ? new Date(
                item?.mriAppointment?.initial[1]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[1]?.status
            : "",
        OMRI3:
          item?.mriAppointment?.initial.length > 2
            ? new Date(
                item?.mriAppointment?.initial[2]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[2]?.status
            : "",
        Ortho:
          item?.orthoAppointment?.initial.length > 0
            ? new Date(
                item?.orthoAppointment?.initial[
                  item?.orthoAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.orthoAppointment?.initial[
                item?.orthoAppointment?.initial?.length - 1
              ]?.status
            : "",
        neuroN:
          item?.nueroAppointment?.initial.length > 0
            ? new Date(
                item?.nueroAppointment?.initial[
                  item?.nueroAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.nueroAppointment?.initial[
                item?.nueroAppointment?.initial?.length - 1
              ]?.status
            : "",
        UEMG:
          item?.UMEG?.initial?.length > 0
            ? new Date(
                item?.UMEG?.initial[item?.UMEG?.initial?.length - 1]?.date
              ).toLocaleDateString() +
              " " +
              item?.UMEG?.initial[item?.UMEG?.initial?.length - 1]?.status
            : "",
        LMEG:
          item?.LMEG?.initial?.length > 0
            ? new Date(
                item?.LMEG?.initial[item?.LMEG?.initial?.length - 1]?.date
              ).toLocaleDateString() +
              " " +
              item?.LMEG?.initial[item?.LMEG?.initial?.length - 1]?.status
            : "",
        pain:
          item?.painAppointment?.initial.length > 0
            ? new Date(
                item?.painAppointment?.initial[
                  item?.painAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.painAppointment?.initial[
                item?.painAppointment?.initial?.length - 1
              ]?.status
            : "",
        spinal:
          item?.spinalAppointment?.initial.length > 0
            ? new Date(
                item?.spinalAppointment?.initial[
                  item?.spinalAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.spinalAppointment?.initial[
                item?.spinalAppointment?.initial?.length - 1
              ]?.status
            : "",
        np:
          item?.nursePractitioner?.initial.length > 0
            ? new Date(
                item?.nursePractitioner?.initial[
                  item?.nursePractitioner?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.nursePractitioner?.initial[
                item?.nursePractitioner?.initial?.length - 1
              ]?.status
            : "",
        cervical: item?.cervical?.date
          ? new Date(item?.cervical?.date).toLocaleDateString() +
            " " +
            item?.cervical?.status
          : "",
        lumberl: item?.lumberal?.date
          ? new Date(item?.lumberal?.date).toLocaleDateString() +
            " " +
            item?.lumberal?.status
          : "",
      };

      return value;
    });
    return arr;
  };

  // old
  // const formate = (data) => {
  //   let arr = data?.map((item) => {
  //     let value = {};
  //     value = {
  //       lastName: item?.patientId?.lastName,
  //       firstName: item?.patientId?.firstName,
  //       internal:
  //         item?.mdAppointment?.initial.length > 0
  //           ? new Date(
  //               item?.mdAppointment?.initial[
  //                 item?.mdAppointment?.initial?.length - 1
  //               ]?.date
  //             ).toLocaleDateString() +
  //             " " +
  //             item?.mdAppointment?.initial[
  //               item?.mdAppointment?.initial?.length - 1
  //             ]?.status
  //           : "",
  //       OMRI1:
  //         item?.mriAppointment?.initial.length > 0
  //           ? new Date(
  //               item?.mriAppointment?.initial[0]?.date
  //             ).toLocaleDateString() +
  //             " " +
  //             item?.mdAppointment?.initial[0]?.status
  //           : "",
  //       OMRI2:
  //         item?.mriAppointment?.initial.length > 1
  //           ? new Date(
  //               item?.mriAppointment?.initial[1]?.date
  //             ).toLocaleDateString() +
  //             " " +
  //             item?.mdAppointment?.initial[1]?.status
  //           : "",
  //       OMRI3:
  //         item?.mriAppointment?.initial.length > 2
  //           ? new Date(
  //               item?.mriAppointment?.initial[2]?.date
  //             ).toLocaleDateString() +
  //             " " +
  //             item?.mdAppointment?.initial[2]?.status
  //           : "",
  //       neuroN:
  //         item?.nueroAppointment?.initial.length > 0
  //           ? new Date(
  //               item?.nueroAppointment?.initial[
  //                 item?.nueroAppointment?.initial?.length - 1
  //               ]?.date
  //             ).toLocaleDateString() +
  //             " " +
  //             item?.nueroAppointment?.initial[
  //               item?.nueroAppointment?.initial?.length - 1
  //             ]?.status
  //           : "",
  //       orthoN:
  //         item?.orthoAppointment?.initial.length > 0
  //           ? new Date(
  //               item?.orthoAppointment?.initial[
  //                 item?.orthoAppointment?.initial?.length - 1
  //               ]?.date
  //             ).toLocaleDateString() +
  //             " " +
  //             item?.orthoAppointment?.initial[
  //               item?.orthoAppointment?.initial?.length - 1
  //             ]?.status
  //           : "",
  //       UEMG:
  //         item?.UMEG?.initial.length > 0
  //           ? new Date(
  //               item?.UMEG?.initial[item?.UMEG?.initial?.length - 1]?.date
  //             ).toLocaleDateString() +
  //             " " +
  //             item?.UMEG?.initial[item?.UMEG?.initial?.length - 1]?.status
  //           : "",
  //       LMEG:
  //         item?.LMEG?.initial.length > 0
  //           ? new Date(
  //               item?.LMEG?.initial[item?.LMEG?.initial?.length - 1]?.date
  //             ).toLocaleDateString() +
  //             " " +
  //             item?.LMEG?.initial[item?.LMEG?.initial?.length - 1]?.status
  //           : "",

  //       cervical: item?.cervical?.date
  //         ? new Date(item?.cervical?.date).toLocaleDateString() +
  //           " " +
  //           item?.cervical?.status
  //         : "",
  //       lumberl: item?.lumberal?.date
  //         ? new Date(item?.lumberal?.date).toLocaleDateString() +
  //           " " +
  //           item?.lumberal?.status
  //         : "",
  //     };

  //     return value;
  //   });
  //   return arr;
  // };

  // const ab = async () => {
  //   try {
  //     const missed = await getAllReports("missed");
  //     const upcoming = await getAllReports("upcoming");

  //     setMissedAppointments(formate(missed?.data?.data));
  //     setUpcomingAppointments(formate(upcoming?.data?.data));
  //   } catch (error) {
  //     toast.error(error.response?.data?.message || error?.message);
  //   }
  // };

  // useEffect(() => {
  //   ab();
  // }, []);

  const handleAppointmentChange = async (type) => {
    setappointmentType(type);

    // let updatedAppointment = {};
    // try {
    //   if (type == "all") {
    //     console.log("typeAll", type);
    //     updatedAppointment = await getReports();
    //   } else {
    //     updatedAppointment = await getAllReports(type);
    //   }
    //   console.log("type", type);
    //   console.log("updatedAppointment", updatedAppointment);
    //   props.setData(formate(updatedAppointment?.data?.data));
    // } catch (error) {
    //   toast.error(error.response?.data?.message || error?.message);
    // }

    try {
      const response = await getSpecialistsForFlaganalysis(specialitytId, type);
      const specialistData = response.data;
      // Process the specialist data or update the state with the retrieved details
      console.log(response);
      props.setData(formate(specialistData?.data));
      // setData(specialistData?.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const specialistById = async (id) => {
    setSpecialistId(id);
    // console.log("data", props.data);
    // props.setData(props.data.filter((patient) => patient?.specialist !== id));
    // props.data.filter((patient) => {
    //   if (patient?.specialist == id) {
    //     console.log(patient?.specialist);
    //   }
    // });
    // console.log("id", id);
    try {
      const response = await getSpecialistsForFlaganalysis(id, appointmentType);
      const specialistData = response.data;
      // Process the specialist data or update the state with the retrieved details
      console.log(response);
      props.setData(formate(specialistData?.data));
      // setData(specialistData?.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const specialityById = async (id) => {
    setSpecialitytId(id);
    try {
      const response = await getSpecialitiesForFlaganalysis(
        id,
        appointmentType
      );
      const specialistData = response.data;
      // Process the specialist data or update the state with the retrieved details
      console.log(response);
      props.setData(formate(specialistData?.data));
      // setData(specialistData?.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  // console.log("appointmentType", appointmentType);
  // console.log("specialistId", specialistId);

  return (
    <>
      <div className="reportHeadingMain">
        <div className="reportHeading">
          <img src="/images/arrowLeft.svg" alt="arrow Left" />
          <h1>Reports</h1>
        </div>
        <div className="reportAppointUrl forFlexRow">
          <p>
            Report /<span> {props.name}</span>
          </p>
        </div>
        <div className="reportAppointUrl forFlexRow">
          <div
            style={{
              display: "flex",
              gap: "20px",
              width: "70%",
            }}
          >
            {/* Select patient */}
            {/* <div
              className="dateRange"
              style={{
                width: "100%",
              }}
            >
              <select
                className="specialist-select"
                required
                name="specialist"
                onChange={(e) => specialistById(e.target.value)}
              >
                <option disabled selected hidden>
                  Select specialist
                </option>
                {specialist.map((specialist) => (
                  <option key={specialist._id} value={specialist?._id}>
                    {specialist?.name}
                  </option>
                ))}
              </select>
            </div> */}
            {/* Select appointment */}
            <div
              className="dateRange"
              style={{
                width: "100%",
              }}
            >
              <select
                className="specialist-select"
                onChange={(e) => {
                  handleAppointmentChange(e?.target?.value);
                  // const a = e?.target?.value;
                  // if (a == "all") {
                  //   // setData(appointments);
                  // } else {
                  //   // setData(appointments?.filter((res) => res?.type == a));
                  // }
                }}
              >
                <option value={"all"}>All Appointments</option>
                <option value={"upcoming"}>Upcominig</option>
                <option value={"missed"}>Missed</option>
              </select>
            </div>

            {/* Select patient */}
            <div
              className="dateRange"
              style={{
                width: "100%",
              }}
            >
              <select
                className="specialist-select"
                required
                name="speciality"
                onChange={(e) => specialityById(e.target.value)}
              >
                <option key="" value={"all"} selected>
                  All specialities
                </option>
                {specialities.map((speciality) => (
                  <option key={speciality._id} value={speciality?._id}>
                    {speciality?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div style={{ display: "flex", gap: "20px" }}>
            <div className="iconPrint" style={{ display: props.display }}>
              <CSVLink
                headers={props.headers}
                filename={"exportReport.csv"}
                data={props.data}
              >
                <div className="iconPrint">
                  <img src="/images/printBlue.svg" alt="print" />
                  <p>Print Report</p>
                </div>
              </CSVLink>
            </div>

            {/* <div className="iconPrint" style={{ display: props.display }}>
              <CSVLink
                headers={[
                  { label: "Patient ID", key: "patientId" },
                  { label: "First Name", key: "firstName" },
                  { label: "Last Name", key: "lastName" },
                  { label: "Phone Number", key: "phoneNumber" },
                  { label: "Internal", key: "internal" },
                  { label: "Cervical", key: "cervical" },
                  { label: "Lumberl", key: "lumberl" },
                  { label: "O MRI 1", key: "OMRI1" },
                  { label: "O MRI 2", key: "OMRI2" },
                  { label: "O MRI 3", key: "OMRI3" },
                  { label: "Ortho", key: "ortho" },
                  { label: "NP", key: "np" },
                  { label: "Neuro N", key: "neuroN" },
                  { label: "UEMG", key: "UEMG" },
                  { label: "LMEG", key: "LMEG" },
                  { label: "Pain", key: "pain" },
                  { label: "Spinal", key: "spinal" },
                ]}
                filename={"export.csv"}
                data={props.data}
              >
                <div className="iconPrint">
                  <img src="/images/printBlue.svg" alt="print" />
                  <p>Calling list</p>
                </div>
              </CSVLink>
            </div> */}

            {/* <div className="iconPrint" style={{ display: props.display }}>
              <CSVLink
                headers={[
                  { label: "Last Name", key: "lastName" },
                  { label: "First Name", key: "firstName" },
                  { label: "Internal", key: "internal" },
                  { label: "Cervical", key: "cervical" },
                  { label: "Lumberl", key: "lumberl" },
                  { label: "O MRI 1", key: "OMRI1" },
                  { label: "O MRI 2", key: "OMRI2" },
                  { label: "O MRI 3", key: "OMRI3" },
                  { label: "Neuro N", key: "neuroN" },
                  { label: "LMEG", key: "LMEG" },
                  { label: "UEMG", key: "UEMG" },
                  { label: "Ortho N", key: "orthoN" },
                ]}
                filename={"export.csv"}
                data={missedAppointments}
              >
                <div className="iconPrint">
                  <img src="/images/printBlue.svg" alt="print" />
                  <p>Missed Appointments</p>
                </div>
              </CSVLink>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportTop;
