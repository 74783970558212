import React from "react";
import { useSelector } from "react-redux";

const MDappointment = ({data}) => {
  return (
    <div className="showPatientsDetail">
      <div className="modalAppointPatientDetail">
        <div>
          <h5>Next MD appt</h5>
          <p>{new Date(data?.date).toLocaleDateString()}</p>
        </div>

        <div>
          <h5>Next ROM appt</h5>
          <p>{new Date(data?.date).toLocaleDateString()}</p>

        </div>

        <div>
          <h5>MD PRN</h5>
          <p>{data?.mdAppointment?.skip?"true":"---"}</p>
        </div>

        <div>
          <h5>NO ROM</h5>
                    <p>{data?.romAppointment?.skip?"true":"---"}</p>

        </div>
        {/* This div is empty for spacing.

                  */}
        <div></div>
      </div>
      <div className="modalAppointPatientDetail2">
        {data?.mdAppointment?.initial[0]?.date&&<div>
          <h5>Intial</h5>
         <p>{new Date(data?.mdAppointment?.initial[0]?.date).toLocaleDateString()}</p>

        </div>}

        {data?.mdAppointment?.initial[5]?.date&&<div>
          <h5>Internist FU5</h5>
                  <p>{new Date(data?.mdAppointment?.initial[5]?.date).toLocaleDateString()}</p>

        </div>}

       {data?.romAppointment?.initial[0]?.date&& <div>
          <h5>ROM</h5>
          <p>{new Date(data?.romAppointment?.initial[0]?.date).toLocaleDateString()}</p>
        </div>}
      </div>
      <div className="modalAppointPatientDetail2">
        {data?.mdAppointment?.initial[1]?.date&&<div>
          <h5>Internist FU1</h5>
                   <p>{new Date(data?.mdAppointment?.initial[1]?.date).toLocaleDateString()}</p>

        </div>}

        {data?.mdAppointment?.initial[6]?.date&&<div>
          <h5>Internist FU6</h5>
                   <p>{new Date(data?.mdAppointment?.initial[6]?.date).toLocaleDateString()}</p>

        </div>}

        {data?.romAppointment?.initial[1]?.date&&<div>
          <h5>ROM 2</h5>
                    <p>{new Date(data?.romAppointment?.initial[1]?.date).toLocaleDateString()}</p>

        </div>}
      </div>
      <div className="modalAppointPatientDetail2">
        {data?.mdAppointment?.initial[2]?.date&&<div>
          <h5>Internist FU2</h5>
                   <p>{new Date(data?.mdAppointment?.initial[2]?.date).toLocaleDateString()}</p>

        </div>}

        {data?.mdAppointment?.initial[7]?.date&&<div>
          <h5>Internist FU7</h5>
                <p>{new Date(data?.mdAppointment?.initial[7]?.date).toLocaleDateString()}</p>

        </div>}

        {data?.romAppointment?.initial[2]?.date&&<div>
          <h5>ROM 3</h5>
                       <p>{new Date(data?.romAppointment?.initial[2]?.date).toLocaleDateString()}</p>


        </div>}
      </div>
      <div className="modalAppointPatientDetail2">
        {data?.mdAppointment?.initial[3]?.date&&<div>
          <h5>Internist FU3</h5>
         <p>{new Date(data?.mdAppointment?.initial[3]?.date).toLocaleDateString()}</p>
          
        </div>}

        {data?.mdAppointment?.initial[8]?.date&&<div>
          <h5>Internist FU8</h5>
                   <p>{new Date(data?.mdAppointment?.initial[8]?.date).toLocaleDateString()}</p>

        </div>}

        {data?.romAppointment?.initial[3]?.date&&<div>
          <h5>ROM 4</h5>
                   <p>{new Date(data?.romAppointment?.initial[3]?.date).toLocaleDateString()}</p>

        </div>}
      </div>
      <div className="modalAppointPatientDetail2">
        {data?.mdAppointment?.initial[5]?.date&&<div>
          <h5>Internist FU5</h5>
                   <p>{new Date(data?.mdAppointment?.initial[5]?.date).toLocaleDateString()}</p>

        </div>}

        {data?.mdAppointment?.initial[9]?.date&&<div>
          <h5>Internist FU9</h5>
                  <p>{new Date(data?.mdAppointment?.initial[9]?.date).toLocaleDateString()}</p>

        </div>}

        {data?.romAppointment?.initial[4]?.date&&<div>
          <h5>ROM 5</h5>
                    <p>{new Date(data?.romAppointment?.initial[4]?.date).toLocaleDateString()}</p>

        </div>}
      </div>
    </div>
  );
};

export default MDappointment;
