import { Button, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateAppointmentById,
  createAppointment,
  updateAppointmentStatus,
} from "../../../api/functions";
import DatePicker from "../../../components/datePicker/DatePicker";
import { getAllAppointments, getPatients } from "../../../redux/records";
const MakeMdAppt = () => {
  const { selectedPatient } = useSelector((state) => state.records);
  const [currentAppt, setCurrentAppt] = useState({});
  const [dates, setDates] = useState({
    initial: currentAppt?.mdAppointment?.initial[0]
      ? new Date(currentAppt?.mdAppointment?.initial[0]?.date)
      : null,
    initial2: currentAppt?.mdAppointment?.initial[1]
      ? new Date(currentAppt?.mdAppointment?.initial[1]?.date)
      : null,
    initial3: currentAppt?.mdAppointment?.initial[2]
      ? new Date(currentAppt?.mdAppointment?.initial[2]?.date)
      : null,
    initial4: currentAppt?.mdAppointment?.initial[3]
      ? new Date(currentAppt?.mdAppointment?.initial[3]?.date)
      : null,
    initial5: currentAppt?.mdAppointment?.initial[4]
      ? new Date(currentAppt?.mdAppointment?.initial[4]?.date)
      : null,
    initial6: currentAppt?.mdAppointment?.initial[5]
      ? new Date(currentAppt?.mdAppointment?.initial[5]?.date)
      : null,
    initial7: currentAppt?.mdAppointment?.initial[6]
      ? new Date(currentAppt?.mdAppointment?.initial[6]?.date)
      : null,
    initial8: currentAppt?.mdAppointment?.initial[7]
      ? new Date(currentAppt?.mdAppointment?.initial[7]?.date)
      : null,
  });
  const [dates2, setDates2] = useState({
    initial: currentAppt?.romAppointment?.initial[0]
      ? new Date(currentAppt?.romAppointment?.initial[0]?.date)
      : null,
    initial1: currentAppt?.romAppointment?.initial[1]
      ? new Date(currentAppt?.romAppointment?.initial[1]?.date)
      : null,
    initial2: currentAppt?.romAppointment?.initial[2]
      ? new Date(currentAppt?.romAppointment?.initial[2]?.date)
      : null,
    initial3: currentAppt?.romAppointment?.initial[3]
      ? new Date(currentAppt?.romAppointment?.initial[3]?.date)
      : null,
    initial4: currentAppt?.romAppointment?.initial[4]
      ? new Date(currentAppt?.romAppointment?.initial[4]?.date)
      : null,
  });

  const [editDate, setEditDate] = useState(null);

  const mdUpdatedDateindex = currentAppt?.mdAppointment?.initial?.length;
  const romUpdateDateIndex = currentAppt?.romAppointment?.initial?.length;

  const dateCallBack = (key) => (value) =>
    setDates((prevDates) => ({ ...prevDates, [key]: value }));
  const dateCallBack2 = (key) => (value) =>
    setDates2((prevDates) => ({ ...prevDates, [key]: value }));
  const toast = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentAppt(selectedPatient?.appointment);
  }, [selectedPatient]);

  useEffect(() => {
    console.log("useEffect for dates");
    setDates({
      initial: currentAppt?.mdAppointment?.initial[0]
        ? new Date(currentAppt?.mdAppointment?.initial[0]?.date)
        : null,
      initial2: currentAppt?.mdAppointment?.initial[1]
        ? new Date(currentAppt?.mdAppointment?.initial[1]?.date)
        : null,
      initial3: currentAppt?.mdAppointment?.initial[2]
        ? new Date(currentAppt?.mdAppointment?.initial[2]?.date)
        : null,
      initial4: currentAppt?.mdAppointment?.initial[3]
        ? new Date(currentAppt?.mdAppointment?.initial[3]?.date)
        : null,
      initial5: currentAppt?.mdAppointment?.initial[4]
        ? new Date(currentAppt?.mdAppointment?.initial[4]?.date)
        : null,
      initial6: currentAppt?.mdAppointment?.initial[5]
        ? new Date(currentAppt?.mdAppointment?.initial[5]?.date)
        : null,
      initial7: currentAppt?.mdAppointment?.initial[6]
        ? new Date(currentAppt?.mdAppointment?.initial[6]?.date)
        : null,
      initial8: currentAppt?.mdAppointment?.initial[7]
        ? new Date(currentAppt?.mdAppointment?.initial[7]?.date)
        : null,
    });
    setDates2({
      initial: currentAppt?.romAppointment?.initial[0]
        ? new Date(currentAppt?.romAppointment?.initial[0]?.date)
        : null,
      initial1: currentAppt?.romAppointment?.initial[1]
        ? new Date(currentAppt?.romAppointment?.initial[1]?.date)
        : null,
      initial2: currentAppt?.romAppointment?.initial[2]
        ? new Date(currentAppt?.romAppointment?.initial[2]?.date)
        : null,
      initial3: currentAppt?.romAppointment?.initial[3]
        ? new Date(currentAppt?.romAppointment?.initial[3]?.date)
        : null,
      initial4: currentAppt?.romAppointment?.initial[4]
        ? new Date(currentAppt?.romAppointment?.initial[4]?.date)
        : null,
    });
  }, [currentAppt?.mdAppointment]);

  const createUpdateAppointment = async (type, date, followUp) => {
    try {
      await createAppointment(type, {
        patientId: selectedPatient?._id,
        date: date,
        initial: followUp,
        status: "upcoming",
      });
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };
  const createUpdateAppointmentById = async (type, date, followUp, id) => {
    try {
      await UpdateAppointmentById(type, {
        patientId: selectedPatient?._id,
        initialId: id,
        date: date,
        initial: followUp,
        status: "upcoming",
      });
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const createUpdateStatusAppointment = async (
    type,
    AppointmentType,
    followUpid,
    status
  ) => {
    try {
      await updateAppointmentStatus(type, {
        patientId: selectedPatient?._id,
        type: AppointmentType,
        initialId: followUpid,
        status: status,
      });
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  console.log("editDate", editDate);
  return (
    <div className="makeMdAppt">
      <div className="nextMdAppt">
        {/* {console.log('currentAppt',selectedPatient)} */}
        <div>
          <label>Next Md Appt</label>
          <DatePicker
            disabled
            selected={
              currentAppt?.mdAppointment?.initial?.length == 0
                ? null
                : currentAppt?.mdAppointment?.initial[mdUpdatedDateindex - 1]
                    .date
            }
            className="iconHeightforms"
            onChange={dateCallBack("mdAppointment")}
          />
        </div>

        <div>
          <label>ROM Initial Appt</label>
          <DatePicker
            iconType={"submit"}
            // minDate={new Date(currentAppt?.date)}
            selected={
              currentAppt?.romAppointment?.initial[romUpdateDateIndex - 1]?.date
            }
            className="iconHeightforms"
            onChange={dateCallBack2("initial")}
            onClick={() =>
              createUpdateAppointment(
                "romAppointment",
                dates2?.initial,
                "initial"
              )
            }
            status={true}
            isMissed={
              currentAppt?.romAppointment?.initial[0]?.status === "missed" &&
              true
            }
            isAttended={
              currentAppt?.romAppointment?.initial[0]?.status === "attended" &&
              true
            }
            atendClick={() =>
              createUpdateStatusAppointment(
                "updateMD",
                "romAppointment",
                currentAppt?.romAppointment?.initial[0]?._id,
                "attended"
              )
            }
            missClick={() =>
              createUpdateStatusAppointment(
                "updateMD",
                "romAppointment",
                currentAppt?.romAppointment?.initial[0]?._id,
                "missed"
              )
            }
            disabled={!!currentAppt?.romAppointment?.initial[0]}
          />
          {/* <div className="mriCheckBox" style={{justifyContent:'flex-end',marginTop:"0px"}}>
            <label>No ROM</label>
            <Checkbox
           
            />
          </div> */}
        </div>
        {/* <div className="attendedNmissed">     
            <div>   
              <label>Attend</label>
          <input type='checkbox' />
          </div>
            <div>   
              <label>Missed</label>
          <input type='checkbox' />
          </div>
          </div> */}
      </div>
      <div className="MdCheckBox">
        {/* <div>
          <label>MD PRN </label>
          <Checkbox id="mdPrn" for="mdPrn" />
        </div>
        <div>
          <label htmlFor="mdRom">No ROM </label>
          <Checkbox id="mdRom" for="mdRom" />
        </div> */}
      </div>
      <form className="formMd">
        <div className="MdDetailInputs">
          <div className="firstRowInputMd">
            <div>
              <div className="label-with-icon">
                <label>Initial/New Md Appt</label>
                {currentAppt?.mdAppointment?.initial[0]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("F1")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                status={!!currentAppt?.mdAppointment?.initial[0]}
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[0]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[0]?._id,
                    "missed"
                  )
                }
                isMissed={
                  currentAppt?.mdAppointment?.initial[0]?.status == "missed" &&
                  true
                }
                isAttended={
                  currentAppt?.mdAppointment?.initial[0]?.status ==
                    "attended" && true
                }
                // minDate={new Date(currentAppt?.date)}
                selected={dates?.initial}
                className="iconHeightforms1"
                onChange={dateCallBack("initial")}
                onClick={() =>
                  editDate == "F1"
                    ? createUpdateAppointmentById(
                        "mdAppointment",
                        dates?.initial,
                        "initial",
                        currentAppt?.mdAppointment?.initial[0]?._id
                      )
                    : createUpdateAppointment(
                        "mdAppointment",
                        dates?.initial,
                        "initial"
                      )
                }
                disabled={
                  (editDate == "F1"
                    ? !!editDate == "F1"
                    : !!currentAppt?.mdAppointment?.initial[0]) ||
                  (currentAppt?.mdAppointment?.initial[0]?.status == "missed" &&
                    true) ||
                  (currentAppt?.mdAppointment?.initial[0]?.status ==
                    "attended" &&
                    true)
                }
              />
            </div>
            <div>
              <div className="label-with-icon">
                <label>Internist FU5</label>
                {currentAppt?.mdAppointment?.initial[4]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("F5")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                status={!!currentAppt?.mdAppointment?.initial[4]}
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[4]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[4]?._id,
                    "missed"
                  )
                }
                isAttended={
                  currentAppt?.mdAppointment?.initial[4]?.status ==
                    "attended" && true
                }
                isMissed={
                  currentAppt?.mdAppointment?.initial[4]?.status == "missed" &&
                  true
                }
                // minDate={new Date(currentAppt?.mdAppointment?.initial[3]?.date)}
                selected={dates?.initial5}
                className="iconHeightforms1"
                onChange={dateCallBack("initial5")}
                onClick={() =>
                  editDate == "F5"
                    ? createUpdateAppointmentById(
                        "mdAppointment",
                        dates?.initial5,
                        "initial4",
                        currentAppt?.mdAppointment?.initial[4]?._id
                      )
                    : createUpdateAppointment(
                        "mdAppointment",
                        dates?.initial5,
                        "initial4"
                      )
                }
                disabled={
                  (editDate == "F5"
                    ? !!editDate == "F5"
                    : !!currentAppt?.mdAppointment?.initial[4]) ||
                  (currentAppt?.mdAppointment?.initial[4]?.status == "missed" &&
                    true) ||
                  (currentAppt?.mdAppointment?.initial[4]?.status ==
                    "attended" &&
                    true)
                }
              />
            </div>
            <div>
              <div className="label-with-icon">
                <label>ROM 1</label>
                {currentAppt?.romAppointment?.initial[1]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("R1")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "romAppointment",
                    currentAppt?.romAppointment?.initial[1]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "romAppointment",
                    currentAppt?.romAppointment?.initial[1]?._id,
                    "missed"
                  )
                }
                isMissed={
                  currentAppt?.romAppointment?.initial[1]?.status ===
                    "missed" && true
                }
                isAttended={
                  currentAppt?.romAppointment?.initial[1]?.status ===
                    "attended" && true
                }
                // minDate={
                //   new Date(currentAppt?.romAppointment?.initial[0]?.date)
                // }
                selected={dates2?.initial1}
                className="iconHeightforms1"
                onChange={dateCallBack2("initial1")}
                // onClick={() =>
                //   createUpdateAppointment(
                //     "romAppointment",
                //     dates2?.initial1,
                //     "initial1"
                //   )
                // }
                // disabled={!!currentAppt?.romAppointment?.initial[1]}
                onClick={() =>
                  editDate == "R1"
                    ? createUpdateAppointmentById(
                        "romAppointment",
                        dates2?.initial1,
                        "initial1",
                        currentAppt?.romAppointment?.initial[1]?._id
                      )
                    : createUpdateAppointment(
                        "romAppointment",
                        dates2?.initial1,
                        "initial1"
                      )
                }
                disabled={
                  (editDate == "R1"
                    ? !!editDate == "R1"
                    : !!currentAppt?.romAppointment?.initial[1]) ||
                  (currentAppt?.romAppointment?.initial[1]?.status ==
                    "missed" &&
                    true) ||
                  (currentAppt?.romAppointment?.initial[1]?.status ==
                    "attended" &&
                    true)
                }
                status={!!currentAppt?.romAppointment?.initial[1]}
              />
            </div>
          </div>
          <div className="firstRowInputMd">
            <div>
              <div className="label-with-icon">
                <label>Internist FU2</label>
                {currentAppt?.mdAppointment?.initial[1]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("F2")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                // minDate={new Date(currentAppt?.mdAppointment?.initial[0]?.date)}
                isMissed={
                  currentAppt?.mdAppointment?.initial[1]?.status === "missed" &&
                  true
                }
                isAttended={
                  currentAppt?.mdAppointment?.initial[1]?.status ===
                    "attended" && true
                }
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[1]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[1]?._id,
                    "missed"
                  )
                }
                selected={dates?.initial2}
                className="iconHeightforms1"
                onChange={dateCallBack("initial2")}
                // onClick={() =>
                //   createUpdateAppointment(
                //     "mdAppointment",
                //     dates?.initial2,
                //     "initial1"
                //   )
                // }
                // disabled={!!currentAppt?.mdAppointment?.initial[1]}
                onClick={() =>
                  editDate == "F2"
                    ? createUpdateAppointmentById(
                        "mdAppointment",
                        dates?.initial2,
                        "initial1",
                        currentAppt?.mdAppointment?.initial[1]?._id
                      )
                    : createUpdateAppointment(
                        "mdAppointment",
                        dates?.initial2,
                        "initial1"
                      )
                }
                disabled={
                  (editDate == "F2"
                    ? !!editDate == "F2"
                    : !!currentAppt?.mdAppointment?.initial[1]) ||
                  (currentAppt?.mdAppointment?.initial[1]?.status == "missed" &&
                    true) ||
                  (currentAppt?.mdAppointment?.initial[1]?.status ==
                    "attended" &&
                    true)
                }
                status={!!currentAppt?.mdAppointment?.initial[1]}
              />
            </div>
            <div>
              <div className="label-with-icon">
                <label>Internist FU6</label>
                {currentAppt?.mdAppointment?.initial[5]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("F6")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                // minDate={new Date(currentAppt?.mdAppointment?.initial[4]?.date)}
                isMissed={
                  currentAppt?.mdAppointment?.initial[5]?.status === "missed" &&
                  true
                }
                isAttended={
                  currentAppt?.mdAppointment?.initial[5]?.status ===
                    "attended" && true
                }
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[5]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[5]?._id,
                    "missed"
                  )
                }
                selected={dates?.initial6}
                className="iconHeightforms1"
                onChange={dateCallBack("initial6")}
                // onClick={() =>
                //   createUpdateAppointment(
                //     "mdAppointment",
                //     dates?.initial6,
                //     "initial5"
                //   )
                // }
                // disabled={!!currentAppt?.mdAppointment?.initial[5]}
                onClick={() =>
                  editDate == "F6"
                    ? createUpdateAppointmentById(
                        "mdAppointment",
                        dates?.initial6,
                        "initial5",
                        currentAppt?.mdAppointment?.initial[5]?._id
                      )
                    : createUpdateAppointment(
                        "mdAppointment",
                        dates?.initial6,
                        "initial5"
                      )
                }
                disabled={
                  editDate == "F6"
                    ? !!editDate == "F6"
                    : !!currentAppt?.mdAppointment?.initial[5] ||
                      (currentAppt?.mdAppointment?.initial[5]?.status ==
                        "missed" &&
                        true) ||
                      (currentAppt?.mdAppointment?.initial[5]?.status ==
                        "attended" &&
                        true)
                }
                status={!!currentAppt?.mdAppointment?.initial[5]}
              />
            </div>
            <div>
              <div className="label-with-icon">
                <label>ROM 2</label>
                {currentAppt?.romAppointment?.initial[2]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("R2")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "romAppointment",
                    currentAppt?.romAppointment?.initial[2]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "romAppointment",
                    currentAppt?.romAppointment?.initial[2]?._id,
                    "missed"
                  )
                }
                isMissed={
                  currentAppt?.romAppointment?.initial[2]?.status ===
                    "missed" && true
                }
                isAttended={
                  currentAppt?.romAppointment?.initial[2]?.status ===
                    "attended" && true
                }
                // minDate={
                //   new Date(currentAppt?.romAppointment?.initial[1]?.date)
                // }
                selected={dates2?.initial2}
                className="iconHeightforms1"
                onChange={dateCallBack2("initial2")}
                // onClick={() =>
                //   createUpdateAppointment(
                //     "romAppointment",
                //     dates2?.initial2,
                //     "initial2"
                //   )
                // }
                // disabled={!!currentAppt?.romAppointment?.initial[2]}
                onClick={() =>
                  editDate == "R2"
                    ? createUpdateAppointmentById(
                        "romAppointment",
                        dates2?.initial2,
                        "initial2",
                        currentAppt?.romAppointment?.initial[2]?._id
                      )
                    : createUpdateAppointment(
                        "romAppointment",
                        dates2?.initial2,
                        "initial2"
                      )
                }
                disabled={
                  (editDate == "R2"
                    ? !!editDate == "R2"
                    : !!currentAppt?.romAppointment?.initial[2]) ||
                  (currentAppt?.romAppointment?.initial[2]?.status ==
                    "missed" &&
                    true) ||
                  (currentAppt?.romAppointment?.initial[2]?.status ==
                    "attended" &&
                    true)
                }
                status={!!currentAppt?.romAppointment?.initial[2]}
              />
            </div>
          </div>
          <div className="firstRowInputMd">
            <div>
              <div className="label-with-icon">
                <label>Internist FU3</label>
                {currentAppt?.mdAppointment?.initial[2]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("F3")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                // minDate={new Date(currentAppt?.mdAppointment?.initial[1]?.date)}
                isMissed={
                  currentAppt?.mdAppointment?.initial[2]?.status === "missed" &&
                  true
                }
                isAttended={
                  currentAppt?.mdAppointment?.initial[2]?.status ===
                    "attended" && true
                }
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[2]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[2]?._id,
                    "missed"
                  )
                }
                selected={dates?.initial3}
                className="iconHeightforms1"
                onChange={dateCallBack("initial3")}
                // onClick={() =>
                //   createUpdateAppointment(
                //     "mdAppointment",
                //     dates?.initial3,
                //     "initial2"
                //   )
                // }
                // disabled={!!currentAppt?.mdAppointment?.initial[2]}
                onClick={() =>
                  editDate == "F3"
                    ? createUpdateAppointmentById(
                        "mdAppointment",
                        dates?.initial3,
                        "initial2",
                        currentAppt?.mdAppointment?.initial[2]?._id
                      )
                    : createUpdateAppointment(
                        "mdAppointment",
                        dates?.initial3,
                        "initial2"
                      )
                }
                disabled={
                  (editDate == "F3"
                    ? !!editDate == "F3"
                    : !!currentAppt?.mdAppointment?.initial[2]) ||
                  (currentAppt?.mdAppointment?.initial[2]?.status == "missed" &&
                    true) ||
                  (currentAppt?.mdAppointment?.initial[2]?.status ==
                    "attended" &&
                    true)
                }
                status={!!currentAppt?.mdAppointment?.initial[2]}
              />
            </div>
            <div>
              <div className="label-with-icon">
                <label>Internist FU7</label>
                {currentAppt?.mdAppointment?.initial[6]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("F7")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                isMissed={
                  currentAppt?.mdAppointment?.initial[6]?.status === "missed" &&
                  true
                }
                isAttended={
                  currentAppt?.mdAppointment?.initial[6]?.status ===
                    "attended" && true
                }
                iconType={"submit"}
                // minDate={new Date(currentAppt?.mdAppointment?.initial[5]?.date)}
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[6]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[6]?._id,
                    "missed"
                  )
                }
                selected={dates?.initial7}
                className="iconHeightforms1"
                onChange={dateCallBack("initial7")}
                // onClick={() =>
                //   createUpdateAppointment(
                //     "mdAppointment",
                //     dates?.initial7,
                //     "initial6"
                //   )
                // }
                // disabled={!!currentAppt?.mdAppointment?.initial[6]}
                onClick={() =>
                  editDate == "F7"
                    ? createUpdateAppointmentById(
                        "mdAppointment",
                        dates?.initial7,
                        "initial6",
                        currentAppt?.mdAppointment?.initial[6]?._id
                      )
                    : createUpdateAppointment(
                        "mdAppointment",
                        dates?.initial7,
                        "initial6"
                      )
                }
                disabled={
                  (editDate == "F7"
                    ? !!editDate == "F7"
                    : !!currentAppt?.mdAppointment?.initial[6]) ||
                  (currentAppt?.mdAppointment?.initial[6]?.status == "missed" &&
                    true) ||
                  (currentAppt?.mdAppointment?.initial[6]?.status ==
                    "attended" &&
                    true)
                }
                status={!!currentAppt?.mdAppointment?.initial[6]}
              />
            </div>
            <div>
              <div className="label-with-icon">
                <label>ROM 3</label>
                {currentAppt?.romAppointment?.initial[3]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("R3")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "romAppointment",
                    currentAppt?.romAppointment?.initial[3]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "romAppointment",
                    currentAppt?.romAppointment?.initial[3]?._id,
                    "missed"
                  )
                }
                isMissed={
                  currentAppt?.romAppointment?.initial[3]?.status ===
                    "missed" && true
                }
                isAttended={
                  currentAppt?.romAppointment?.initial[3]?.status ===
                    "attended" && true
                }
                // minDate={
                //   new Date(currentAppt?.romAppointment?.initial[2]?.date)
                // }
                selected={dates2?.initial3}
                className="iconHeightforms1"
                onChange={dateCallBack2("initial3")}
                // onClick={() =>
                //   createUpdateAppointment(
                //     "romAppointment",
                //     dates2?.initial3,
                //     "initial3"
                //   )
                // }
                // disabled={!!currentAppt?.romAppointment?.initial[3]}
                onClick={() =>
                  editDate == "R3"
                    ? createUpdateAppointmentById(
                        "romAppointment",
                        dates2?.initial3,
                        "initial3",
                        currentAppt?.romAppointment?.initial[3]?._id
                      )
                    : createUpdateAppointment(
                        "romAppointment",
                        dates2?.initial3,
                        "initial3"
                      )
                }
                disabled={
                  (editDate == "R3"
                    ? !!editDate == "R3"
                    : !!currentAppt?.romAppointment?.initial[3]) ||
                  (currentAppt?.romAppointment?.initial[3]?.status ==
                    "missed" &&
                    true) ||
                  (currentAppt?.romAppointment?.initial[3]?.status ==
                    "attended" &&
                    true)
                }
                status={!!currentAppt?.romAppointment?.initial[3]}
              />
            </div>
          </div>
          <div className="firstRowInputMd">
            <div>
              <div className="label-with-icon">
                <label>Internist FU4</label>
                {currentAppt?.mdAppointment?.initial[3]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("F4")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                // minDate={new Date(currentAppt?.mdAppointment?.initial[2]?.date)}
                isMissed={
                  currentAppt?.mdAppointment?.initial[3]?.status === "missed" &&
                  true
                }
                isAttended={
                  currentAppt?.mdAppointment?.initial[3]?.status ===
                    "attended" && true
                }
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[3]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[3]?._id,
                    "missed"
                  )
                }
                selected={dates?.initial4}
                className="iconHeightforms1"
                onChange={dateCallBack("initial4")}
                // onClick={() =>
                //   createUpdateAppointment(
                //     "mdAppointment",
                //     dates?.initial4,
                //     "initial3"
                //   )
                // }
                // disabled={!!currentAppt?.mdAppointment?.initial[3]}
                onClick={() =>
                  editDate == "F4"
                    ? createUpdateAppointmentById(
                        "mdAppointment",
                        dates?.initial4,
                        "initial3",
                        currentAppt?.mdAppointment?.initial[3]?._id
                      )
                    : createUpdateAppointment(
                        "mdAppointment",
                        dates?.initial4,
                        "initial3"
                      )
                }
                disabled={
                  (editDate == "F4"
                    ? !!editDate == "F4"
                    : !!currentAppt?.mdAppointment?.initial[3]) ||
                  (currentAppt?.mdAppointment?.initial[3]?.status == "missed" &&
                    true) ||
                  (currentAppt?.mdAppointment?.initial[3]?.status ==
                    "attended" &&
                    true)
                }
                status={!!currentAppt?.mdAppointment?.initial[3]}
              />
            </div>
            <div>
              <div className="label-with-icon">
                <label>Internist FU8</label>
                {currentAppt?.mdAppointment?.initial[7]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("F8")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                // minDate={new Date(currentAppt?.mdAppointment?.initial[6]?.date)}
                isMissed={
                  currentAppt?.mdAppointment?.initial[7]?.status === "missed" &&
                  true
                }
                isAttended={
                  currentAppt?.mdAppointment?.initial[7]?.status ===
                    "attended" && true
                }
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[7]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "mdAppointment",
                    currentAppt?.mdAppointment?.initial[7]?._id,
                    "missed"
                  )
                }
                selected={dates?.initial8}
                className="iconHeightforms1"
                onChange={dateCallBack("initial8")}
                // onClick={() =>
                //   createUpdateAppointment(
                //     "mdAppointment",
                //     dates?.initial8,
                //     "initial7"
                //   )
                // }
                // disabled={!!currentAppt?.mdAppointment?.initial[7]}
                onClick={() =>
                  editDate == "F8"
                    ? createUpdateAppointmentById(
                        "mdAppointment",
                        dates?.initial8,
                        "initial7",
                        currentAppt?.mdAppointment?.initial[7]?._id
                      )
                    : createUpdateAppointment(
                        "mdAppointment",
                        dates?.initial8,
                        "initial7"
                      )
                }
                disabled={
                  (editDate == "F8"
                    ? !!editDate == "F8"
                    : !!currentAppt?.mdAppointment?.initial[7]) ||
                  (currentAppt?.mdAppointment?.initial[7]?.status == "missed" &&
                    true) ||
                  (currentAppt?.mdAppointment?.initial[7]?.status ==
                    "attended" &&
                    true)
                }
                status={!!currentAppt?.mdAppointment?.initial[7]}
              />
            </div>
            <div>
              <div className="label-with-icon">
                <label>ROM 4</label>
                {currentAppt?.romAppointment?.initial[4]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("R4")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "romAppointment",
                    currentAppt?.romAppointment?.initial[4]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateMD",
                    "romAppointment",
                    currentAppt?.romAppointment?.initial[4]?._id,
                    "missed"
                  )
                }
                isMissed={
                  currentAppt?.romAppointment?.initial[4]?.status ===
                    "missed" && true
                }
                isAttended={
                  currentAppt?.romAppointment?.initial[4]?.status ===
                    "attended" && true
                }
                // minDate={
                //   new Date(currentAppt?.romAppointment?.initial[3]?.date)
                // }
                selected={dates2?.initial4}
                className="iconHeightforms1"
                onChange={dateCallBack2("initial4")}
                // onClick={() =>
                //   createUpdateAppointment(
                //     "romAppointment",
                //     dates2?.initial4,
                //     "initial4"
                //   )
                // }
                // disabled={!!currentAppt?.romAppointment?.initial[4]}
                onClick={() =>
                  editDate == "R4"
                    ? createUpdateAppointmentById(
                        "romAppointment",
                        dates2?.initial4,
                        "initial4",
                        currentAppt?.romAppointment?.initial[4]?._id
                      )
                    : createUpdateAppointment(
                        "romAppointment",
                        dates2?.initial4,
                        "initial4"
                      )
                }
                disabled={
                  (editDate == "R4"
                    ? !!editDate == "R4"
                    : !!currentAppt?.romAppointment?.initial[4]) ||
                  (currentAppt?.romAppointment?.initial[4]?.status ==
                    "missed" &&
                    true) ||
                  (currentAppt?.romAppointment?.initial[4]?.status ==
                    "attended" &&
                    true)
                }
                status={!!currentAppt?.romAppointment?.initial[4]}
              />
            </div>
          </div>
        </div>
        <div className="mdBtnSave">
          {/* <Button  type="button" title="Mark as done">Mark as done</Button> */}
        </div>
      </form>
    </div>
  );
};

export default MakeMdAppt;
