import React from "react";

import { NavLink } from "react-router-dom";
const TypeOfAppointment = () => {
  return (
    <div className="AppointTypeMain">
      <div className="firstTypeRow">
        <NavLink
          className={({ isActive }) => (isActive ? "activeType" : "")}
          to={"/appointment/createnewappointment/editpatient"}
        >
          Edit Patient
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "activeType" : "")}
          to={"mdappointment"}
        >
          Make MD appt
        </NavLink>
        <NavLink
          to={"mriappointment"}
          className={({ isActive }) => (isActive ? "activeType" : "")}
        >
          Make MRI appt
        </NavLink>
          <NavLink
          to={"orthoappointment"}
          className={({ isActive }) => (isActive ? "activeType" : "")}
        >
          Make Ortho appt
        </NavLink>
        <NavLink
          to={"nursepractionar"}
          className={({ isActive }) => (isActive ? "activeType" : "")}
        >
          Nurse Practitioner
        </NavLink>
        <NavLink
          to={"neuroappointment"}
          className={({ isActive }) => (isActive ? "activeType" : "")}
        >
          Make Neuro appt
        </NavLink>
      
        <NavLink
          to={"painmangement"}
          className={({ isActive }) => (isActive ? "activeType" : "")}
        >
          Pain Management
        </NavLink>
        
      </div>
      <div className="secondTypeRow">
        <NavLink
          to={"spinalsurgeon"}
          className={({ isActive }) => (isActive ? "activeType" : "")}
        >
          Spinal Surgeon
        </NavLink>
        <NavLink
          to={"physicaltherapy"}
          className={({ isActive }) => (isActive ? "activeType" : "")}
        >
          Physical Therapy
        </NavLink>
        <NavLink
          to={"chiropractic"}
          className={({ isActive }) => (isActive ? "activeType" : "")}
        >
          Chiropractic
        </NavLink>
        <NavLink
          to={"outsidereferral"}
          className={({ isActive }) => (isActive ? "activeType" : "")}
        >
          Outside Referral
        </NavLink>
        <NavLink
          to={"specialist"}
          className={({ isActive }) => (isActive ? "activeType" : "")}
        >
          Specialist 2
        </NavLink>
      </div>
    </div>
  );
};

export default TypeOfAppointment;
