import React from 'react'

const PateintDetail = ({data}) => {
  return (
    <div className="showPatientsDetail">
                <div className="modalAppointPatientDetail">
                  <div>
                    <h5>Phone</h5>
                    <p>{data?.phoneNumber}</p>
                  </div>

                  <div>
                    <h5>DOB</h5>
                    <p>{data?.dateOfBirth}</p>
                  </div>

                  <div>
                    <h5>Attorney</h5>
                    <p>{data?.attorney}</p>
                  </div>

                  <div>
                    <h5>OV/W</h5>
                    <p>1</p>
                  </div>
                  <div>
                    <h5>Transportation</h5>
                    <p>{data?.transportation?"true":"false"}</p>
                  </div>
                  
                </div>
                <div className="modalAppointPatientDetail2">
                  <div>
                    <h5>Disable</h5>
                    <p>{data?.disabled?"true":"false"}</p>
                  </div>

                  <div>
                    <h5>Non Complaint</h5>
                    <p>-</p>
                  </div>

                  <div>
                    <h5>D/C</h5>
                    <p>-</p>
                  </div>

                  
                  
                </div>
              </div>
  )
}

export default PateintDetail