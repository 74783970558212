import React, { memo, useState } from "react";
import { useAlert } from "react-alert";
import { useQuery } from "react-query";
import { getDashboardCounts } from "../../api/functions";
import Cards from "../../components/Cards";

const Counts = () => {
  const [counts, setCounts] = useState({});
  console.log('sadf',counts)
  const alert = useAlert();

  useQuery("counts", () => getDashboardCounts("counts"), {
    onSuccess: (data) => {
      setCounts(data.data.data);
    },
    onError: (err) => {
      alert.error(err?.response?.data?.message || err.message);
    },
    refetchInterval: 50000,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  return (
    <div>
      <div className="cardsCon">
        {Object.entries(counts).length <= 0 &&
          Array.from(new Float32Array(4)).map((_record, index) => {
            return <Cards loading={true} uniqueKey={index} src="" data={[]} />;
          })}
        {Object.entries(counts).map((record, index) => {
          return (
            <Cards
              loading={false}
              data={record}
              uniqueKey={index}
              src="/images/graph.svg"
            />
          );
        })}
      </div>
    </div>
  );
};

export default memo(Counts);
