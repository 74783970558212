import React, { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";

const DatePicker = ({
  mainClass,
  className,
  name,
  status,
  maxDate,
  minDate,
  onChange,
  selected = new Date(),
  iconType,
  atendClick,
  missClick,
  onClick,
  disabled = false,
  isAttended,
  isMissed,
}) => {
  const refIcon = useRef();
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div className={`showPickr ${mainClass}`}>
      <ReactDatePicker
        placeholderText={"Please select a date"}
        ref={refIcon}
        selected={selected ? new Date(selected) : null}
        maxDate={maxDate}
        minDate={minDate}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        autoComplete="off"
        // value={selected}
        onChange={() => {}}
        name={name}
        disabled={disabled}
        onSelect={onChange}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
      {status ? (
        <div className={`iconForStatus attendNmiss`}>
          <div onClick={() => atendClick()} className="attendDate">
            <input type="checkbox" checked={isAttended ? true : false} />
            <label>Attend</label>
          </div>
          <div onClick={() => missClick()} className="missedDate attendDate">
            <input type="checkbox" checked={isMissed ? true : false} />
            <label>Missed</label>
          </div>
        </div>
      ) : null}
      <div
        className={`iconForPicker ${className}`}
        onClick={() => {
          !disabled && iconType === "submit"
            ? onClick()
            : refIcon.current.setOpen(true);
        }}
      >
        {iconType === "submit" ? (
          <h5
            style={{
              cursor: disabled ? "default" : "pointer",
              fontSize: "16px",
            }}
          >
            ✔️
          </h5>
        ) : (
          <img src="/images/picker.svg" alt="calender Icon" />
        )}
      </div>
    </div>
  );
};

export default DatePicker;
