import { Checkbox } from "antd";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { addPatient, getPatientId } from "../../api/functions";
import DatePicker from "../../components/datePicker/DatePicker";
import PateintTop from "../../components/patient/PateintTop";
import Select from "../../components/Select/Select";
import AntdSelect from "../../components/Select/AntdSelect";
import LoadingSpinner from "../../components/Spinner/Spinner";
import { getPatients } from "../../redux/records";
const AddPatient = () => {
  const { specialist } = useSelector((state) => state.records);
  // const [formData, setFormData] = useState({ specialist: "" });
  const [loading, setLoading] = useState(true);
  const { records } = useSelector((state) => state);
  const [data, setData] = useState({
    patientId: "",
    // dateOfEntry: new Date(),
    dateOfEntry: null,
    dateOfBirth: null,
    dateOfAdmission: null,
    specialist: "",
  });
  console.log("specialist", specialist);
  const OVWOptions = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
  ];
  const caseOptions = [
    {
      value: "No Fault",
      label: "No Fault",
    },
    {
      value: "Cash",
      label: "Cash",
    },
    {
      value: "WorkComp",
      label: "WorkComp",
    },
    {
      value: "Major Medical",
      label: "Major Medical",
    },
    {
      value: "Lien",
      label: "Lien",
    },
    {
      value: "Work",
      label: "Work",
    },
  ];
  const attorneyOptions = records?.attornies?.map((record) => ({
    value: record?._id,
    label: record?.attorney,
    key: record?._id,
  }));

  const toast = useAlert();
  const nav = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const res = await getPatientId();

      setData((prevState) => ({ ...prevState, patientId: res.data.counts }));
      setLoading(false);
    })();
  }, []);

  const callback = (key) => {
    return (value) => {
      setData({ ...data, [key]: new Date(value) });
    };
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner height="50vh" />
      ) : (
        <>
          <PateintTop address="Add Patient" show="none" />
          <div className="AddPateintContainer">
            <div className="addInput">
              <form
                onChange={({ target: { name, value, checked } }) =>
                  setData({
                    ...data,
                    [name]: value.length > 0 ? value : checked,
                  })
                }
                onSubmit={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  
                  try {
                    await addPatient(data);
                    dispatch(getPatients())
                      .unwrap()
                      .then(() =>
                        nav("/appointment/createnewappointment/editpatient")
                        
                      );
                      setData({});
                  } catch (error) {
                    toast.error(
                      error?.response?.data?.message || error?.message
                    );
                  }
                  setLoading(false);
                }}
                className="editForm"
              >
                <div className="addformInputs">
                  <div className="addinputs">
                    <label>Patient Id</label>
                    <input
                      value={data?.patientId}
                      disabled
                      type="text"
                      placeholder="Patient id"
                    />
                  </div>
                  <div className="radioInputs">
                    <div>
                      <label>Disabled</label>
                      <Checkbox
                        name={"disabled"}
                        for="Disabled"
                        id="Disabled"
                        checked={data?.disabled}
                      />
                    </div>
                    <div>
                      <label>Transportation</label>

                      <Checkbox
                        name={"transportation"}
                        for="Transport"
                        id="Transport"
                        checked={data?.transportation}
                      />
                    </div>
                    <div>
                      <label>X-ray</label>

                      <Checkbox
                        checked={data?.x_ray}
                        name={"x_ray"}
                        id="x-ray"
                        for="x-ray"
                      />
                    </div>
                    {/* <div>
                      <label>OV/W</label>
                      <Checkbox
                        checked={data?.officialVisits}
                        name={"officialVisits"}
                        id="OV"
                        for="OV"
                      />
                    </div> */}
                  </div>
                </div>
                <div className="addformInputs">
                  <div className="addinputs">
                    <label>First Name</label>
                    <input
                      name={"firstName"}
                      type="text"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="addinputs">
                    <label>Last Name</label>
                    <input
                      name={"lastName"}
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                <div className="addformInputs">
                  <div className="addinputs">
                    <label>Phone Number</label>
                    <input
                      name={"phoneNumber"}
                      type="text"
                      placeholder="Enter your Phone Number"
                    />
                  </div>
                  <div className="addinputs">
                    <label>Date Of Birth</label>
                    <DatePicker
                      selected={data?.dateOfBirth || null}
                      maxDate={new Date()}
                      onChange={callback("dateOfBirth")}
                      name={"dateOfBirth"}
                      className="addPatHeight"
                    />
                  </div>
                </div>

                <div className="addformInputs">
                  <div className="addinputs">
                    <label>D/A</label>
                    <DatePicker
                      selected={data?.dateOfAdmission || null}
                      // minDate={new Date()}
                      onChange={callback("dateOfAdmission")}
                      name={"dateOfAdmission"}
                      className="addPatHeight"
                    />
                  </div>
                  <div className="addinputs">
                    <label>D/E</label>
                    <DatePicker
                      selected={data?.dateOfEntry || null}
                      onChange={callback("dateOfEntry")}
                      name={"dateOfEntry"}
                      className="addPatHeight"
                      // disabled={true}
                    />
                  </div>
                </div>

                <div className="addformInputs">
                  <div className="addinputs">
                    <label>Attorney</label>
                    <AntdSelect
                      // selectValue={patientData?.attorney}
                      options={attorneyOptions}
                      hanldeChange={(value) =>
                        setData({ ...data, attorney: value })
                      }
                      popUpClass="popUpClassSelect"
                      placeholder={"Select Attorney"}
                    />
                  </div>
                  <div className="addinputs">
                    <label>Case Type</label>
                    <AntdSelect
                      // selectValue={data?.caseType}
                      options={caseOptions}
                      placeholder="Select Case Type"
                      hanldeChange={(value) =>
                        setData({ ...data, caseType: value })
                      }
                      popUpClass="popUpClassSelect"
                    />
                  </div>
                </div>
                <div className="addformInputs">
                  <div className="addinputs">
                    <label>OV/W</label>

                    <AntdSelect
                      hanldeChange={(value) => {
                        setData({ ...data, oVisits: parseInt(value) });
                      }}
                      options={OVWOptions}
                      placeholder="Select OVW "
                      // selectValue={data?.oVisits}

                      popUpClass="popUpClassSelect"
                    />
                  </div>
                  <div className="addinputs">
                    <label>Referred By</label>
                    <input
                      name={"referredBy"}
                      type="text"
                      placeholder="Enter Referral Name"
                    />
                  </div>
                </div>
                <div className="addformInputs">
                  <div className="addinputs">
                    <label>New MD Appointment</label>
                    <DatePicker
                      minDate={new Date()}
                      selected={data?.mdAppointment || null}
                      onChange={callback("mdAppointment")}
                      name={"mdAppointment"}
                      className="addPatHeight"
                    />
                  </div>
                  <div className="addinputs">
                    <label>Specialist</label>
                    <select
                      className="specialist-select"
                      required
                      name="specialist"
                    >
                      <option disabled selected hidden>
                        Select specialist
                      </option>
                      {specialist.map((specialist) => (
                        <option
                          onChange={(value) => {
                            setData({ ...data, specialist: value });
                          }}
                          selected={data.specialist === specialist?._id}
                          value={specialist?._id}
                        >
                          {specialist?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="AddSave">
                  <button type="submit" className="addSave">
                    Add & Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddPatient;
