import React, { useEffect, useState } from "react";
import DatePicker from "../../../components/datePicker/DatePicker";
import { Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createAppointment,
  createMriAppointment,
  createNueroAppointment,
  updateNueroAppointmentById,
  updateAppointment,
  updateAppointmentStatus,
} from "../../../api/functions";
import { getAllAppointments, getPatients } from "../../../redux/records";
import { useAlert } from "react-alert";

const MakeNeuroAppt = () => {
  const { selectedPatient } = useSelector((state) => state.records);
  const [editDate, setEditDate] = useState(null);
  const [currentAppt, setCurrentAppt] = useState({});
  const [dates, setDates] = useState({
    // initial: null,
    // initial1: null,
    // initial2: null,
    // initial3: null,
    // initial4: null,
    // initial5: null,
    // initial6: null,
    // initial7: null,
    // initial8: null,
    initial: currentAppt?.nueroAppointment?.initial[0]
      ? new Date(currentAppt?.nueroAppointment?.initial[0]?.date)
      : null,
    initial1: currentAppt?.nueroAppointment?.initial[1]
      ? new Date(currentAppt?.nueroAppointment?.initial[1]?.date)
      : null,
    initial2: currentAppt?.nueroAppointment?.initial[2]
      ? new Date(currentAppt?.nueroAppointment?.initial[2]?.date)
      : null,
    initial3: currentAppt?.nueroAppointment?.initial[3]
      ? new Date(currentAppt?.nueroAppointment?.initial[3]?.date)
      : null,
    initial4: currentAppt?.nueroAppointment?.initial[4]
      ? new Date(currentAppt?.nueroAppointment?.initial[4]?.date)
      : null,
  });
  const [dates2, setDates2] = useState({
    initial: null,
    initial1: null,
    initial2: null,
    initial3: null,
  });

  console.log("currentAppt", currentAppt);
  const dateCallBack = (key) => (value) =>
    setDates((prevDates) => ({ ...prevDates, [key]: value }));
  const dateCallBack2 = (key) => (value) =>
    setDates2((prevDates) => ({ ...prevDates, [key]: value }));

  const toast = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentAppt(selectedPatient?.appointment);
  }, [selectedPatient]);

  useEffect(() => {
    console.log("useEffect for dates");
    setDates({
      initial: currentAppt?.nueroAppointment?.initial[0]
        ? new Date(currentAppt?.nueroAppointment?.initial[0]?.date)
        : null,
      initial1: currentAppt?.nueroAppointment?.initial[1]
        ? new Date(currentAppt?.nueroAppointment?.initial[1]?.date)
        : null,
      initial2: currentAppt?.nueroAppointment?.initial[2]
        ? new Date(currentAppt?.nueroAppointment?.initial[2]?.date)
        : null,
      initial3: currentAppt?.nueroAppointment?.initial[3]
        ? new Date(currentAppt?.nueroAppointment?.initial[3]?.date)
        : null,
      initial4: currentAppt?.nueroAppointment?.initial[4]
        ? new Date(currentAppt?.nueroAppointment?.initial[4]?.date)
        : null,
    });
  }, [currentAppt?.nueroAppointment]);

  const createUpdateAppointment = async (type, date, followUp) => {
    try {
      await createNueroAppointment(type, {
        patientId: selectedPatient?._id,
        date: date,
        initial: followUp,
        status: "upcoming",
      });
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const createUpdateAppointmentById = async (type, date, followUp, id) => {
    try {
      await updateNueroAppointmentById(type, {
        patientId: selectedPatient?._id,
        date: date,
        initial: followUp,
        status: "upcoming",
        initialId: id,
      });
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const createUpdateStatusAppointment = async (
    type,
    AppointmentType,
    followUpid,
    status
  ) => {
    try {
      await updateAppointmentStatus(type, {
        patientId: selectedPatient?._id,
        type: AppointmentType,
        initialId: followUpid,
        status: status,
      });
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  return (
    <div className="makeNeuroAppt">
      <form className="formMd">
        <div className="neuroInputs">
          <div className="neuroFirstRow">
            <div className="nextNeuro">
              <div className="neuroLable">
                <label>Next Neuro</label>
                <div className="imgeCircleNeuro">
                  <img src="/images/arrowCircle.svg" alt="circular arrow" />
                  <p>Clear Next Neuro</p>
                </div>
              </div>
              <DatePicker
                selected={currentAppt?.date}
                className="iconHeightformsNeuro"
                mainClass="neuroWidhtDatePicker"
                disabled
              />
            </div>
            <div className="neuroCheckBox">
              <label>Neuro PRN</label>
              <Checkbox
                checked={currentAppt?.nueroAppointment?.skip}
                disabled={currentAppt?.nueroAppointment?.initial[0]}
                onChange={async (e) => {
                  try {
                    await createNueroAppointment("nueroAppointment", {
                      patientId: selectedPatient?._id,
                      initial: "skip",
                      value: e.target.checked,
                    });
                    toast.success("Success");
                    dispatch(getAllAppointments());
                    dispatch(getPatients());
                  } catch (error) {
                    toast.error(error.response?.data?.message || error.message);
                  }
                }}
                id="neuroPRN"
                for="neuroPRN"
              />
            </div>
          </div>
          <div className="neuroFirstRow">
            <div className="addnextNeuro">
              <div className="labelnInput">
                <div className="label-with-icon">
                  <label>Neuro</label>
                  {currentAppt?.nueroAppointment?.initial[0]?.status ==
                    "upcoming" && (
                    <div
                      className="edit-icon"
                      onClick={() => setEditDate("F0")}
                    >
                      <img src="/images/edit.svg" alt="edit" />
                    </div>
                  )}
                </div>
                <DatePicker
                  iconType={"submit"}
                  // minDate={new Date(currentAppt?.date)}
                  selected={
                    currentAppt?.nueroAppointment?.initial[0]
                      ? new Date(
                          currentAppt?.nueroAppointment?.initial[0]?.date
                        )
                      : dates?.initial
                  }
                  atendClick={() =>
                    createUpdateStatusAppointment(
                      "updateNuero",
                      "nueroAppointment",
                      currentAppt?.nueroAppointment?.initial[0]?._id,
                      "attended"
                    )
                  }
                  missClick={() =>
                    createUpdateStatusAppointment(
                      "updateNuero",
                      "nueroAppointment",
                      currentAppt?.nueroAppointment?.initial[0]?._id,
                      "missed"
                    )
                  }
                  className="iconHeightformsmri"
                  onChange={dateCallBack("initial")}
                  onClick={() =>
                    editDate == "F0"
                      ? createUpdateAppointmentById(
                          "nueroAppointment",
                          dates?.initial,
                          "initial",
                          currentAppt?.nueroAppointment?.initial[0]?._id
                        )
                      : createUpdateAppointment(
                          "nueroAppointment",
                          dates?.initial,
                          "initial"
                        )
                  }
                  disabled={
                    (editDate == "F0"
                      ? !!editDate == "F0"
                      : !!currentAppt?.nueroAppointment?.initial[0]) ||
                    (currentAppt?.nueroAppointment?.initial[0]?.status ==
                      "missed" &&
                      true) ||
                    (currentAppt?.nueroAppointment?.initial[0]?.status ==
                      "attended" &&
                      true)
                  }
                  // onClick={() =>
                  //   createUpdateAppointment("nueroAppointment", dates?.initial,"initial")
                  // }
                  // disabled={!!currentAppt?.nueroAppointment?.initial[0]||currentAppt?.nueroAppointment?.skip}
                  status={
                    !!currentAppt?.nueroAppointment?.initial[0] ||
                    currentAppt?.nueroAppointment?.skip
                  }
                />
              </div>
              <div className="labelnInput">
                <label>UEMG</label>

                <DatePicker
                  iconType={"submit"}
                  // minDate={new Date(currentAppt?.date)}
                  selected={
                    currentAppt?.UMEG?.initial[0]
                      ? new Date(currentAppt?.UMEG?.initial[0]?.date)
                      : dates2?.initial
                  }
                  className="iconHeightformsmri"
                  onChange={dateCallBack2("initial")}
                  onClick={() =>
                    createUpdateAppointment("UMEG", dates2?.initial, "initial")
                  }
                  disabled={
                    !!currentAppt?.UMEG?.initial[0] ||
                    currentAppt?.nueroAppointment?.skip
                  }
                />
              </div>
            </div>
            <div className="addneuroCheckBox">
              <label>Scan Done</label>
              <Checkbox
                selected={currentAppt?.UMEG?.initial[0]?.status === "attended"}
                disabled={currentAppt?.nueroAppointment?.skip}
                onChange={async ({ target: { checked } }) =>
                  createUpdateStatusAppointment(
                    "updateNuero",
                    "UMEG",
                    currentAppt?.UMEG?.initial[0]?._id,
                    checked ? "attended" : "missed"
                  )
                }
                id="neuroScan"
                for="neuroScan"
              />
            </div>
          </div>
          <div className="neuroFirstRow">
            <div className="addnextNeuro">
              <div className="labelnInput">
                <div className="label-with-icon">
                  <label>Neuro FU1</label>
                  {currentAppt?.nueroAppointment?.initial[1]?.status ==
                    "upcoming" && (
                    <div
                      className="edit-icon"
                      onClick={() => setEditDate("F1")}
                    >
                      <img src="/images/edit.svg" alt="edit" />
                    </div>
                  )}
                </div>
                <DatePicker
                  iconType={"submit"}
                  // minDate={
                  //   new Date(currentAppt?.nueroAppointment?.initial[0]?.date)
                  // }
                  selected={
                    currentAppt?.nueroAppointment?.initial[1]
                      ? new Date(
                          currentAppt?.nueroAppointment?.initial[1]?.date
                        )
                      : dates?.initial1
                  }
                  isMissed={
                    currentAppt?.nueroAppointment?.initial[1]?.status ===
                      "missed" && true
                  }
                  isAttended={
                    currentAppt?.nueroAppointment?.initial[1]?.status ===
                      "attended" && true
                  }
                  atendClick={() =>
                    createUpdateStatusAppointment(
                      "updateNuero",
                      "nueroAppointment",
                      currentAppt?.nueroAppointment?.initial[1]?._id,
                      "attended"
                    )
                  }
                  missClick={() =>
                    createUpdateStatusAppointment(
                      "updateNuero",
                      "nueroAppointment",
                      currentAppt?.nueroAppointment?.initial[1]?._id,
                      "missed"
                    )
                  }
                  className="iconHeightformsmri"
                  onChange={dateCallBack("initial1")}
                  onClick={() =>
                    editDate == "F1"
                      ? createUpdateAppointmentById(
                          "nueroAppointment",
                          dates?.initial1,
                          "initial1",
                          currentAppt?.nueroAppointment?.initial[1]?._id
                        )
                      : createUpdateAppointment(
                          "nueroAppointment",
                          dates?.initial1,
                          "initial1"
                        )
                  }
                  disabled={
                    (editDate == "F1"
                      ? !!editDate == "F1"
                      : !!currentAppt?.nueroAppointment?.initial[1]) ||
                    (currentAppt?.nueroAppointment?.initial[1]?.status ==
                      "missed" &&
                      true) ||
                    (currentAppt?.nueroAppointment?.initial[1]?.status ==
                      "attended" &&
                      true)
                  }
                  // onClick={() =>
                  //   createUpdateAppointment("nueroAppointment", dates?.initial1,"initial1")
                  // }
                  // disabled={!!currentAppt?.nueroAppointment?.initial[1]||currentAppt?.nueroAppointment?.skip}
                  status={
                    !!currentAppt?.nueroAppointment?.initial[1] ||
                    currentAppt?.nueroAppointment?.skip
                  }
                />
              </div>
              <div className="labelnInput">
                <label>LEMG</label>

                <DatePicker
                  iconType={"submit"}
                  // minDate={new Date(currentAppt?.date)}
                  selected={
                    currentAppt?.LMEG?.initial[0]
                      ? new Date(currentAppt?.UMEG?.initial[0]?.date)
                      : dates2?.initial2
                  }
                  className="iconHeightformsmri"
                  onChange={dateCallBack2("initial2")}
                  onClick={() =>
                    createUpdateAppointment(
                      "LMEG",
                      dates2?.initial2,
                      "initial1"
                    )
                  }
                  disabled={
                    !!currentAppt?.LMEG?.initial[0] ||
                    currentAppt?.nueroAppointment?.skip
                  }
                />
              </div>
            </div>
            <div className="addneuroCheckBox">
              <label>Scan Done</label>

              <Checkbox
                selected={currentAppt?.LMEG?.initial[0]?.status === "attended"}
                disabled={
                  currentAppt?.nueroAppointment?.skip ||
                  !currentAppt?.LMEG?.initial[0]?._id
                }
                onChange={async ({ target: { checked } }) =>
                  createUpdateStatusAppointment(
                    "updateNuero",
                    "LMEG",
                    currentAppt?.LMEG?.initial[0]?._id,
                    checked ? "attended" : "missed"
                  )
                }
                id="neuroScan"
                for="neuroScan"
              />
            </div>
          </div>
          <div className="neuroSingleInput">
            <div>
              <div className="label-with-icon">
                <label>Neuro FU2</label>
                {currentAppt?.nueroAppointment?.initial[2]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("F2")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                // minDate={
                //   new Date(currentAppt?.nueroAppointment?.initial[1]?.date)
                // }
                selected={
                  currentAppt?.nueroAppointment?.initial[2]
                    ? new Date(currentAppt?.nueroAppointment?.initial[2]?.date)
                    : dates?.initial2
                }
                isMissed={
                  currentAppt?.nueroAppointment?.initial[2]?.status ===
                    "missed" && true
                }
                isAttended={
                  currentAppt?.nueroAppointment?.initial[2]?.status ===
                    "attended" && true
                }
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateNuero",
                    "nueroAppointment",
                    currentAppt?.nueroAppointment?.initial[2]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateNuero",
                    "nueroAppointment",
                    currentAppt?.nueroAppointment?.initial[2]?._id,
                    "missed"
                  )
                }
                className="iconHeightformsmri"
                onChange={dateCallBack("initial2")}
                onClick={() =>
                  editDate == "F2"
                    ? createUpdateAppointmentById(
                        "nueroAppointment",
                        dates?.initial2,
                        "initial2",
                        currentAppt?.nueroAppointment?.initial[2]?._id
                      )
                    : createUpdateAppointment(
                        "nueroAppointment",
                        dates?.initial2,
                        "initial2"
                      )
                }
                disabled={
                  (editDate == "F2"
                    ? !!editDate == "F2"
                    : !!currentAppt?.nueroAppointment?.initial[2]) ||
                  (currentAppt?.nueroAppointment?.initial[2]?.status ==
                    "missed" &&
                    true) ||
                  (currentAppt?.nueroAppointment?.initial[2]?.status ==
                    "attended" &&
                    true)
                }
                // onClick={() =>
                //   createUpdateAppointment("nueroAppointment", dates?.initial2,"initial2")
                // }
                // disabled={!!currentAppt?.nueroAppointment?.initial[2]||currentAppt?.nueroAppointment?.skip}
                status={
                  !!currentAppt?.nueroAppointment?.initial[2] ||
                  currentAppt?.nueroAppointment?.skip
                }
              />
            </div>
          </div>
          <div className="neuroSingleInput">
            <div>
              <div className="label-with-icon">
                <label>Neuro FU3</label>
                {currentAppt?.nueroAppointment?.initial[3]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("F3")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                // minDate={
                //   new Date(currentAppt?.nueroAppointment?.initial[2]?.date)
                // }
                selected={
                  currentAppt?.nueroAppointment?.initial[3]
                    ? new Date(currentAppt?.nueroAppointment?.initial[3]?.date)
                    : dates?.initial3
                }
                className="iconHeightformsmri"
                onChange={dateCallBack("initial3")}
                isMissed={
                  currentAppt?.nueroAppointment?.initial[3]?.status ===
                    "missed" && true
                }
                isAttended={
                  currentAppt?.nueroAppointment?.initial[3]?.status ===
                    "attended" && true
                }
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateNuero",
                    "nueroAppointment",
                    currentAppt?.nueroAppointment?.initial[3]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateNuero",
                    "nueroAppointment",
                    currentAppt?.nueroAppointment?.initial[3]?._id,
                    "missed"
                  )
                }
                onClick={() =>
                  editDate == "F3"
                    ? createUpdateAppointmentById(
                        "nueroAppointment",
                        dates?.initial3,
                        "initial3",
                        currentAppt?.nueroAppointment?.initial[3]?._id
                      )
                    : createUpdateAppointment(
                        "nueroAppointment",
                        dates?.initial3,
                        "initial3"
                      )
                }
                disabled={
                  (editDate == "F3"
                    ? !!editDate == "F3"
                    : !!currentAppt?.nueroAppointment?.initial[3]) ||
                  (currentAppt?.nueroAppointment?.initial[3]?.status ==
                    "missed" &&
                    true) ||
                  (currentAppt?.nueroAppointment?.initial[3]?.status ==
                    "attended" &&
                    true)
                }
                // onClick={() =>
                //   createUpdateAppointment("nueroAppointment", dates?.initial3,"initial3")
                // }
                // disabled={!!currentAppt?.nueroAppointment?.initial[3]||currentAppt?.nueroAppointment?.skip}
                status={
                  !!currentAppt?.nueroAppointment?.initial[3] ||
                  currentAppt?.nueroAppointment?.skip
                }
              />
            </div>
          </div>
          <div className="neuroSingleInput">
            <div>
              <div className="label-with-icon">
                <label>Neuro FU4</label>
                {currentAppt?.nueroAppointment?.initial[4]?.status ==
                  "upcoming" && (
                  <div className="edit-icon" onClick={() => setEditDate("F4")}>
                    <img src="/images/edit.svg" alt="edit" />
                  </div>
                )}
              </div>
              <DatePicker
                iconType={"submit"}
                // minDate={
                //   new Date(currentAppt?.nueroAppointment?.initial[3]?.date)
                // }
                selected={
                  currentAppt?.nueroAppointment?.initial[4]
                    ? new Date(currentAppt?.nueroAppointment?.initial[4]?.date)
                    : dates?.initial4
                }
                isMissed={
                  currentAppt?.nueroAppointment?.initial[4]?.status ===
                    "missed" && true
                }
                isAttended={
                  currentAppt?.nueroAppointment?.initial[4]?.status ===
                    "attended" && true
                }
                atendClick={() =>
                  createUpdateStatusAppointment(
                    "updateNuero",
                    "nueroAppointment",
                    currentAppt?.nueroAppointment?.initial[4]?._id,
                    "attended"
                  )
                }
                missClick={() =>
                  createUpdateStatusAppointment(
                    "updateNuero",
                    "nueroAppointment",
                    currentAppt?.nueroAppointment?.initial[4]?._id,
                    "missed"
                  )
                }
                className="iconHeightformsmri"
                onChange={dateCallBack("initial4")}
                onClick={() =>
                  editDate == "F4"
                    ? createUpdateAppointmentById(
                        "nueroAppointment",
                        dates?.initial4,
                        "initial4",
                        currentAppt?.nueroAppointment?.initial4[4]?._id
                      )
                    : createUpdateAppointment(
                        "nueroAppointment",
                        dates?.initial4,
                        "initial4"
                      )
                }
                disabled={
                  (editDate == "F4"
                    ? !!editDate == "F4"
                    : !!currentAppt?.nueroAppointment?.initial[4]) ||
                  (currentAppt?.nueroAppointment?.initial[4]?.status ==
                    "missed" &&
                    true) ||
                  (currentAppt?.nueroAppointment?.initial[4]?.status ==
                    "attended" &&
                    true)
                }
                // onClick={() =>
                //   createUpdateAppointment("nueroAppointment", dates?.initial4,"initial4")
                // }
                // disabled={!!currentAppt?.nueroAppointment?.initial[4]||currentAppt?.nueroAppointment?.skip}
                status={
                  !!currentAppt?.nueroAppointment?.initial[4] ||
                  currentAppt?.nueroAppointment?.skip
                }
              />
            </div>
          </div>
          <div className="clearLastNeuro">
            <img src="/images/arrowCircle.svg" alt="circular arrow" />
            <h2>Clear Last ortho</h2>
          </div>
          <div className="mdBtnSave" type="button"></div>
        </div>
      </form>
    </div>
  );
};

export default MakeNeuroAppt;
