import React, { useEffect, useState } from "react";
import {  createNurseAppointment,updateNurseAppointmentbyId, updateAppointmentStatus } from "../../../api/functions";
import DatePicker from "../../../components/datePicker/DatePicker";
import { getAllAppointments, getPatients } from "../../../redux/records";
import {Checkbox} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

const NurseAppt = () => {
  const { selectedPatient } = useSelector((state) => state.records);
  const [editDate, setEditDate] = useState(null);
  const [currentAppt, setCurrentAppt] = useState({
  });
  const [dates, setDates] = useState({
    // initial: null,
    // initial1: null,
    // initial2: null,
    // initial3: null,
    // initial4: null,
    // initial5: null,
    // initial6: null,
    // initial7: null,
    // initial8: null,
    initial: currentAppt?.nursePractitioner?.initial[0]
    ? new Date(currentAppt?.nursePractitioner?.initial[0]?.date)
    : null,
    initial1: currentAppt?.nursePractitioner?.initial[1]
    ? new Date(currentAppt?.nursePractitioner?.initial[1]?.date)
    : null,
  initial2: currentAppt?.nursePractitioner?.initial[2]
    ? new Date(currentAppt?.nursePractitioner?.initial[2]?.date)
    : null,
  initial3: currentAppt?.nursePractitioner?.initial[3]
    ? new Date(currentAppt?.nursePractitioner?.initial[3]?.date)
    : null,
  initial4: currentAppt?.nursePractitioner?.initial[4]
    ? new Date(currentAppt?.nursePractitioner?.initial[4]?.date)
    : null,
  });
  //  const [dates2, setDates2] = useState({
  //   initial: null,
  //   initial1: null,
  //   initial2: null,
  //   initial3: null,
    
  // });
 

  console.log('currentAppt',currentAppt)
  const dateCallBack = (key) => (value) =>
    setDates((prevDates) => ({ ...prevDates, [key]: value }));
    //  const dateCallBack2 = (key) => (value) =>
    // setDates2((prevDates) => ({ ...prevDates, [key]: value }));
      
  const toast = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentAppt(selectedPatient?.appointment);
  }, [selectedPatient]);

  useEffect(() => {
    console.log("useEffect for dates");
    setDates({
      initial: currentAppt?.nursePractitioner?.initial[0]
        ? new Date(currentAppt?.nursePractitioner?.initial[0]?.date)
        : null,
      initial1: currentAppt?.nursePractitioner?.initial[1]
        ? new Date(currentAppt?.nursePractitioner?.initial[1]?.date)
        : null,
      initial2: currentAppt?.nursePractitioner?.initial[2]
        ? new Date(currentAppt?.nursePractitioner?.initial[2]?.date)
        : null,
      initial3: currentAppt?.nursePractitioner?.initial[3]
        ? new Date(currentAppt?.nursePractitioner?.initial[3]?.date)
        : null,
      initial4: currentAppt?.nursePractitioner?.initial[4]
        ? new Date(currentAppt?.nursePractitioner?.initial[4]?.date)
        : null,
    });

  }, [currentAppt?.nursePractitioner]);

  const createUpdateAppointment = async (type,date,followUp) => {
    try {
      await createNurseAppointment(
        type,
        {
          patientId:selectedPatient?._id,
          date:date,
          initial:followUp,
          status:"upcoming"
          },
        
      );
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const createUpdateAppointmentById = async (type,date,followUp, id) => {
    try {
      await updateNurseAppointmentbyId(
        type,
        {
          patientId:selectedPatient?._id,
          date:date,
          initial:followUp,
          status:"upcoming",
          initialId: id,
          },
        
      );
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

        const createUpdateStatusAppointment = async (type,AppointmentType,followUpid,status) => {
 
    try {
      await updateAppointmentStatus(
        type,
        {
          patientId:selectedPatient?._id,
           type:AppointmentType,
          initialId:followUpid,
          status:status
          },

        
      );
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };


  return (
    <div className="outsideRef">
      <form className="outRef">
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
            <div className="neuroLableRef">
              <label>Next Nurse appt</label>
              <div className="imgeCircle">
                <img src="/images/arrowCircle.svg" alt="circular arrow" />
                <p>clear Next Nurse</p>
              </div>
            </div>
            <DatePicker
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
              selected={
             currentAppt?.date
              }
              disabled
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              {" "}
              <label>Ortho PRN</label>
              <Checkbox
                checked={currentAppt?.nursePractitioner?.skip}
                disabled={currentAppt?.nursePractitioner?.initial[0]}
                onChange={async (e) => {
                   try {
                    await createNurseAppointment(
                      "nursePractitioner",
                      {
                        patientId:selectedPatient?._id,
                        initial:"skip",
                        value:e.target.checked
                        },
                      
                    );
                    toast.success("Success");
                    dispatch(getAllAppointments());
                    dispatch(getPatients());
                  } catch (error) {
                    toast.error(error.response?.data?.message || error.message);
                  }
                }}
                id="orthoPRN"
                for="orthoPRN"
              />
            </div>
          </div>
        </div>
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
            <div className="label-with-icon">
              <label>Nurse</label>
              {currentAppt?.nursePractitioner?.initial[0]?.status ==
                "upcoming" && (
                <div className="edit-icon" onClick={() => setEditDate("F0")}>
                  <img src="/images/edit.svg" alt="edit" />
                </div>
              )}
            </div>

            <DatePicker
              iconType={"submit"}
                // minDate={new Date(currentAppt?.date)}
                selected={
                  currentAppt?.nursePractitioner?.initial[0]
                    ? new Date(currentAppt?.nursePractitioner?.initial[0]?.date)
                    : dates?.initial
                }
                  
              atendClick={()=>createUpdateStatusAppointment("updateNurse", "nursePractitioner",currentAppt?.nursePractitioner?.initial[0]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateNurse", "nursePractitioner",currentAppt?.nursePractitioner?.initial[0]?._id,"missed")}
             
                onChange={dateCallBack("initial")}
                isMissed={currentAppt?.nursePractitioner?.initial[0]?.status === 'missed' && true  }
                isAttended={currentAppt?.nursePractitioner?.initial[0]?.status === 'attended' && true }
                onClick={() =>
                editDate == "F0"
                  ? createUpdateAppointmentById(
                      "nursePractitioner",
                      dates?.initial,
                      "initial",
                      currentAppt?.nursePractitioner?.initial[0]?._id
                    )
                  : createUpdateAppointment(
                      "nursePractitioner",
                      dates?.initial,
                      "initial"
                    )
              }
              disabled={
                (editDate == "F0"
                  ? !!editDate == "F0"
                  : !!currentAppt?.nursePractitioner?.initial[0]) ||
                (currentAppt?.nursePractitioner?.initial[0]?.status ==
                  "missed" &&
                  true) ||
                (currentAppt?.nursePractitioner?.initial[0]?.status ==
                  "attended" &&
                  true)
              }
                // onClick={() =>
                //   createUpdateAppointment("nursePractitioner", dates?.initial,"initial")
                // }
                // disabled={!!currentAppt?.nursePractitioner?.initial[0]||currentAppt?.nursePractitioner?.skip} 
                status={!!currentAppt?.nursePractitioner?.initial[0]||currentAppt?.nursePractitioner?.skip} 
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              <label>Surgery RX</label>
              <Checkbox id="surgery" for="surgery" />
            </div>
            <div>
              <label>Surgery Performed</label>
              <Checkbox id="surgeryPer" for="surgeryPer" />
            </div>
          </div>
        </div>
        <div className="firstEditPatientRow">
          <div>
            <div className="label-with-icon">
              <label>Nurse FU1</label>
              {currentAppt?.nursePractitioner?.initial[1]?.status ==
                "upcoming" && (
                <div className="edit-icon" onClick={() => setEditDate("F1")}>
                  <img src="/images/edit.svg" alt="edit" />
                </div>
              )}
            </div>
            <DatePicker iconType={"submit"}
                // minDate={new Date(currentAppt?.nursePractitioner?.initial[0]?.date)}
                selected={
                  currentAppt?.nursePractitioner?.initial[1]
                    ? new Date(currentAppt?.nursePractitioner?.initial[1]?.date)
                    : dates?.initial1
                }
                isMissed={currentAppt?.nursePractitioner?.initial[1]?.status === 'missed' && true  }
                isAttended={currentAppt?.nursePractitioner?.initial[1]?.status === 'attended' && true }
                  
              atendClick={()=>createUpdateStatusAppointment("updateNurse", "nursePractitioner",currentAppt?.nursePractitioner?.initial[1]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateNurse", "nursePractitioner",currentAppt?.nursePractitioner?.initial[1]?._id,"missed")}
             
                onChange={dateCallBack("initial1")}
                onClick={() =>
                editDate == "F1"
                  ? createUpdateAppointmentById(
                      "nursePractitioner",
                      dates?.initial1,
                      "initial1",
                      currentAppt?.nursePractitioner?.initial[1]?._id
                    )
                  : createUpdateAppointment(
                      "nursePractitioner",
                      dates?.initial1,
                      "initial1"
                    )
              }
              disabled={
                (editDate == "F1"
                  ? !!editDate == "F1"
                  : !!currentAppt?.nursePractitioner?.initial[1]) ||
                (currentAppt?.nursePractitioner?.initial[1]?.status ==
                  "missed" &&
                  true) ||
                (currentAppt?.nursePractitioner?.initial[1]?.status ==
                  "attended" &&
                  true)
              }
                // onClick={() =>
                //   createUpdateAppointment("nursePractitioner", dates?.initial1,"initial1")
                // }
                // disabled={!!currentAppt?.nursePractitioner?.initial[1]||currentAppt?.nursePractitioner?.skip} 
                status={!!currentAppt?.nursePractitioner?.initial[1]||currentAppt?.nursePractitioner?.skip} 
              className="iconHeightformsedit"
              />
          </div>
          <div>
            <label>Surgery Date</label>
            <DatePicker className="iconHeightformsedit" />
          </div>
        </div>
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
            <div className="label-with-icon">
              <label>Nurse FU2</label>
              {currentAppt?.nursePractitioner?.initial[2]?.status ==
                "upcoming" && (
                <div className="edit-icon" onClick={() => setEditDate("F2")}>
                  <img src="/images/edit.svg" alt="edit" />
                </div>
              )}
            </div>
            <DatePicker
              iconType={"submit"}
                // minDate={new Date(currentAppt?.nursePractitioner?.initial[1]?.date)}
                selected={
                  currentAppt?.nursePractitioner?.initial[2]
                    ? new Date(currentAppt?.nursePractitioner?.initial[2]?.date)
                    : dates?.initial2
                }
                isMissed={currentAppt?.nursePractitioner?.initial[2]?.status === 'missed' && true  }
                isAttended={currentAppt?.nursePractitioner?.initial[2]?.status === 'attended' && true }
              atendClick={()=>createUpdateStatusAppointment("updateNurse", "nursePractitioner",currentAppt?.nursePractitioner?.initial[2]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateNurse", "nursePractitioner",currentAppt?.nursePractitioner?.initial[2]?._id,"missed")}
             
                onChange={dateCallBack("initial2")}
                onClick={() =>
                editDate == "F2"
                  ? createUpdateAppointmentById(
                      "nursePractitioner",
                      dates?.initial2,
                      "initial2",
                      currentAppt?.nursePractitioner?.initial[2]?._id
                    )
                  : createUpdateAppointment(
                      "nursePractitioner",
                      dates?.initial2,
                      "initial2"
                    )
              }
              disabled={
                (editDate == "F2"
                  ? !!editDate == "F2"
                  : !!currentAppt?.nursePractitioner?.initial[2]) ||
                (currentAppt?.nursePractitioner?.initial[2]?.status ==
                  "missed" &&
                  true) ||
                (currentAppt?.nursePractitioner?.initial[2]?.status ==
                  "attended" &&
                  true)
              }
                // onClick={() =>
                //   createUpdateAppointment("nursePractitioner", dates?.initial2,"initial2")
                // }
                // disabled={!!currentAppt?.nursePractitioner?.initial[2]||currentAppt?.nursePractitioner?.skip} 
                status={!!currentAppt?.nursePractitioner?.initial[2]||currentAppt?.nursePractitioner?.skip} 
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              <label>Second Surgery</label>
              <Checkbox id="surgery1" for="surgery1" />
            </div>
            <div>
              <label>Surgery Performed</label>
              <Checkbox id="surgeryPer1" for="surgeryPer1" />
            </div>
          </div>
        </div>
        <div className="firstEditPatientRow">
          <div>
          <div className="label-with-icon">
              <label>Nurse FU3</label>
              {currentAppt?.nursePractitioner?.initial[3]?.status ==
                "upcoming" && (
                <div className="edit-icon" onClick={() => setEditDate("F3")}>
                  <img src="/images/edit.svg" alt="edit" />
                </div>
              )}
            </div>
            <DatePicker
              iconType={"submit"}
                // minDate={new Date(currentAppt?.nursePractitioner?.initial[2]?.date)}
                isMissed={currentAppt?.nursePractitioner?.initial[3]?.status === 'missed' && true  }
                isAttended={currentAppt?.nursePractitioner?.initial[3]?.status === 'attended' && true }
                selected={
                  currentAppt?.nursePractitioner?.initial[3]
                    ? new Date(currentAppt?.nursePractitioner?.initial[3]?.date)
                    : dates?.initial3
                }
                    
              atendClick={()=>createUpdateStatusAppointment("updateNurse", "nursePractitioner",currentAppt?.nursePractitioner?.initial[3]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateNurse", "nursePractitioner",currentAppt?.nursePractitioner?.initial[3]?._id,"missed")}
             
                onChange={dateCallBack("initial3")}
                onClick={() =>
                editDate == "F3"
                  ? createUpdateAppointmentById(
                      "nursePractitioner",
                      dates?.initial3,
                      "initial3",
                      currentAppt?.nursePractitioner?.initial[3]?._id
                    )
                  : createUpdateAppointment(
                      "nursePractitioner",
                      dates?.initial3,
                      "initial3"
                    )
              }
              disabled={
                (editDate == "F3"
                  ? !!editDate == "F3"
                  : !!currentAppt?.nursePractitioner?.initial[3]) ||
                (currentAppt?.nursePractitioner?.initial[3]?.status ==
                  "missed" &&
                  true) ||
                (currentAppt?.nursePractitioner?.initial[3]?.status ==
                  "attended" &&
                  true)
              }
                // onClick={() =>
                //   createUpdateAppointment("nursePractitioner", dates?.initial3,"initial3")
                // }
                // disabled={!!currentAppt?.nursePractitioner?.initial[3]||currentAppt?.nursePractitioner?.skip} 
                status={!!currentAppt?.nursePractitioner?.initial[3]||currentAppt?.nursePractitioner?.skip} 
              className="iconHeightformsedit"
             
            />
          </div>
          <div>
            <label>Surgery Date</label>
            <DatePicker className="iconHeightformsedit" />
          </div>
        </div>
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
          <div className="label-with-icon">
              <label>Nurse FU4</label>
              {currentAppt?.nursePractitioner?.initial[4]?.status ==
                "upcoming" && (
                <div className="edit-icon" onClick={() => setEditDate("F4")}>
                  <img src="/images/edit.svg" alt="edit" />
                </div>
              )}
            </div>

            <DatePicker

            iconType={"submit"}
                // minDate={new Date(currentAppt?.nursePractitioner?.initial[3]?.date)}
                selected={
                  currentAppt?.nursePractitioner?.initial[4]
                    ? new Date(currentAppt?.nursePractitioner?.initial[4]?.date)
                    : dates?.initial4
                }
                    // status={true}
              atendClick={()=>createUpdateStatusAppointment("updateNurse", "nursePractitioner",currentAppt?.nursePractitioner?.initial[4]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateNurse", "nursePractitioner",currentAppt?.nursePractitioner?.initial[4]?._id,"missed")}
              isMissed={currentAppt?.nursePractitioner?.initial[4]?.status === 'missed' && true  }
                isAttended={currentAppt?.nursePractitioner?.initial[4]?.status === 'attended' && true }
                onChange={dateCallBack("initial4")}
                onClick={() =>
                editDate == "F4"
                  ? createUpdateAppointmentById(
                      "nursePractitioner",
                      dates?.initial4,
                      "initial4",
                      currentAppt?.nursePractitioner?.initial[4]?._id
                    )
                  : createUpdateAppointment(
                      "nursePractitioner",
                      dates?.initial4,
                      "initial4"
                    )
              }
              disabled={
                (editDate == "F4"
                  ? !!editDate == "F4"
                  : !!currentAppt?.nursePractitioner?.initial[4]) ||
                (currentAppt?.nursePractitioner?.initial[4]?.status ==
                  "missed" &&
                  true) ||
                (currentAppt?.nursePractitioner?.initial[4]?.status ==
                  "attended" &&
                  true)
              }
                // onClick={() =>
                //   createUpdateAppointment("nursePractitioner", dates?.initial4,"initial4")
                // }
                // disabled={!!currentAppt?.nursePractitioner?.initial[4]||currentAppt?.nursePractitioner?.skip} 
                status={!!currentAppt?.nursePractitioner?.initial[4]||currentAppt?.nursePractitioner?.skip} 
            
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              <label>Third Surgery</label>
              <Checkbox id="surgery2" for="surgery2" />
            </div>
            <div>
              <label>Surgery Performed</label>
              <Checkbox id="surgeryPer2" for="surgeryPer2" />
            </div>
          </div>
        </div>
       
        <div className="clearLastNeuro">
          <img src="/images/arrowCircle.svg" alt="circular arrow" />
          <h2>Clear Last Nurse</h2>
        </div>
      </form>
    </div>
  );
};

export default NurseAppt;
