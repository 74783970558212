import React, { useCallback, useEffect, useMemo, useState } from "react";
import UtilityTop from "../../components/utility/UtilityTop";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Table, Switch } from "antd";
import { updatePatientStatus, getAllStatusPatients } from "../../api/functions";

const PatientList = () => {
  const { patients } = useSelector((state) => state.records);
  const [data, setData] = useState([]);

//   console.log("data", data);

  const getAllStatusPatientsApi = async () => {
    try {
      const response = await getAllStatusPatients();
      //   const specialistData = response.data;
      // Process the specialist data or update the state with the retrieved details
      console.log("getAllStatusPatients", response);
      setData(response.data);
      // setData(specialistData?.data);
    } catch (error) {
      // toast.error(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    getAllStatusPatientsApi();
  }, []);

  const onStatusChange = async (record) => {
    console.log(record);
    let status = "";
    if (record?.status == "admitted") {
      status = "discharged";
    } else {
      status = "admitted";
    }

    try {
      const response = await updatePatientStatus(record?._id, status);
      getAllStatusPatientsApi();
      //   const specialistData = response.data;
      // Process the specialist data or update the state with the retrieved details
      console.log(response);
      // setData(specialistData?.data);
    } catch (error) {
      // toast.error(error.response?.data?.message || error.message);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
      },
      {
        title: "DOB",
        dataIndex: "dateOfBirth",
        key: "dateOfBirth",
        render: (text, record) =>
          moment(record?.dateOfBirth).format("YYYY-MM-DD"),
      },
      {
        title: "Case Type",
        dataIndex: "caseType",
        key: "caseType",
      },
      {
        title: "Phone Number",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => {
          const checked = record.status === "admitted";
          return (
            <div className="actionsRecent">
              <Switch
                defaultChecked={checked}
                onChange={() => onStatusChange(record)}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className="reportHeadingMain">
        <div className="reportHeading">
          <img src="/images/arrowLeft.svg" alt="arrow Left" />
          <h1>Utilities</h1>
        </div>
        <div className="reportAppointUrl">
          <div>
            <p>
              Utilities /<span>Patients list</span>
            </p>
          </div>
        </div>
      </div>

      <div className="recentPatients">
        <Table
          rowKey={(obj) => obj._id}
          dataSource={data}
          columns={columns}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
          }}
        />
      </div>
    </div>
  );
};

export default PatientList;
