import {ReactComponent as appointIcon} from './apptBlack.svg'
import {ReactComponent as dashIcon} from './dashWhite.svg'
import {ReactComponent as Patient} from './icon3.svg'
import {ReactComponent as Utils} from './utils.svg'
import {ReactComponent as Report} from './report.svg'
import {ReactComponent as Logout} from './logout.svg'
import {ReactComponent as Ham} from './ham.svg'
import {ReactComponent as Print} from './print.svg'

export default {
    appointIcon,
    dashIcon,
    Patient,
    Utils,
    Report,
    Logout,
    Ham,
    Print

}