import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { showModalActions } from "../../redux/showingModel";
import PateintDetail from "./details/PateintDetail";
import MDappointment from "./details/MDappointment";
import MRiApptDetails from "./details/MRiApptDetails";
import NeuroApptDetail from "./details/NeuroApptDetail";
import OrthoApptDetails from "./details/OrthoApptDetails";
import PhysicalTherapy from "./details/PhysicalTherapy";
import Details from "../../components/Appointments/Details";
import MakePainAppt from "./appointTypes/painAppt";

const AppointmentDetailModal = ({data}) => {
  console.log('data',data)
  const [showPateint,setShowPatient] = useState(false);
  const [showMDAppt,setShowMDAppt] = useState(false);
  const [showMRIAppt,setShowMRIAppt] = useState(false);
  const [showNeuroAppt,setShowNeuroAppt] = useState(false);
  const [showOrthoAppt,setShowOrthoAppt] = useState(false);
  const [showPhysical,setShowPhysical] = useState(false);
  const [showPain,setShowPain] = useState(false);
  const [showSpinal,setShowSpinal] = useState(false);
  const [showNurse,setShowNurse] = useState(false);

  const dispatch = useDispatch();
  const CloseModal = () => {
    dispatch(showModalActions.toggleAppointment());
  };
  const showDetailsPatient = ()=>{
    setShowPatient(!showPateint);

  }
  const showMDDetails = () =>{
    setShowMDAppt(!showMDAppt);

  }
  const showMRIappt = () =>{
    setShowMRIAppt(!showMRIAppt);
  }
  const showNeuroDetail =()=>{
    setShowNeuroAppt(!showNeuroAppt);

  }
  const showDOrthoDetails = ()=>{
    setShowOrthoAppt(!showOrthoAppt);

  }
  const showPhysicalDetails = () =>{
    setShowPhysical(!showPhysical);

  }
  const showPainDetails = () =>{
    setShowPain(!showPain);

  }
  const showspinalDetails = () =>{
    setShowSpinal(!showSpinal)

  }
  const showNurseDetails = ()=>{
    setShowNurse(!showNurse);
    
  }
  return (
    <div className="appointmentDetailModal">
      <div className="appointModalDetails">
        <button onClick={CloseModal} className="appointCrossbtn">
          X
        </button>
        <h1>Appointment Details
</h1>
        <div className="modalAppointDetail">
          <div>
            <h5>Patient First Name</h5>
            <p>{data?.patient_profile?.firstName+' '+data?.patient_profile?.lastName}</p>
          </div>

          {/* <div>
            <h5> Last Name</h5>
            <p>Amaru</p>
          </div> */}

          <div>
            <h5>D/A</h5>
            <p>{new Date(data?.patient_profile?.dateOfAdmission).toLocaleDateString()}</p>
          </div>

          <div>
            <h5>D/E</h5>
             <p>{new Date(data?.patient_profile?.dateOfEntry).toLocaleDateString()}</p>

          </div>
          <div>
            <h5>Case Type</h5>
            <p>{data?.patient_profile?.caseType}</p>
          </div>
        </div>
        <div className="moreDetails">
          <div className="totalAppointDetails">
            <div className="pateintDetails">
              <Details name="Patient Details" onClick={showDetailsPatient}/>
             {showPateint &&  <PateintDetail data={data?.patient_profile}/>} 
              
            </div>
            <div className="pateintDetails">
              <Details name="MD Appointment" onClick={showMDDetails}/>
             {showMDAppt &&  <MDappointment data={data}/>} 
              
            </div>
            <div className="pateintDetails">
              <Details name="MRI Appointment" onClick={showMRIappt}/>
             {showMRIAppt &&  <MRiApptDetails mriAppointment={data?.mriAppointment}/>} 
              
            </div>
            <div className="pateintDetails">
              <Details name="Neuro Appointment" onClick={showNeuroDetail}/>
             {showNeuroAppt &&  <NeuroApptDetail nueroAppointment={data?.nueroAppointment}/>} 
              
            </div>
            <div className="pateintDetails">
              <Details name="Ortho Appointment" onClick={showDOrthoDetails}/>
             {showOrthoAppt &&  <OrthoApptDetails orthoAppointment={data?.orthoAppointment}/>} 
              
            </div>
            <div className="pateintDetails">
              <Details name="Pain Management" onClick={showPainDetails}/>
             {showPain &&  <MakePainAppt painAppointment={data?.painAppointment}/>} 
              
            </div>
            <div className="pateintDetails">
              <Details name="Spinal Surgeon" onClick={showspinalDetails}/>
             {showSpinal &&  <OrthoApptDetails mriAppointment={data?.mriAppointment}/>} 
              
            </div>
            <div className="pateintDetails">
              <Details name="Nurse practitioner" onClick={showNurseDetails}/>
             {showNurse &&  <OrthoApptDetails mriAppointment={data?.mriAppointment}/>} 
              
            </div>
            <div className="pateintDetails">
              <Details name="Physical Therapy" onClick={showPhysicalDetails}/>
             {showPhysical &&  <PhysicalTherapy mriAppointment={data?.mriAppointment}/>} 
              
            </div>
            
            
            
           
            
          </div>
        </div>
        <div className="backBtnAppoint">
          <button onClick={CloseModal}>Back</button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetailModal;
