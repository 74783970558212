import React from "react";
import DatePicker from "../datePicker/DatePicker";
import { Checkbox } from "antd";
import { updateAppointment } from "../../api/functions";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getAllAppointments, getPatients } from "../../redux/records";

const ApptForm2 = () => {
  const {
    selectedPatient: { appointment },
  } = useSelector((state) => state.records);
  const dispatch = useDispatch();
  const toast = useAlert();
  return (
    <div className="makeMdAppt">
      <div className="nextMdAppt">
        <div>
          <label>Next Md Appt</label>
          <DatePicker className="iconHeightforms" />
        </div>
        <div>
          <label>Next MG2 request</label>
          <DatePicker className="iconHeightforms" />
        </div>
      </div>
      <div className="MdCheckBox">
        <div>
          <label>MD PRN </label>
          <Checkbox
            checked={appointment?.physical?.prn}
            onChange={async (e) => {
              try {
                await updateAppointment({
                  _id: appointment?._id,
                  appointment: {
                    physical: {
                      prn: e.target.checked,
                    },
                  },
                });
                dispatch(getPatients());
                dispatch(getAllAppointments());
                toast.success("Success");
              } catch (error) {
                toast.error(error?.response?.data?.message || error.message);
              }
            }}
            id="orthoPRN"
            for="orthoPRN"
          />
        </div>
        <div>
          <label>No MG2 request </label>
          <Checkbox id="phyPRN1" for="phyPRN1" />
        </div>
      </div>
      <form className="formMd">
        <div className="MdDetailInputs">
          <div className="firstRowInputMd">
            <div>
              <label>Intial</label>
              <DatePicker className="iconHeightforms1" />
            </div>
            <div>
              <label>Internist FU5</label>
              <DatePicker className="iconHeightforms1" />
            </div>
            <div>
              <label>MG request </label>
              <DatePicker className="iconHeightforms1" />
            </div>
          </div>
          <div className="firstRowInputMd">
            <div>
              <label>Internist FU2</label>
              <DatePicker className="iconHeightforms1" />
            </div>
            <div>
              <label>Internist FU6</label>
              <DatePicker className="iconHeightforms1" />
            </div>
            <div>
              <label>MG request 2</label>
              <DatePicker className="iconHeightforms1" />
            </div>
          </div>
          <div className="firstRowInputMd">
            <div>
              <label>Internist FU3</label>
              <DatePicker className="iconHeightforms1" />
            </div>
            <div>
              <label>Internist FU6</label>
              <DatePicker className="iconHeightforms1" />
            </div>
            <div>
              <label>MG request 3</label>
              <DatePicker className="iconHeightforms1" />
            </div>
          </div>
          <div className="firstRowInputMd">
            <div>
              <label>Internist FU4</label>
              <DatePicker className="iconHeightforms1" />
            </div>
            <div>
              <label>Internist FU8</label>
              <DatePicker className="iconHeightforms1" />
            </div>
            <div>
              <label>MG request 4</label>
              <DatePicker className="iconHeightforms1" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ApptForm2;
