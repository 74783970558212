import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AllAppointments from "./AllAppointments";
import "./appointment.css";
import { useAlert } from "react-alert";
import AppointmentTop from "./AppointmentTop";

const Appointment = () => {
  const { appointments, specialist } = useSelector((state) => state.records);
  const toast = useAlert();
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null); // Default selected option
  const [filteredAppointments, setFilteredAppointments] = useState([]);

  // Function to filter patients by date range
  const filterPatientsByDate = (startDate, endDate) => {
    const filteredData = data.filter((patient) => {
      const patientDate = new Date(patient.patient_profile.dateOfAdmission);
      return patientDate >= startDate && patientDate <= endDate;
    });
    setFilteredAppointments(filteredData);
  };



  // Function to handle radio button selection
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    switch (event.target.value) {
      case "week":
        const thisWeekDates = getThisWeekDates();
        filterPatientsByDate(thisWeekDates.startDate, thisWeekDates.endDate);
        break;
      case "month":
        const thisMonthDates = getThisMonthDates();
        filterPatientsByDate(thisMonthDates.startDate, thisMonthDates.endDate);
        break;
      case "year":
        const thisYearDates = getThisYearDates();
        filterPatientsByDate(thisYearDates.startDate, thisYearDates.endDate);
        break;
      case "all":
        displayAllRecords();
        break;
      default:
        setFilteredAppointments([]);
        break;
    }
  };

  useEffect(() => {
    setSelectedOption("all");
    setFilteredAppointments(data);
    // const thisWeekDates = getThisWeekDates();
    // filterPatientsByDate(thisWeekDates.startDate, thisWeekDates.endDate);
  }, [data]);

  console.log("filteredAppointments", filteredAppointments);

  // Function to get the start and end dates for "This week"
  const getThisWeekDates = () => {
    const today = new Date();
    const firstDayOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay())
    );
    const lastDayOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay() + 6)
    );

    return { startDate: firstDayOfWeek, endDate: lastDayOfWeek };
  };

  // Function to get the start and end dates for "This month"
  const getThisMonthDates = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    return { startDate: firstDayOfMonth, endDate: lastDayOfMonth };
  };

  // Function to get the start and end dates for "This year"
  const getThisYearDates = () => {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const lastDayOfYear = new Date(today.getFullYear(), 11, 31);

    return { startDate: firstDayOfYear, endDate: lastDayOfYear };
  };



  // const getSpecialists = async () => {
  //   try {
  //     const response = await fetch(
  //       "http://10.10.50.212:3500/api/appointments/list-patients/6486fcc441c3c703bf1ee671"
  //     );
  //     if (response.ok) {
  //       const data = await response.json();
  //       // Process the data and extract the specialists' records
  //       const specialists = data.map((patient) => patient.specialist);
  //       // Update the state or do something with the specialists' records
  //       console.log(specialists);
  //     } else {
  //       // Handle the case when the response is not successful
  //       console.error("Failed to fetch specialists' records");
  //     }
  //   } catch (error) {
  //     // Handle any errors during the API call
  //     console.error("Error while fetching specialists' records:", error);
  //   }
  // };

  // getSpecialists();

  const displayAllRecords = () => {
    setFilteredAppointments(data);
  };

  // Filteration by timeFrame end

  useEffect(() => {
    setData(appointments);
  }, [appointments]);

  return (
    <>
      <AppointmentTop
        appointments={appointments}
        data={data}
        specialist={specialist}
        setData={setData}
        selectedOption={selectedOption}
        handleRadioChange={handleRadioChange}
        filteredAppointments={filteredAppointments}
      />

      <AllAppointments appointments={filteredAppointments} />
    </>
  );
};

export default Appointment;
