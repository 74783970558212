import React from "react";
import MainModal from "../../components/modal/MainModal";
import SmallDetails from "../../components/patient/SmallDetails";
import { useSelector, useDispatch } from "react-redux";
import { showModalActions } from "../../redux/showingModel";

const PatientDetailCard = ({ patient }) => {
  const dispatch = useDispatch();
  const { records } = useSelector((state) => state);
  const attornName = records?.attornies?.find(records => records?._id == patient?.attorney)?.attorney;;
 
 
  const closeDeatils = () => {
    dispatch(showModalActions.toggle());
  };
  return (
    <MainModal>
      <div className="detailCard">
        <button className="crossDetails" onClick={closeDeatils}>
          x
        </button>
        <h1>Patient Details</h1>
        <div className="detailsPatient">
          <div className="detailsContainer">
            <SmallDetails name="Patient ID" details={patient?.patientId} />
            <SmallDetails
              name="Disabled"
              details={patient?.disabled ? "yes" : "no"}
            />
            <SmallDetails
              name="Transportaton"
              details={patient?.transportation ? "yes" : "no"}
            />
            <SmallDetails
              name="X-Ray"
              details={patient?.x_ray ? "yes" : "no"}
            />
            <SmallDetails name="First Name" details={patient?.firstName} />

           
          </div>
          <div className="detailsContainer">
            <SmallDetails name="Last Name" details={patient?.lastName} />
            <SmallDetails name="Phone No" details={patient?.phoneNumber} />
            <SmallDetails name="Date of Birth" details={new Date(patient?.dateOfBirth).toLocaleDateString()} />
            <SmallDetails name="D/A" details={new Date(patient?.dateOfAdmission).toLocaleDateString()} />

            <SmallDetails name="Case Type" details={patient?.caseType} />
          </div>
          <div className="detailsContainer">
            <SmallDetails name="D/E" details={new Date(patient?.dateOfEntry).toLocaleDateString()} />
            <SmallDetails name="New MD appointment" details={new Date(patient?.mdAppointment).toLocaleDateString()} />
            <SmallDetails name="Attorney" details={attornName} />
            <SmallDetails name="OV/W" details={patient?.oVisits} />
            <SmallDetails name="Reffered By" details={patient?.referredBy} />

          </div>
          
        </div>
        <div className="centerBtnBack">
        <button className="cardBackBtn" onClick={closeDeatils}>
          Back
        </button>
        </div>
      </div>
    </MainModal>
  );
};

export default PatientDetailCard;
