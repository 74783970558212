import React from "react";

const MRiApptDetails = () => {
  return (
    <>
      <>
        <div className="nameplusBorder">
          <hr />
          <h3>Cervical</h3>
        </div>
        <div className="modalAppointPatientDetail">
          <div>
            <h5>No cervical MRI</h5>
            <p>-</p>
          </div>

          <div>
            <h5>Facility</h5>
            <p>Lorem Ipsum</p>
          </div>

          <div>
            <h5>Scheduled date</h5>
            <p>9/6/1969</p>
          </div>

          <div>
            <h5>Scan Done</h5>
            <p>-</p>
          </div>

          <div>
            <h5>Report Recieved</h5>
            <p>No</p>
          </div>
        </div>
      </>
      <>
      <div className="nameplusBorder">
        <hr />
        <h3>Lumber</h3>
      </div>
      <div className="modalAppointPatientDetail">
        <div>
          <h5>No Lumber MRI</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Facility</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Scheduled date</h5>
          <p>9/6/1969</p>
        </div>

        <div>
          <h5>Scan Done</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Report Recieved</h5>
          <p>No</p>
        </div>
      </div>
      </>
      <>
      <div className="nameplusBorderExtra">
        <hr />
        <h3>Extremity/Thoracic/Brain</h3>
      </div>
      <div className="modalAppointPatientDetail">
        <div>
          <h5>No extremity MRI</h5>
          <p>-</p>
        </div>

        
      </div>
      <div className="modalAppointPatientDetail">
        <div>
          <h5>Area</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Facility</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Scheduled date</h5>
          <p>9/6/1969</p>
        </div>

        <div>
          <h5>Scan Done</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Report Recieved</h5>
          <p>No</p>
        </div>
      </div>
      <div className="modalAppointPatientDetail">
        <div>
          <h5>Area</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Facility</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Scheduled date</h5>
          <p>9/6/1969</p>
        </div>

        <div>
          <h5>Scan Done</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Report Recieved</h5>
          <p>No</p>
        </div>
      </div>
      <div className="modalAppointPatientDetail">
        <div>
          <h5>Area</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Facility</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Scheduled date</h5>
          <p>9/6/1969</p>
        </div>

        <div>
          <h5>Scan Done</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Report Recieved</h5>
          <p>No</p>
        </div>
      </div>
      <div className="modalAppointPatientDetail">
        <div>
          <h5>Area</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Facility</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Scheduled date</h5>
          <p>9/6/1969</p>
        </div>

        <div>
          <h5>Scan Done</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Report Recieved</h5>
          <p>No</p>
        </div>
      </div>
      <div className="modalAppointPatientDetail">
        <div>
          <h5>Area</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Facility</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Scheduled date</h5>
          <p>9/6/1969</p>
        </div>

        <div>
          <h5>Scan Done</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Report Recieved</h5>
          <p>No</p>
        </div>
      </div>
      <div className="modalAppointPatientDetail">
        <div>
          <h5>Area</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Facility</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Scheduled date</h5>
          <p>9/6/1969</p>
        </div>

        <div>
          <h5>Scan Done</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Report Recieved</h5>
          <p>No</p>
        </div>
      </div>
      <div className="modalAppointPatientDetail">
        <div>
          <h5>Area</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Facility</h5>
          <p>Lorem Ipsum</p>
        </div>

        <div>
          <h5>Scheduled date</h5>
          <p>9/6/1969</p>
        </div>

        <div>
          <h5>Scan Done</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Report Recieved</h5>
          <p>No</p>
        </div>
      </div>
      </>

    </>
  );
};

export default MRiApptDetails;
