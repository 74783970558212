import React, { useEffect, useState } from "react";
import DatePicker from "../../../components/datePicker/DatePicker";
import {Checkbox} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import {  createOrthuAppointment, updateAppointmentStatus } from "../../../api/functions";
import { getAllAppointments, getPatients } from "../../../redux/records";
import { createSpinalAppointment } from "../../../api/functions";
const SpinalAppt = () => {
  const { selectedPatient } = useSelector((state) => state.records);
  const [dates, setDates] = useState({
    initial: null,
    initial1: null,
    initial2: null,
    initial3: null,
    initial4: null,
    initial5: null,
    initial6: null,
    initial7: null,
    initial8: null,
  });
   const [dates2, setDates2] = useState({
    initial: null,
    initial1: null,
    initial2: null,
    initial3: null,
    
  });
 
  const [currentAppt, setCurrentAppt] = useState({
  });

  const dateCallBack = (key) => (value) =>
    setDates((prevDates) => ({ ...prevDates, [key]: value }));
     const dateCallBack2 = (key) => (value) =>
    setDates2((prevDates) => ({ ...prevDates, [key]: value }));
      
  const toast = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentAppt(selectedPatient?.appointment);
  }, [selectedPatient]);

  const createUpdateAppointment = async (type,date,followUp) => {
    try {
      await createSpinalAppointment(
        type,
        {
          patientId:selectedPatient?._id,
          date:date,
          initial:followUp,
          status:"upcoming"
          },
        
      );
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

        const createUpdateStatusAppointment = async (type,AppointmentType,followUpid,status) => {
 
    try {
      await updateAppointmentStatus(
        type,
        {
          patientId:selectedPatient?._id,
           type:AppointmentType,
          initialId:followUpid,
          status:status
          },

        
      );
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };


  return (
    <div className="outsideRef">
      <form className="outRef">
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
            <div className="neuroLableRef">
              <label>Next Spinal appt</label>
              <div className="imgeCircle">
                <img src="/images/arrowCircle.svg" alt="circular arrow" />
                <p>clear Next Spinal</p>
              </div>
            </div>
            <DatePicker
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
              selected={
             currentAppt?.date
              }
              disabled
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              {" "}
              <label>Spinal PRN</label>
              <Checkbox
                checked={currentAppt?.spinalAppointment?.skip}
                disabled={currentAppt?.spinalAppointment?.initial[0]}
                onChange={async (e) => {
                   try {
                    await createSpinalAppointment(
                      "spinalAppointment",
                      {
                        patientId:selectedPatient?._id,
                        initial:"skip",
                        value:e.target.checked
                        },
                      
                    );
                    toast.success("Success");
                    dispatch(getAllAppointments());
                    dispatch(getPatients());
                  } catch (error) {
                    toast.error(error.response?.data?.message || error.message);
                  }
                }}
                id="orthoPRN"
                for="orthoPRN"
              />
            </div>
          </div>
        </div>
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
            <label>Spinal</label>

            <DatePicker
              iconType={"submit"}
                minDate={new Date(currentAppt?.date)}
                selected={
                  currentAppt?.spinalAppointment?.initial[0]
                    ? new Date(currentAppt?.spinalAppointment?.initial[0]?.date)
                    : dates?.initial
                }
                  
              atendClick={()=>createUpdateStatusAppointment("updateSpinal", "spinalAppointment",currentAppt?.spinalAppointment?.initial[0]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateSpinal", "spinalAppointment",currentAppt?.spinalAppointment?.initial[0]?._id,"missed")}
             
                onChange={dateCallBack("initial")}
                onClick={() =>
                  createUpdateAppointment("spinalAppointment", dates?.initial,"initial")
                }
                isMissed={currentAppt?.spinalAppointment?.initial[0]?.status === 'missed' && true  }
              isAttended={currentAppt?.spinalAppointment?.initial[0]?.status === 'attended' && true }
                disabled={!!currentAppt?.spinalAppointment?.initial[0]||currentAppt?.spinalAppointment?.skip} 
                status={!!currentAppt?.spinalAppointment?.initial[0]||currentAppt?.spinalAppointment?.skip} 
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              <label>Surgery RX</label>
              <Checkbox id="surgery" for="surgery" />
            </div>
            <div>
              <label>Surgery Performed</label>
              <Checkbox id="surgeryPer" for="surgeryPer" />
            </div>
          </div>
        </div>
        <div className="firstEditPatientRow">
          <div>
            <label>Spinal FU1</label>
            <DatePicker iconType={"submit"}
                minDate={new Date(currentAppt?.spinalAppointment?.initial[0]?.date)}
                selected={
                  currentAppt?.spinalAppointment?.initial[1]
                    ? new Date(currentAppt?.spinalAppointment?.initial[1]?.date)
                    : dates?.initial1
                }
                  
              atendClick={()=>createUpdateStatusAppointment("updateSpinal", "spinalAppointment",currentAppt?.spinalAppointment?.initial[1]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateSpinal", "spinalAppointment",currentAppt?.spinalAppointment?.initial[1]?._id,"missed")}
             
                onChange={dateCallBack("initial1")}
                onClick={() =>
                  createUpdateAppointment("spinalAppointment", dates?.initial1,"initial1")
                }
                isMissed={currentAppt?.spinalAppointment?.initial[1]?.status === 'missed' && true  }
                isAttended={currentAppt?.spinalAppointment?.initial[1]?.status === 'attended' && true }
                disabled={!!currentAppt?.spinalAppointment?.initial[1]||currentAppt?.spinalAppointment?.skip} 
                status={!!currentAppt?.spinalAppointment?.initial[1]||currentAppt?.spinalAppointment?.skip} 
              className="iconHeightformsedit"
              />
          </div>
          <div>
            <label>Surgery Date</label>
            <DatePicker className="iconHeightformsedit" />
          </div>
        </div>
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
            <label>Spinal FU2</label>

            <DatePicker
              iconType={"submit"}
                minDate={new Date(currentAppt?.spinalAppointment?.initial[1]?.date)}
                selected={
                  currentAppt?.spinalAppointment?.initial[2]
                    ? new Date(currentAppt?.spinalAppointment?.initial[2]?.date)
                    : dates?.initial2
                }
                 
              atendClick={()=>createUpdateStatusAppointment("updateSpinal", "spinalAppointment",currentAppt?.spinalAppointment?.initial[2]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateSpinal", "spinalAppointment",currentAppt?.spinalAppointment?.initial[2]?._id,"missed")}
             
                onChange={dateCallBack("initial2")}
                onClick={() =>
                  createUpdateAppointment("spinalAppointment", dates?.initial2,"initial2")
                }
                isMissed={currentAppt?.spinalAppointment?.initial[2]?.status === 'missed' && true  }
                isAttended={currentAppt?.spinalAppointment?.initial[2]?.status === 'attended' && true }
                disabled={!!currentAppt?.spinalAppointment?.initial[2]||currentAppt?.spinalAppointment?.skip} 
                status={!!currentAppt?.spinalAppointment?.initial[2]||currentAppt?.spinalAppointment?.skip} 
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              <label>Second Surgery</label>
              <Checkbox id="surgery1" for="surgery1" />
            </div>
            <div>
              <label>Surgery Performed</label>
              <Checkbox id="surgeryPer1" for="surgeryPer1" />
            </div>
          </div>
        </div>
        <div className="firstEditPatientRow">
          <div>
            <label>Spinal FU3</label>
            <DatePicker
              iconType={"submit"}
                minDate={new Date(currentAppt?.spinalAppointment?.initial[2]?.date)}
                selected={
                  currentAppt?.spinalAppointment?.initial[3]
                    ? new Date(currentAppt?.spinalAppointment?.initial[3]?.date)
                    : dates?.initial3
                }
                    
              atendClick={()=>createUpdateStatusAppointment("updateSpinal", "spinalAppointment",currentAppt?.spinalAppointment?.initial[3]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateSpinal", "spinalAppointment",currentAppt?.spinalAppointment?.initial[3]?._id,"missed")}
              isMissed={currentAppt?.spinalAppointment?.initial[3]?.status === 'missed' && true  }
              isAttended={currentAppt?.spinalAppointment?.initial[3]?.status === 'attended' && true }
                onChange={dateCallBack("initial3")}
                onClick={() =>
                  createUpdateAppointment("spinalAppointment", dates?.initial3,"initial3")
                }
                disabled={!!currentAppt?.spinalAppointment?.initial[3]||currentAppt?.spinalAppointment?.skip} 
                status={!!currentAppt?.spinalAppointment?.initial[3]||currentAppt?.spinalAppointment?.skip} 
              className="iconHeightformsedit"
             
            />
          </div>
          <div>
            <label>Surgery Date</label>
            <DatePicker className="iconHeightformsedit" />
          </div>
        </div>
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
            <label>Spinal FU4</label>

            <DatePicker

            iconType={"submit"}
                minDate={new Date(currentAppt?.spinalAppointment?.initial[3]?.date)}
                selected={
                  currentAppt?.spinalAppointment?.initial[4]
                    ? new Date(currentAppt?.spinalAppointment?.initial[4]?.date)
                    : dates?.initial4
                }
                isMissed={currentAppt?.spinalAppointment?.initial[4]?.status === 'missed' && true  }
                isAttended={currentAppt?.spinalAppointment?.initial[4]?.status === 'attended' && true }
                   
              atendClick={()=>createUpdateStatusAppointment("updateSpinal", "spinalAppointment",currentAppt?.spinalAppointment?.initial[4]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateSpinal", "spinalAppointment",currentAppt?.spinalAppointment?.initial[4]?._id,"missed")}
             
                onChange={dateCallBack("initial4")}
                onClick={() =>
                  createUpdateAppointment("spinalAppointment", dates?.initial4,"initial4")
                }
                disabled={!!currentAppt?.spinalAppointment?.initial[4]||currentAppt?.spinalAppointment?.skip} 
                status={!!currentAppt?.spinalAppointment?.initial[4]||currentAppt?.spinalAppointment?.skip} 
            
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              <label>Third Surgery</label>
              <Checkbox id="surgery2" for="surgery2" />
            </div>
            <div>
              <label>Surgery Performed</label>
              <Checkbox id="surgeryPer2" for="surgeryPer2" />
            </div>
          </div>
        </div>
       
        <div className="clearLastNeuro">
          <img src="/images/arrowCircle.svg" alt="circular arrow" />
          <h2>Clear Last Spinal</h2>
        </div>
      </form>
    </div>
  );
};

export default SpinalAppt;
