import React, { useEffect, useState } from "react";
import DatePicker from "../datePicker/DatePicker";
import {Checkbox} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { createNueroAppointment, createOrthuAppointment,UpdateOrthuAppointmentById, updateAppointment, updateAppointmentStatus } from "../../api/functions";
import { getAllAppointments, getPatients } from "../../redux/records";
const ApptForm1 = () => {
  const [editDateOrtho, setEditDateOrtho] = useState(null);
  const { selectedPatient } = useSelector((state) => state.records);
  const [currentAppt, setCurrentAppt] = useState({
  });
  // const [dates, setDates] = useState({
  //   initial: null,
  //   initial1: null,
  //   initial2: null,
  //   initial3: null,
  //   initial4: null,
  //   initial5: null,
  //   initial6: null,
  //   initial7: null,
  //   initial8: null,
  // });
  const [dates, setDates] = useState({
    initial: currentAppt?.orthoAppointment?.initial[0]
      ? new Date(currentAppt?.orthoAppointment?.initial[0]?.date)
      : null,
      initial1: currentAppt?.orthoAppointment?.initial[1]
      ? new Date(currentAppt?.orthoAppointment?.initial[1]?.date)
      : null,
    initial2: currentAppt?.orthoAppointment?.initial[2]
      ? new Date(currentAppt?.orthoAppointment?.initial[2]?.date)
      : null,
    initial3: currentAppt?.orthoAppointment?.initial[3]
      ? new Date(currentAppt?.orthoAppointment?.initial[3]?.date)
      : null,
    initial4: currentAppt?.orthoAppointment?.initial[4]
      ? new Date(currentAppt?.orthoAppointment?.initial[4]?.date)
      : null,
    // initial5: currentAppt?.orthoAppointment?.initial[5]
    //   ? new Date(currentAppt?.orthoAppointment?.initial[5]?.date)
    //   : null,
    // initial6: currentAppt?.orthoAppointment?.initial[6]
    //   ? new Date(currentAppt?.orthoAppointment?.initial[6]?.date)
    //   : null,
    // initial7: currentAppt?.orthoAppointment?.initial[7]
    //   ? new Date(currentAppt?.orthoAppointment?.initial[7]?.date)
    //   : null,
    // initial8: currentAppt?.orthoAppointment?.initial[8]
    //   ? new Date(currentAppt?.orthoAppointment?.initial[8]?.date)
    //   : null,
  });
  //  const [dates2, setDates2] = useState({
  //   initial: null,
  //   initial1: null,
  //   initial2: null,
  //   initial3: null,
    
  // });
 

  console.log('currentAppt',currentAppt)
  const dateCallBack = (key) => (value) =>
    setDates((prevDates) => ({ ...prevDates, [key]: value }));
    //  const dateCallBack2 = (key) => (value) =>
    // setDates2((prevDates) => ({ ...prevDates, [key]: value }));
      
  const toast = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentAppt(selectedPatient?.appointment);
  }, [selectedPatient]);

  useEffect(() => {
    console.log("useEffect for dates");
    setDates({
      initial: currentAppt?.orthoAppointment?.initial[0]
        ? new Date(currentAppt?.orthoAppointment?.initial[0]?.date)
        : null,
      initial1: currentAppt?.orthoAppointment?.initial[1]
        ? new Date(currentAppt?.orthoAppointment?.initial[1]?.date)
        : null,
      initial2: currentAppt?.orthoAppointment?.initial[2]
        ? new Date(currentAppt?.orthoAppointment?.initial[2]?.date)
        : null,
      initial3: currentAppt?.orthoAppointment?.initial[3]
        ? new Date(currentAppt?.orthoAppointment?.initial[3]?.date)
        : null,
      initial4: currentAppt?.orthoAppointment?.initial[4]
        ? new Date(currentAppt?.orthoAppointment?.initial[4]?.date)
        : null,
    });

  }, [currentAppt?.orthoAppointment]);


  const createUpdateAppointment = async (type,date,followUp) => {
    try {
      await createOrthuAppointment(
        type,
        {
          patientId:selectedPatient?._id,
          date:date,
          initial:followUp,
          status:"upcoming"
          },
        
      );
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const createUpdateAppointmentById = async (type, date, followUp, id) => {
    try {
      await UpdateOrthuAppointmentById(type, {
        patientId: selectedPatient?._id,
        date: date,
        initial: followUp,
        status: "upcoming",
        initialId: id,
      });
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

        const createUpdateStatusAppointment = async (type,AppointmentType,followUpid,status) => {
 
    try {
      await updateAppointmentStatus(
        type,
        {
          patientId:selectedPatient?._id,
           type:AppointmentType,
          initialId:followUpid,
          status:status
          },

        
      );
      toast.success("Success");
      dispatch(getAllAppointments());
      dispatch(getPatients());
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };


  return (
    <div className="outsideRef">
      <form className="outRef">
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
            <div className="neuroLableRef">
              <label>Next Ortho appt</label>
              <div className="imgeCircle">
                <img src="/images/arrowCircle.svg" alt="circular arrow" />
                <p>clear Next Neuro</p>
              </div>
            </div>
            <DatePicker
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
              selected={
             currentAppt?.date
              }
              disabled
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              {" "}
              <label>Ortho PRN</label>
              <Checkbox
                checked={currentAppt?.orthoAppointment?.skip}
                disabled={currentAppt?.orthoAppointment?.initial[0]}
                onChange={async (e) => {
                   try {
                    await createOrthuAppointment(
                      "orthoAppointment",
                      {
                        patientId:selectedPatient?._id,
                        initial:"skip",
                        value:e.target.checked
                        },
                      
                    );
                    toast.success("Success");
                    dispatch(getAllAppointments());
                    dispatch(getPatients());
                  } catch (error) {
                    toast.error(error.response?.data?.message || error.message);
                  }
                }}
                id="orthoPRN"
                for="orthoPRN"
              />
            </div>
          </div>
        </div>
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
          <div className="label-with-icon">
              <label>Orhto</label>
              {currentAppt?.orthoAppointment?.initial[0]?.status ==
                "upcoming" && (
                <div className="edit-icon" onClick={() => setEditDateOrtho("OF0")}>
                  <img src="/images/edit.svg" alt="edit" />
                </div>
              )}
            </div>

            <DatePicker
              iconType={"submit"}
                // minDate={new Date(currentAppt?.date)}
                selected={
                  currentAppt?.orthoAppointment?.initial[0]
                    ? new Date(currentAppt?.orthoAppointment?.initial[0]?.date)
                    : dates?.initial
                }
                  status={!!currentAppt?.orthoAppointment?.initial[0]||currentAppt?.orthoAppointment?.skip}

              atendClick={()=>createUpdateStatusAppointment("updateOrtho", "orthoAppointment",currentAppt?.orthoAppointment?.initial[0]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateOrtho", "orthoAppointment",currentAppt?.orthoAppointment?.initial[0]?._id,"missed")}
             
                onChange={dateCallBack("initial")}
                onClick={() =>
                editDateOrtho == "OF0"
                  ? createUpdateAppointmentById(
                      "orthoAppointment",
                      dates?.initial,
                      "initial",
                      currentAppt?.orthoAppointment?.initial[0]?._id
                    )
                  : createUpdateAppointment(
                      "orthoAppointment",
                      dates?.initial,
                      "initial"
                    )
              }
                // onClick={() =>
                //   createUpdateAppointment("orthoAppointment", dates?.initial,"initial")
                // }
                isMissed={currentAppt?.orthoAppointment?.initial[0]?.status === 'missed' && true  }
              isAttended={currentAppt?.orthoAppointment?.initial[0]?.status === 'attended' && true }
                // disabled={!!currentAppt?.orthoAppointment?.initial[0]||currentAppt?.orthoAppointment?.skip} 
                disabled={
                (editDateOrtho == "OF0"
                  ? !!editDateOrtho == "OF0"
                  : !!currentAppt?.orthoAppointment?.initial[0]) ||
                (currentAppt?.orthoAppointment?.initial[0]?.status ==
                  "missed" &&
                  true) ||
                (currentAppt?.orthoAppointment?.initial[0]?.status ==
                  "attended" &&
                  true)
              }
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              <label>Surgery RX</label>
              <Checkbox id="surgery" for="surgery" />
            </div>
            <div>
              <label>Surgery Performed</label>
              <Checkbox id="surgeryPer" for="surgeryPer" />
            </div>
          </div>
        </div>
        <div className="firstEditPatientRow">
          <div>
          <div className="label-with-icon">
              <label>Ortho FU1</label>
              {currentAppt?.orthoAppointment?.initial[1]?.status ==
                "upcoming" && (
                <div className="edit-icon" onClick={() => setEditDateOrtho("OF1")}>
                  <img src="/images/edit.svg" alt="edit" />
                </div>
              )}
            </div>
            <DatePicker iconType={"submit"}
                // minDate={new Date(currentAppt?.orthoAppointment?.initial[0]?.date)}
                selected={
                  currentAppt?.orthoAppointment?.initial[1]
                    ? new Date(currentAppt?.orthoAppointment?.initial[1]?.date)
                    : dates?.initial1
                }
                isMissed={currentAppt?.orthoAppointment?.initial[1]?.status === 'missed' && true  }
                isAttended={currentAppt?.orthoAppointment?.initial[1]?.status === 'attended' && true }
              atendClick={()=>createUpdateStatusAppointment("updateOrtho", "orthoAppointment",currentAppt?.orthoAppointment?.initial[1]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateOrtho", "orthoAppointment",currentAppt?.orthoAppointment?.initial[1]?._id,"missed")}
             
                onChange={dateCallBack("initial1")}
                onClick={() =>
                editDateOrtho == "OF1"
                  ? createUpdateAppointmentById(
                      "orthoAppointment",
                      dates?.initial1,
                      "initial1",
                      currentAppt?.orthoAppointment?.initial[1]?._id
                    )
                  : createUpdateAppointment(
                      "orthoAppointment",
                      dates?.initial1,
                      "initial1"
                    )
              }
              disabled={
                (editDateOrtho == "OF1"
                  ? !!editDateOrtho == "OF1"
                  : !!currentAppt?.orthoAppointment?.initial[1]) ||
                (currentAppt?.orthoAppointment?.initial[1]?.status ==
                  "missed" &&
                  true) ||
                (currentAppt?.orthoAppointment?.initial[1]?.status ==
                  "attended" &&
                  true)
              }
                // onClick={() =>
                //   createUpdateAppointment("orthoAppointment", dates?.initial1,"initial1")
                // }
                // disabled={!!currentAppt?.orthoAppointment?.initial[1]||currentAppt?.orthoAppointment?.skip} 
                status={!!currentAppt?.orthoAppointment?.initial[1]||currentAppt?.orthoAppointment?.skip} 
              className="iconHeightformsedit"
              />
          </div>
          <div>
            <label>Surgery Date</label>
            <DatePicker className="iconHeightformsedit" />
          </div>
        </div>
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
          <div className="label-with-icon">
            <label>Orhto FU2</label>
              {currentAppt?.orthoAppointment?.initial[2]?.status ==
                "upcoming" && (
                <div className="edit-icon" onClick={() => setEditDateOrtho("OF2")}>
                  <img src="/images/edit.svg" alt="edit" />
                </div>
              )}
            </div>

            <DatePicker
              iconType={"submit"}
                // minDate={new Date(currentAppt?.orthoAppointment?.initial[1]?.date)}
                selected={
                  currentAppt?.orthoAppointment?.initial[2]
                    ? new Date(currentAppt?.orthoAppointment?.initial[2]?.date)
                    : dates?.initial2
                }
                isMissed={currentAppt?.orthoAppointment?.initial[2]?.status === 'missed' && true  }
                isAttended={currentAppt?.orthoAppointment?.initial[2]?.status === 'attended' && true }
              atendClick={()=>createUpdateStatusAppointment("updateOrtho", "orthoAppointment",currentAppt?.orthoAppointment?.initial[2]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateOrtho", "orthoAppointment",currentAppt?.orthoAppointment?.initial[2]?._id,"missed")}
             
                onChange={dateCallBack("initial2")}
                onClick={() =>
                editDateOrtho == "OF2"
                  ? createUpdateAppointmentById(
                      "orthoAppointment",
                      dates?.initial2,
                      "initial2",
                      currentAppt?.orthoAppointment?.initial[2]?._id
                    )
                  : createUpdateAppointment(
                      "orthoAppointment",
                      dates?.initial2,
                      "initial2"
                    )
              }
              disabled={
                (editDateOrtho == "OF2"
                  ? !!editDateOrtho == "OF2"
                  : !!currentAppt?.orthoAppointment?.initial[2]) ||
                (currentAppt?.orthoAppointment?.initial[2]?.status ==
                  "missed" &&
                  true) ||
                (currentAppt?.orthoAppointment?.initial[2]?.status ==
                  "attended" &&
                  true)
              }
                // onClick={() =>
                //   createUpdateAppointment("orthoAppointment", dates?.initial2,"initial2")
                // }
                // disabled={!!currentAppt?.orthoAppointment?.initial[2]||currentAppt?.orthoAppointment?.skip} 
                status={!!currentAppt?.orthoAppointment?.initial[2]||currentAppt?.orthoAppointment?.skip} 
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              <label>Second Surgery</label>
              <Checkbox id="surgery1" for="surgery1" />
            </div>
            <div>
              <label>Surgery Performed</label>
              <Checkbox id="surgeryPer1" for="surgeryPer1" />
            </div>
          </div>
        </div>
        <div className="firstEditPatientRow">
          <div>
          <div className="label-with-icon">
          <label>Ortho FU3</label>
              {currentAppt?.orthoAppointment?.initial[3]?.status ==
                "upcoming" && (
                <div className="edit-icon" onClick={() => setEditDateOrtho("OF3")}>
                  <img src="/images/edit.svg" alt="edit" />
                </div>
              )}
            </div>
            <DatePicker
              iconType={"submit"}
                // minDate={new Date(currentAppt?.orthoAppointment?.initial[2]?.date)}
                selected={
                  currentAppt?.orthoAppointment?.initial[3]
                    ? new Date(currentAppt?.orthoAppointment?.initial[3]?.date)
                    : dates?.initial3
                }
                isMissed={currentAppt?.orthoAppointment?.initial[3]?.status === 'missed' && true  }
              isAttended={currentAppt?.orthoAppointment?.initial[3]?.status === 'attended' && true }
              atendClick={()=>createUpdateStatusAppointment("updateOrtho", "orthoAppointment",currentAppt?.orthoAppointment?.initial[3]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateOrtho", "orthoAppointment",currentAppt?.orthoAppointment?.initial[3]?._id,"missed")}
             
                onChange={dateCallBack("initial3")}
                onClick={() =>
                editDateOrtho == "OF3"
                  ? createUpdateAppointmentById(
                      "orthoAppointment",
                      dates?.initial3,
                      "initial3",
                      currentAppt?.orthoAppointment?.initial[3]?._id
                    )
                  : createUpdateAppointment(
                      "orthoAppointment",
                      dates?.initial3,
                      "initial3"
                    )
              }
              disabled={
                (editDateOrtho == "OF3"
                  ? !!editDateOrtho == "OF3"
                  : !!currentAppt?.orthoAppointment?.initial[3]) ||
                (currentAppt?.orthoAppointment?.initial[3]?.status ==
                  "missed" &&
                  true) ||
                (currentAppt?.orthoAppointment?.initial[3]?.status ==
                  "attended" &&
                  true)
              }
                // onClick={() =>
                //   createUpdateAppointment("orthoAppointment", dates?.initial3,"initial3")
                // }
                // disabled={!!currentAppt?.orthoAppointment?.initial[3]||currentAppt?.orthoAppointment?.skip} 
                status={!!currentAppt?.orthoAppointment?.initial[3]||currentAppt?.orthoAppointment?.skip} 
              className="iconHeightformsedit"
             
            />
          </div>
          <div>
          <label>Surgery Date</label>
            <DatePicker className="iconHeightformsedit" />
          </div>
        </div>
        <div className="neuroFirstRow">
          <div className="nextNeuroRef">
          <div className="label-with-icon">
            <label>Orhto FU4</label>
              {currentAppt?.orthoAppointment?.initial[4]?.status ==
                "upcoming" && (
                <div className="edit-icon" onClick={() => setEditDateOrtho("OF4")}>
                  <img src="/images/edit.svg" alt="edit" />
                </div>
              )}
            </div>

            <DatePicker

            iconType={"submit"}
                // minDate={new Date(currentAppt?.orthoAppointment?.initial[3]?.date)}
                selected={
                  currentAppt?.orthoAppointment?.initial[4]
                    ? new Date(currentAppt?.orthoAppointment?.initial[4]?.date)
                    : dates?.initial4
                }
                      isMissed={currentAppt?.orthoAppointment?.initial[4]?.status === 'missed' && true  }
              isAttended={currentAppt?.orthoAppointment?.initial[4]?.status === 'attended' && true }
              atendClick={()=>createUpdateStatusAppointment("updateOrtho", "orthoAppointment",currentAppt?.orthoAppointment?.initial[4]?._id,"attended")}
              missClick={()=>createUpdateStatusAppointment("updateOrtho", "orthoAppointment",currentAppt?.orthoAppointment?.initial[4]?._id,"missed")}
             
                onChange={dateCallBack("initial4")}
                onClick={() =>
                editDateOrtho == "OF4"
                  ? createUpdateAppointmentById(
                      "orthoAppointment",
                      dates?.initial4,
                      "initial4",
                      currentAppt?.orthoAppointment?.initial[4]?._id
                    )
                  : createUpdateAppointment(
                      "orthoAppointment",
                      dates?.initial4,
                      "initial4"
                    )
              }
              disabled={
                (editDateOrtho == "OF4"
                  ? !!editDateOrtho == "OF4"
                  : !!currentAppt?.orthoAppointment?.initial[4]) ||
                (currentAppt?.orthoAppointment?.initial[4]?.status ==
                  "missed" &&
                  true) ||
                (currentAppt?.orthoAppointment?.initial[4]?.status ==
                  "attended" &&
                  true)
              }
                // onClick={() =>
                //   createUpdateAppointment("orthoAppointment", dates?.initial4,"initial4")
                // }
                // disabled={!!currentAppt?.orthoAppointment?.initial[4]||currentAppt?.orthoAppointment?.skip} 
                status={!!currentAppt?.orthoAppointment?.initial[4]||currentAppt?.orthoAppointment?.skip} 
            
              className="iconHeightformsedit"
              mainClass="widthOrthoDatePicker"
            />
          </div>
          <div className="neuroCheckBoxRef">
            <div>
              <label>Third Surgery</label>
              <Checkbox id="surgery2" for="surgery2" />
            </div>
            <div>
              <label>Surgery Performed</label>
              <Checkbox id="surgeryPer2" for="surgeryPer2" />
            </div>
          </div>
        </div>
       
        <div className="clearLastNeuro">
          <img src="/images/arrowCircle.svg" alt="circular arrow" />
          <h2>Clear Last ortho</h2>
        </div>
      </form>
    </div>
  );
};

export default ApptForm1;
