import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";
import DatePicker from "../../components/datePicker/DatePicker";
import MainModal from "../../components/modal/MainModal";
import { showModalActions } from "../../redux/showingModel";
import Select from "../../components/Select/Select";

import { updatePatient } from "../../api/functions";
import { getPatients } from "../../redux/records";
import AntdSelect from "../../components/Select/AntdSelect";
import { useAlert } from "react-alert";

const EditPatientDetail = ({ patient }) => {
  const [data, setData] = useState();
  const { records } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    setData(patient);
  }, [patient]);

  const attorneyOptions = records?.attornies?.map((record) => ({
    value: record?._id,
    label: record?.attorney,
    key: record?._id,
  }));
  const caseOptions = [
    {
      value: "No Fault",
      label: "No Fault",
    },
    {
      value: "Cash",
      label: "Cash",
    },
    {
      value: "WorkComp",
      label: "WorkComp",
    },
    {
      value: "Major Medical",
      label: "Major Medical",
    },
    {
      value: "Lien",
      label: "Lien",
    },
    {
      value: "Work",
      label: "Work",
    },
  ];

  const closeEditingDetails = () => {
    dispatch(showModalActions.toggleDetails());
  };
  const callback = (key) => {
    return (value) => {
      setData({ ...data, [key]: new Date(value) });
    };
  };

  const OVWOptions = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
  ];

  const handleKeyDown = (event) => {
    if (!/\d/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const toast = useAlert();
  return (
    <MainModal>
      {data ? (
        <div className="editDetails">
          <button className="crossDetails" onClick={closeEditingDetails}>
            x
          </button>
          <h1> Edit patient details</h1>
          <form
            onChange={({ target: { name, value, checked } }) =>
              setData((prevState) => ({
                ...prevState,
                [name]: value.length > 0 ? value : checked,
              }))
            }
            onSubmit={async (e) => {
              e.preventDefault();

              try {
                await updatePatient(data, data?._id);
                dispatch(getPatients())
                  .unwrap()
                  .then((e) => {
                    dispatch(showModalActions.toggleDetails());
                    toast.success("Patient update successfuly");
                  });
              } catch (error) {
                toast.error(error?.response?.data?.message || error?.message);
              }
            }}
            className="editForm"
          >
            <div className="formInputs">
              <div className="inputs">
                <label>Patient Id</label>
                <input
                  disabled
                  value={data?.patientId}
                  type="text"
                  placeholder="Patient id"
                  name="patientId"
                />
              </div>
              <div className="radioInputs">
                <div>
                  <label>Disabled</label>
                  <Checkbox
                    checked={data?.disabled}
                    htmlfor="Disabled"
                    id="Disabled"
                    name="disabled"
                  />
                </div>
                <div>
                  <label>Transportation</label>

                  <Checkbox
                    checked={data?.transportation}
                    htmlfor="Transport"
                    id="Transport"
                    name="transportation"
                  />
                </div>
                <div>
                  <label>X-ray</label>

                  <Checkbox
                    name="x_ray"
                    checked={data?.x_ray}
                    id="x-ray"
                    htmlfor="x-ray"
                  />
                </div>
                {/* <div>
            <label>OV/W</label>
            <Checkbox
              name="officialVisits"
              checked={data?.officialVisits}
              id="OV"
              htmlfor="OV"
            />
          </div> */}
              </div>
            </div>
            {
              <>
                <div className="formInputs">
                  <div className="inputs">
                    <label>First Name</label>
                    <input
                      value={data?.firstName}
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                    />
                  </div>
                  <div className="inputs">
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={data?.lastName}
                      placeholder="Last Name"
                      name="lastName"
                    />
                  </div>
                </div>
                <div className="formInputs">
                  <div className="inputs">
                    <label>Phone Number</label>
                    <input
                      value={data?.phoneNumber || ""}
                      type="text"
                      placeholder="Enter your Phone Number"
                      name="phoneNumber"
                      onKeyDown={handleKeyDown}
                      onChange={(value) => {
                        setData({
                          ...data,
                          phoneNumber: value,
                        });
                      }}
                    />
                  </div>
                  <div className="inputs">
                    <label>Date Of Birth</label>
                    <DatePicker
                      onChange={callback("dateOfBirth")}
                      selected={data?.dateOfBirth}
                      className="iconHeightformseditDetails"
                      name={"dateOfBirth"}
                      // disabled={true}
                    />{" "}
                  </div>
                </div>
                <div className="formInputs">
                  <div className="inputs">
                    <label>D/A</label>
                    <DatePicker
                      onChange={callback("dateOfAdmission")}
                      selected={data?.dateOfAdmission}
                      className="iconHeightformseditDetails"
                      name={"dateOfAdmission"}
                      // disabled={true}
                    />
                  </div>
                  <div className="inputs">
                    <label>D/E</label>
                    <DatePicker
                      onChange={callback("dateOfEntry")}
                      selected={data?.dateOfEntry}
                      className="iconHeightformseditDetails"
                      name={"dateOfEntry"}
                      // disabled={true}
                    />{" "}
                  </div>
                </div>
              </>
            }

            <div className="formInputs">
              <div className="inputs">
                <label>Case Type</label>
                <AntdSelect
                  selectValue={data?.caseType}
                  options={caseOptions}
                  placeholder="Select Case Type"
                  hanldeChange={(value) =>
                    setData({ ...data, caseType: value })
                  }
                  popUpClass="popUpClassSelect"
                />
              </div>
              <div className="inputs">
                <label>Attorney</label>
                <AntdSelect
                  selectValue={data?.attorney}
                  options={attorneyOptions}
                  hanldeChange={(value) =>
                    setData({ ...data, attorney: value })
                  }
                  popUpClass="popUpClassSelect"
                  placeholder={"Select Attorney"}
                />
              </div>
            </div>
            <div className="formInputs">
              {/* <div className="inputs">
          <label>New MD Appointment</label>
          <input type="text" placeholder="Enter new MD appointment " />
        </div> */}
              <div className="inputs">
                <label>Referred By</label>
                <input
                  value={data?.referredBy}
                  type="text"
                  name="referredBy"
                  placeholder="Enter Referral Name"
                  onChange={(value) => {
                    setData({
                      ...data,
                      referredBy: value,
                    });
                  }}
                />
              </div>
              <div className="inputs">
                <label>OV/W</label>
                <AntdSelect
                  hanldeChange={(value) => {
                    setData({ ...data, oVisits: parseInt(value) });
                  }}
                  options={OVWOptions}
                  placeholder="Select OVW "
                  selectValue={data?.oVisits}
                  popUpClass="popUpClassSelect"
                />

                {/* <select  name="ov_W" className="selectOVW"
        
          
          
          >
            <option  disabled hidden>
              Select OV/W
            </option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            {data?.oVisits && 
            
            <option value={data?.oVisits}  selected>{data?.oVisits}</option>
            }
           
         
          </select> */}
              </div>
            </div>

            <div className="updateBtns">
              <button className="cancelDetails" onClick={closeEditingDetails}>
                Cancel
              </button>
              <button type="submit" className="updateDetails">
                {" "}
                Update
              </button>
            </div>
          </form>
        </div>
      ) : (
        <>Loading</>
      )}
    </MainModal>
  );
};

export default EditPatientDetail;
