import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";
import DatePicker from "../../../components/datePicker/DatePicker";
import Select from "../../../components/Select/Select";
import { getPatients } from "../../../redux/records";
import { updatePatient } from "../../../api/functions";
import { useAlert } from "react-alert";
import AntdSelect from "../../../components/Select/AntdSelect";
const EditPatient = () => {
  const { specialist } = useSelector((state) => state.records);
  const [formData, setFormData] = useState({ name: "", specialist: "" });
  const { selectedPatient } = useSelector((state) => state.records);

  const [loading, setLoading] = useState(true);
  const { records } = useSelector((state) => state);

  const [patientData, setPatientData] = useState();
  useEffect(() => {
    setPatientData(selectedPatient);
    setLoading(false);
    return () => {
      setPatientData({});
    };
  }, [selectedPatient]);

  const OVWOptions = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
  ];
  const caseOptions = [
    {
      value: "No Fault",
      label: "No Fault",
    },
    {
      value: "Cash",
      label: "Cash",
    },
    {
      value: "WorkComp",
      label: "WorkComp",
    },
    {
      value: "Major Medical",
      label: "Major Medical",
    },
    {
      value: "Lien",
      label: "Lien",
    },
    {
      value: "Work",
      label: "Work",
    },
  ];
  const attorneyOptions = records?.attornies?.map((record) => ({
    value: record?._id,
    label: record?.attorney,
    key: record?._id,
  }));
  console.log("patientData", patientData);
  // console.log("patientData?.phoneNumber", patientData?.phoneNumber);

  const callback = (key) => {
    return (value) => {
      setPatientData({ ...patientData, [key]: new Date(value) });
    };
  };

  const handleKeyDown = (event) => {
    if (!/\d/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const dispatch = useDispatch();
  const toast = useAlert();
  return (
    <div className="editTypePatient">
      {!loading && patientData && (
        <form
          onChange={({ target: { name, value, checked } }) => {
            setPatientData((prevState) => ({
              ...prevState,
              [name]: !!value.length ? value : checked,
            }));
          }}
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);

            try {
              await updatePatient(patientData, patientData?._id);
              dispatch(getPatients())
                .unwrap()
                .then((e) => {
                  toast.success("Patient update successfuly");
                });
            } catch (error) {
              toast.error(error?.response?.data?.message || error?.message);
            }
            setLoading(false);
          }}
        >
          <div className="editPatientInput">
            <div className="firstEditPatientRow">
              <div>
                <label>Phone</label>
                <input
                  value={patientData?.phoneNumber ? patientData?.phoneNumber : ""}
                  type="text"
                  placeholder="(784) 957-98775"
                  name="phoneNumber"
                  onKeyDown={handleKeyDown}
                  onChange={(value) => {
                    setPatientData({
                      ...patientData,
                      phoneNumber: value,
                    });
                  }}
                />
              </div>
              <div>
                <label>DOB</label>
                <DatePicker
                  selected={
                    patientData?.dateOfBirth
                      ? new Date(patientData?.dateOfBirth)
                      : null
                  }
                  className="iconHeightformsedit"
                  name={"dateOfBirth"}
                  onChange={callback("dateOfBirth")}
                  // disabled={true}
                />
              </div>
            </div>

            <div className="firstEditPatientRow">
              <div>
                <label>D/A</label>
                <DatePicker
                  selected={
                    patientData?.dateOfAdmission
                      ? new Date(patientData?.dateOfAdmission)
                      : null
                  }
                  className="iconHeightformsedit"
                  name={"dateOfAdmission"}
                  onChange={callback("dateOfAdmission")}
                  // disabled={true}
                />
              </div>
              <div>
                <label>D/E</label>
                <DatePicker
                  selected={
                    patientData?.dateOfEntry
                      ? new Date(patientData?.dateOfEntry)
                      : null
                  }
                  className="iconHeightformsedit"
                  name={"dateOfEntry"}
                  onChange={callback("dateOfEntry")}
                  // disabled={true}
                />
              </div>
            </div>
            <div className="firstEditPatientRow">
              <div>
                <label>Case Type</label>
                <AntdSelect
                  selectValue={patientData?.caseType}
                  options={caseOptions}
                  placeholder="Select Case Type"
                  hanldeChange={(value) =>
                    setPatientData({ ...patientData, caseType: value })
                  }
                  popUpClass="popUpClassSelect"
                />
              </div>
              <div>
                {/* <label>Attorney</label>
                <select defaultValue={patientAttorney} name="attorney">
                  {allAttorneys?.map(attr=>
                    <option value={attr?.id}>{attr?.attorney}</option>
                    )}
                </select> */}
                <label>Attorney</label>
                <AntdSelect
                  selectValue={patientData?.attorney}
                  options={attorneyOptions}
                  hanldeChange={(value) =>
                    setPatientData({ ...patientData, attorney: value })
                  }
                  popUpClass="popUpClassSelect"
                  placeholder={"Select Attorney"}
                />
              </div>
            </div>
            <div className="firstEditPatientRow">
              <div>
                <label>OV/W</label>
                <AntdSelect
                  hanldeChange={(value) => {
                    setPatientData({
                      ...patientData,
                      oVisits: parseInt(value),
                    });
                  }}
                  options={OVWOptions}
                  placeholder="Select OV/W "
                  selectValue={patientData?.oVisits}
                  popUpClass="popUpClassSelect"
                />
              </div>
              <div>
                <label>Specialist</label>
                <select
                  onChange={(e) => {
                    {
                      console.log(e.target.value);
                    }
                    setPatientData({
                      ...patientData,
                      specialist: e.target.value,
                    });
                  }}
                  // defaultValue={patientData?.specialist?.name}
                  className="specialist-select"
                  required
                  name="specialist"
                >
                  <option disabled selected hidden>
                    Select specialist
                  </option>
                  {specialist.map((specialist) => (
                    <option
                      selected={patientData.specialist?._id === specialist?._id}
                      value={specialist?._id}
                    >
                      {specialist?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <div className="radioInputs">
                <div>
                  <label>Disabled</label>
                  <Checkbox
                    checked={patientData.disabled}
                    for="Disabled"
                    id="Disabled"
                    name="disabled"
                  />
                </div>
                <div>
                  <label>Transportation</label>

                  <Checkbox
                    name="transportation"
                    checked={patientData.transportation}
                    for="Transport"
                    id="Transport"
                  />
                </div>
                <div>
                  <label>X-ray</label>

                  <Checkbox
                    checked={patientData.x_ray}
                    id="x-ray"
                    for="x-ray"
                    name="x_ray"
                  />
                </div>
                {/* <div>
                    <label>OV/W</label>
                    <Checkbox
                      name="officialVisits"
                      checked={patientData.officialVisits}
                      id="OV"
                      for="OV"
                    />
                  </div> */}
              </div>
            </div>
          </div>
          <div className="mdBtnSave">
            <button> Update & Save</button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditPatient;
