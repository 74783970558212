import React from "react";
import ReportTop from "../../components/reports/ReportTop";
import DateRangePicker from "react-bootstrap-daterangepicker";

const ReportMRI = () => {
  return (
    <>
      <ReportTop name="MRI" display="none" />
      <div className="reportMri">
        <div className="mriPrintIcon">
          <div className="mridateInput">
            <label>Date Range</label>
            <DateRangePicker>
              <input type="text" />
            </DateRangePicker>
          </div>
          <div className="iconPrint">
            <img src="/images/printBlue.svg" alt="print" />
            <p>Print Report</p>
          </div>
        </div>
        <div className="reportMriDetailHeading">
          <h3>Date</h3>
          <h3>Patient Name</h3>
          <h3>Area</h3>
        </div>
        <div className="barwithDate">
          <div className="barDateReport">
            <h3>Medical Arts Radiology</h3>
            <h3>Total=10</h3>
          </div>
        </div>
        <div className="reportMriDetails">
          <p>8/1/2021</p>
          <p>John Doe</p>
          <p>Lumber</p>
        </div>
        <div className="reportMriDetails">
          <p>8/1/2021</p>
          <p>John Doe</p>
          <p>Lumber</p>
        </div>
        <div className="reportMriDetails">
          <p>8/1/2021</p>
          <p>John Doe</p>
          <p>Lumber</p>
        </div>
        <div className="reportMriDetails">
          <p>8/1/2021</p>
          <p>John Doe</p>
          <p>Lumber</p>
        </div>
        <div className="reportMriDetails">
          <p>8/1/2021</p>
          <p>John Doe</p>
          <p>Lumber</p>
        </div>
        <div className="reportMriDetails">
          <p>8/1/2021</p>
          <p>John Doe</p>
          <p>Lumber</p>
        </div>
        <div className="reportMriDetails">
          <p>8/1/2021</p>
          <p>John Doe</p>
          <p>Lumber</p>
        </div>
      </div>
    </>
  );
};

export default ReportMRI;
