import React from 'react'

const PhysicalTherapy = () => {
  return (
    <div className="showPatientsDetail">
                <div className="modalAppointPatientDetail">
                  <div>
                    <h5>Next MD appt</h5>
                    <p>9/9/1998</p>
                  </div>

                  <div>
                    <h5>Next MG2 request</h5>
                    <p>6/8/1969</p>
                  </div>

                  <div>
                    <h5>MD PRN</h5>
                    <p>-</p>
                  </div>

                  <div>
                    <h5>No MG2 request</h5>
                    <p>-</p>
                  </div>
                  <div>
                    {/* Div for spacing only  */}
                  </div>
                  
                </div>
                <div className="modalAppointPatientDetail2">
                  <div>
                    <h5>Intial</h5>
                    <p>6/91968</p>
                  </div>

                  <div>
                    <h5>Internist FU5</h5>
                    <p>6/91968</p>
                  </div>

                  <div>
                    <h5>MG2 request</h5>
                    <p>6/91968</p>
                  </div>

                  
                  
                </div>
                <div className="modalAppointPatientDetail2">
                  <div>
                    <h5>Internist FU1</h5>
                    <p>6/91968</p>
                  </div>

                  <div>
                    <h5>Internist FU6</h5>
                    <p>6/91968</p>
                  </div>

                  <div>
                    <h5>MG2 request 2</h5>
                    <p>6/91968</p>
                  </div>

                  
                  
                </div>
                <div className="modalAppointPatientDetail2">
                  <div>
                    <h5>Internist FU2</h5>
                    <p>6/91968</p>
                  </div>

                  <div>
                    <h5>Internist FU7</h5>
                    <p>6/91968</p>
                  </div>

                  <div>
                    <h5>MG2 request 3</h5>
                    <p>6/91968</p>
                  </div>

                  
                  
                </div>
                <div className="modalAppointPatientDetail2">
                  <div>
                    <h5>Internist FU3</h5>
                    <p>6/91968</p>
                  </div>

                  <div>
                    <h5>Internist FU8</h5>
                    <p>6/91968</p>
                  </div>

                  <div>
                    <h5>MG2 request 4</h5>
                    <p>6/91968</p>
                  </div>

                  
                  
                </div>
                <div className="modalAppointPatientDetail2">
                  <div>
                    <h5>Internist FU4</h5>
                    <p>6/91968</p>
                  </div>

                  <div>
                    <h5>Internist FU9</h5>
                    <p>6/91968</p>
                  </div>

                  <div>
                    <h5>MG2 request 5</h5>
                    <p>6/91968</p>
                  </div>

                  
                  
                </div>
              </div>
  )
}

export default PhysicalTherapy