import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllPatients,
  getAllSpecialist,
  getAllSpeciality,
  getAppointments,
  getUtilities,
} from "../api/functions";

const helper = async (callback) => {
  const data = await callback();
  return data.data;
};

const errorHelper = (error) =>
  (error.response && error.response.data && error.response.data.message) ||
  error.message ||
  error.toString();

export const getPatients = createAsyncThunk(
  "record/patients",
  async (_data, thunkAPI) => {
    try {
      const data = await helper(getAllPatients);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(errorHelper(error));
    }
  }
);
export const getAllAppointments = createAsyncThunk(
  "record/appointments",
  async (_data, thunkAPI) => {
    try {
      const data = await helper(getAppointments);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(errorHelper(error));
    }
  }
);
export const getSpecialist = createAsyncThunk(
  "record/specialists",
  async (_data, thunkAPI) => {
    try {
      const data = await helper(getAllSpecialist);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(errorHelper(error));
    }
  }
);

export const getSpeciality = createAsyncThunk(
  "record/speciality",
  async (_data, thunkAPI) => {
    try {
      const data = await helper(getAllSpeciality);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(errorHelper(error));
    }
  }
);
export const getAllAttornies = createAsyncThunk(
  "record/attornies",
  async (_data, thunkAPI) => {
    try {
      const data = await helper(() => getUtilities("all-attornies"));
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(errorHelper(error));
    }
  }
);
export const getAllMri = createAsyncThunk(
  "record/mri",
  async (_data, thunkAPI) => {
    try {
      const data = await helper(() => getUtilities("all-mrifacility"));
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(errorHelper(error));
    }
  }
);

export const recordsSlice = createSlice({
  name: "records",
  initialState: {
    patients: [],
    appointments: [],
    attornies: [],
    selectedPatient: {},
    specialist: [],
    mriFacility: [],
    specialities: [],
  },
  reducers: {
    changeSelectedPatient: (state, action) => {
      state.selectedPatient = action.payload;
    },
  },

  extraReducers: {
    [getPatients.fulfilled]: (state, action) => {
      state.patients = action.payload.data;
      state.selectedPatient = action.payload.data[0];
    },
    [getAllAppointments.fulfilled]: (state, action) => {
      state.appointments = action.payload.data;
    },
    [getSpecialist.fulfilled]: (state, action) => {
      state.specialist = action.payload.specialist;
    },
    [getAllAttornies.fulfilled]: (state, action) => {
      state.attornies = action.payload.attornies;
    },
    [getAllMri.fulfilled]: (state, action) => {
      state.mriFacility = action.payload.mri_facility;
    },
    [getSpeciality.fulfilled]: (state, action) => {
      state.specialities = action.payload.specialities;
    },
  },
});
export const recordAction = recordsSlice.actions;