import { useEffect } from "react";
import AddUtils from "../../components/utility/AddUtils";
import UtilityTop from "../../components/utility/UtilityTop";

import { Space, Table, DatePicker } from "antd";
import { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteMri } from "../../api/functions";
import { getAllMri } from "../../redux/records";
import UpdateMri from "../../components/utility/UpdateMri";
import { useAlert } from "react-alert";
import { getMriPatients } from "../../api/functions";

const MriFacility = () => {
  const { RangePicker } = DatePicker;
  const { mriFacility } = useSelector((state) => state.records);
  const dispatch = useDispatch();
  const [showMRI, setShowMRI] = useState(false);
  const [showUpdateMRI, setShowUpdateMRI] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seleted, setSelected] = useState("");
  const [mriPatients, setMriPatients] = useState(null);
  const toast = useAlert();

  // filtering records
  const [selectedOption, setSelectedOption] = useState(null); // Default selected option
  const [filteredPatients, setFilteredPatients] = useState([]);

  const [dateRange, setDateRange] = useState(null);

  const showMriFacility = () => {
    setShowMRI(!showMRI);
  };
  const closeMriModal = () => {
    setShowMRI(!showMRI);
  };
  const closeUpdateMriModal = () => {
    setShowUpdateMRI(!showUpdateMRI);
  };
  const columns = useMemo(
    () => [
      {
        title: "MRI Facility",
        dataIndex: "mriFacility",
      },
      {
        title: "Address",
        dataIndex: "address",
      },
      {
        title: "Action",
        dataIndex: "action",
        render: (text, record) => {
          return (
            <Space size="middle">
              <img
                onClick={() => {
                  closeUpdateMriModal();
                  setSelected(record);
                }}
                src="/images/editing.svg"
                alt="Edit"
                className="actionimg"
              />
              <img
                onClick={() => {
                  removeMri(record?._id);
                }}
                className="actionimg"
                src="/images/dlt.svg"
                alt="delete"
              />
            </Space>
          );
        },
      },
    ],
    []
  );

  const removeMri = async (id) => {
    setLoading(true);
    try {
      await deleteMri(id);
      dispatch(getAllMri()).then(() => {
        setLoading(false);
        toast.success("Mri is deleted successfuly");
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
    getAllMri();
  };

  console.log("mriFacility", mriFacility);

  const ab = async () => {
    try {
      const mriPatientsRecord = await getMriPatients();
      setMriPatients(mriPatientsRecord);
    } catch (error) {
      toast.error(error.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    ab();
  }, []);
  console.log("mriPatients", mriPatients);
  // let allMriPatients = {
  //     mriFacility: null,
  //     pName: null,
  //     date: null,
  //     area: null
  //   };

  let allMriPatients = [];

  mriPatients?.data[0].cervical.map((itm, idx) => {
    allMriPatients.push(itm);
  });

  mriPatients?.data[0].lumberal.map((itm, idx) => {
    allMriPatients.push(itm);
  });

  mriPatients?.data[0].mri.map((itm, idx) => {
    allMriPatients.push(itm);
  });

  console.log("allMriPatients", allMriPatients);

  // debugger

  // Function to filter patients by date range
  // const filterPatientsByDate = (startDate, endDate) => {
  //   const filteredData = allMriPatients.filter((patient) => {
  //     const patientDate = new Date(patient.facility?.updatedAt);
  //     return patientDate >= startDate && patientDate <= endDate;
  //   });
  //   setFilteredPatients(filteredData);
  // };

  const filterPatientsByDate = (startDate, endDate) => {
    const filteredData = allMriPatients.filter((patient) => {
      const patientDate = new Date(patient.facility?.updatedAt);
      return patientDate >= startDate && patientDate <= endDate;
    });
    setFilteredPatients(filteredData);
  };

  useEffect(() => {
    if (dateRange && dateRange.length === 2) {
      filterPatientsByDate(
        dateRange[0].startOf("day"),
        dateRange[1].endOf("day")
      );
    } else {
      // Reset the filtered data if no date range is selected
      setFilteredPatients(allMriPatients);
    }
  }, [dateRange, allMriPatients]);

  useEffect(() => {
    setSelectedOption("all");
    displayAllRecords();
    // const thisWeekDates = getThisWeekDates();
    // filterPatientsByDate(thisWeekDates.startDate, thisWeekDates.endDate);
  }, [mriPatients]);

  // Function to handle radio button selection
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    switch (event.target.value) {
      case "week":
        const thisWeekDates = getThisWeekDates();
        filterPatientsByDate(thisWeekDates.startDate, thisWeekDates.endDate);
        break;
      case "month":
        const thisMonthDates = getThisMonthDates();
        filterPatientsByDate(thisMonthDates.startDate, thisMonthDates.endDate);
        break;
      case "year":
        const thisYearDates = getThisYearDates();
        filterPatientsByDate(thisYearDates.startDate, thisYearDates.endDate);
        break;
      case "all":
        displayAllRecords();
        break;
      default:
        setFilteredPatients([]);
        break;
    }
  };

  // Function to get the start and end dates for "This week"
  const getThisWeekDates = () => {
    const today = new Date();
    const firstDayOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay())
    );
    const lastDayOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay() + 6)
    );

    return { startDate: firstDayOfWeek, endDate: lastDayOfWeek };
  };

  // Function to get the start and end dates for "This month"
  const getThisMonthDates = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    return { startDate: firstDayOfMonth, endDate: lastDayOfMonth };
  };

  // Function to get the start and end dates for "This year"
  const getThisYearDates = () => {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const lastDayOfYear = new Date(today.getFullYear(), 11, 31);

    return { startDate: firstDayOfYear, endDate: lastDayOfYear };
  };

  const displayAllRecords = () => {
    setFilteredPatients(allMriPatients);
  };

  console.log("filteredPatients", filteredPatients);

  // PDF Print data function

  function printIt() {
    var win = window.open();
    win.document.write("<" + "html" + "><" + "body" + ">");
    win.document.write(`<div> 
    <div style="display: flex; justify-content:space-between;">
      <div style="width: 40%;">
        <h2 style="">
          MriFacility Record
        </h2>        
      </div>
    </div>
    `);

    filteredPatients?.map((item, idx) => {
      win.document.write(`
        <h3>${item?.facility?.mriFacility}</h3>
        <table style=" width:100%">
          <tr style="border-bottom:1px solid black;">
            <th style="text-align: start;">Date</th>
            <th style="text-align: start;">Patient</th>
            <th style="text-align: start;">Area</th>
          </tr>
          <tr key={${idx}} style="border-bottom:1px solid black;">
            <td style="text-align: start; border-bottom:1px solid black; width: 33%">${new Date(
              item?.facility?.updatedAt
            ).toLocaleDateString("en-US")}</td>
            <td style="text-align: start; border-bottom:1px solid black; width: 33%">${
              item?.firstName
            }  ${item?.lastName}</td>
            <td style="text-align: start; border-bottom:1px solid black; width: 33%">${
              item?.service
            }</td>
          </tr>`);
    });
    win.document.write(`</table></div>`);
    win.document.write("<" + "/body" + "><" + "/html" + ">");
    win.print();

    win.document.close();
    // win.close();
  }

  // print data pdf end

  return (
    <>
      <UtilityTop
        name="MRI Facility"
        placeholder="Search by MRI Facility Name"
        onClick={showMriFacility}
        BtnName="Add MRI Facility"
      />
      {showMRI && (
        <AddUtils
          onClick={closeMriModal}
          nameModal="Add MRI Facility"
          name="MRI Facility"
          placeholder="Enter MRI Facility"
          endpoint="create-mrifacility"
          label="mriFacility"
        />
      )}
      {showUpdateMRI && (
        <UpdateMri
          onClick={closeUpdateMriModal}
          nameModal="Update MRI Facility"
          data={seleted}
          setSelected={setSelected}
          name="MRI Facility"
          placeholder="Enter MRI Facility"
          endpoint="create-mrifacility"
          label="mriFacility"
        />
      )}
      <div className="printPlusIcon-wrapper-attorney">
        <div className="dateRange drange">
          <label>Choose the date</label>
          <RangePicker
            name="createdAt"
            className="rangePickerFilter"
            value={dateRange}
            onChange={(dates) => setDateRange(dates)}
            allowClear={true}
          />
        </div>
        <div className="filtersWrapper">
          <div className="filters">
            <div>
              <label>
                <input
                  type="radio"
                  value="all"
                  checked={selectedOption === "all"}
                  onChange={handleRadioChange}
                />
                <span>All</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="week"
                  checked={selectedOption === "week"}
                  onChange={handleRadioChange}
                />
                <span>This Week</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="month"
                  checked={selectedOption === "month"}
                  onChange={handleRadioChange}
                />
                <span>This Month</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="year"
                  checked={selectedOption === "year"}
                  onChange={handleRadioChange}
                />
                <span>This Year</span>
              </label>
            </div>
          </div>
          <div
            onClick={() => {
              printIt();
            }}
            className="printPlusIcon"
          >
            <img src="/images/printBlue.svg" alt="print" />
            <h5>Print MriFacility Data</h5>
          </div>
        </div>
      </div>
      <div className="AttorneyOfficeList">
        <Table dataSource={mriFacility} loading={loading} columns={columns} />
      </div>
    </>
  );
};

export default MriFacility;
