import { Select } from 'antd'

import React from 'react'

const AntdSelect = ({hanldeChange,options,selectValue,popUpClass,placeholder}) => {
    
  return (
    <div className="selectMainContainer"
    >
      <Select 
      onChange={hanldeChange}
      options={options}
      allowClear
      defaultValue={selectValue}
      popupClassName={popUpClass}
      placeholder={placeholder}


      
      />
    </div>
  )
}

export default AntdSelect