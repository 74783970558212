import { Space, Table } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSpecialist,
  newSpecialist,
  updateSpecialist,
} from "../../api/functions";
import Circle from "../../components/Circle/Circle";
import UtilityTop from "../../components/utility/UtilityTop";
import { getSpecialist } from "../../redux/records";

const SpecialistUtil = () => {
  const { specialist, specialities } = useSelector((state) => state.records);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ name: "", speciality: "" });
  const [mode, setMode] = useState("add");

  const dispatch = useDispatch();

  const cleanup = () => {
    dispatch(getSpecialist()).then(() => setLoading(false));
    setFormData({ name: "", speciality: "" });
    setMode("add");
  };

  const removeSpecialist = async (id) => {
    setLoading(true);
    try {
      await deleteSpecialist(id);
    } catch (error) {}
    cleanup();
  };

  const addSpecialist = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      mode === "add" && (await newSpecialist(formData));
      mode === "update" && (await updateSpecialist(formData));
    } catch (error) {}
    cleanup();
  };
  const columns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Speciality",
        render: (text, record) => record?.speciality?.name,
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => {
          return (
            <Space size="middle">
              <img
                onClick={() => {
                  setFormData({
                    _id: record?._id,
                    name: record?.name,
                    speciality: record?.speciality?._id,
                  });
                  setMode("update");
                }}
                src="/images/editing.svg"
                alt="Edit"
                className="actionimg"
              />
              <img
                onClick={() => removeSpecialist(record?._id)}
                className="actionimg"
                src="/images/dlt.svg"
                alt="delete"
              />
            </Space>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <UtilityTop name="Add specialist" show="none" showInput="none" />
      <div className="specialtUtlity">
        <div className="addInputMainSpecialist">
          <div className="addInputSpecilist">
            <form
              onChange={({ target: { name, value } }) =>
                setFormData({ ...formData, [name]: value })
              }
              onSubmit={addSpecialist}
            >
              <div>
                <h5>Specialist name</h5>

                <input
                  name="name"
                  type="text"
                  placeholder="Enter Specialist name"
                  value={formData.name}
                />
              </div>
              <div className="specialtySpacing">
                <h5>Specialty</h5>

                <select required name="speciality">
                  <option disabled selected hidden>
                    Select specialty
                  </option>
                  {specialities.map((speciality) => (
                    <option
                      selected={formData.speciality === speciality?._id}
                      value={speciality?._id}
                    >
                      {speciality?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="btnAddNsave">
                <button type="submit">
                  {loading ? (
                    <Circle height="0px" />
                  ) : mode === "add" ? (
                    "Add & Save"
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="inputValueAdded">
          <Table
            rowKey={(obj) => obj?._id}
            loading={loading}
            dataSource={specialist}
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};

export default SpecialistUtil;
