import React from "react";

const OrthoApptDetails = () => {
  return (
    <div className="showPatientsDetail">
      <div className="modalAppointPatientDetail2">
        <div>
          <h5>Next ortho appt</h5>
          <p>6/8/1969</p>
        </div>

        <div>
          <h5>Ortho PRN</h5>
          <p>-</p>
        </div>

        <div>{/* only For spacing.alignment of items  */}</div>
      </div>

      <div className="modalAppointPatientDetail2">
        <div>
          <h5>Ortho</h5>
          <p>6/8/1969</p>
        </div>

        <div>
          <h5>Surgery RX</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Surgery Performed</h5>
          <p>-</p>
        </div>
      </div>
      <div className="modalAppointPatientDetail2">
        <div>
          <h5>Ortho FU1</h5>
          <p>6/8/1969</p>
        </div>

        <div>
          <h5>Surgery Performed</h5>
          <p>6/8/1998</p>
        </div>

        <div>{/* only For spacing.alignment of items  */}</div>
      </div>
      <div className="modalAppointPatientDetail2">
        <div>
          <h5>Ortho FU2</h5>
          <p>6/8/1969</p>
        </div>

        <div>
          <h5>Second Surgery?</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Surgery Performed?</h5>
          <p>-</p>
        </div>
      </div>
      <div className="modalAppointPatientDetail2">
        <div>
          <h5>Ortho FU3</h5>
          <p>6/8/1969</p>
        </div>

        <div>
          <h5>Surgery Performed</h5>
          <p>6/8/1998</p>
        </div>

        <div>{/* only For spacing.alignment of items  */}</div>
      </div>
      <div className="modalAppointPatientDetail2">
        <div>
          <h5>Ortho FU4</h5>
          <p>6/8/1969</p>
        </div>

        <div>
          <h5>Third Surgery?</h5>
          <p>-</p>
        </div>

        <div>
          <h5>Surgery Performed?</h5>
          <p>-</p>
        </div>
      </div>
      <div className="modalAppointPatientDetail2">
        <div>
          <h5>Ortho FU5</h5>
          <p>6/8/1969</p>
        </div>

        <div>
          <h5>Surgery Performed</h5>
          <p>6/8/1998</p>
        </div>

        <div>{/* only For spacing.alignment of items  */}</div>
      </div>
      <div className="modalAppointPatientDetail2">
        <div>
          <h5>Ortho FU6</h5>
          <p>6/8/1969</p>
        </div>

        <div>
          <h5>First injection date</h5>
          <p>6/8/1998</p>
        </div>

        <div>{/* only For spacing.alignment of items  */}</div>
      </div>
      <div className="modalAppointPatientDetail2">
        <div>
          <h5>Ortho FU7</h5>
          <p>6/8/1969</p>
        </div>

        <div>
          <h5>Second injection date</h5>
          <p>6/8/1998</p>
        </div>

        <div>{/* only For spacing.alignment of items  */}</div>
      </div>
      <div className="modalAppointPatientDetail2">
        <div>
          <h5>Ortho FU8</h5>
          <p>6/8/1969</p>
        </div>

        <div>
          <h5>Third injection date</h5>
          <p>6/8/1998</p>
        </div>

        <div>{/* only For spacing.alignment of items  */}</div>
      </div>
      <div className="modalAppointPatientDetail2">
        <div>
          <h5>Ortho FU9</h5>
          <p>6/8/1969</p>
        </div>

        <div>
          <h5>Fourth injection date</h5>
          <p>6/8/1998</p>
        </div>

        <div>{/* only For spacing.alignment of items  */}</div>
      </div>
    </div>
  );
};

export default OrthoApptDetails;
