import { Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import LoadingSpinner from "../../components/Spinner/Spinner";
import { showModalActions } from "../../redux/showingModel";
import { recordAction } from "../../redux/records";
import AppointmentDetailModal from "./AppointmentDetailModal";

const AllAppointments = ({ appointments }) => {
  const showAppoint = useSelector((state) => state.modal.showAppointment);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { records } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [singelAppointment, setSingelAppointment] = useState();
  const showAppointHandler = useCallback(() => {
    dispatch(showModalActions.toggleAppointment());
  }, [dispatch]);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(appointments);
  }, [appointments]);

  const columns = useMemo(
    () => [
      {
        title: "D/E",
        dataIndex: "dateOfEntry",
        key: "dateOfEntry",
        render: (text, record) =>
          record?.patient_profile?.dateOfEntry
            ? moment(record?.patient_profile?.dateOfEntry).format("YYYY-MM-DD")
            : "--",
      },
      {
        title: "Patient Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) =>
          record?.patient_profile?.firstName ||
          record?.patient_profile?.lastName
            ? `${record?.patient_profile?.firstName} ${record?.patient_profile?.lastName}`
            : "--",
      },
      {
        title: "D/A",
        dataIndex: "dateOfAdmission",
        key: "dateOfAdmission",
        render: (text, record) =>
          record?.patient_profile?.dateOfAdmission
            ? moment(record?.patient_profile?.dateOfAdmission).format(
                "YYYY-MM-DD"
              )
            : "--",
      },
      {
        title: "Case Type",
        dataIndex: "caseType",
        key: "caseType",
        render: (text, record) =>
          record?.patient_profile?.caseType
            ? record?.patient_profile?.caseType
            : "--",
      },
      {
        title: "Appointment Type",
        dataIndex: "appointmentType",
        key: "appointmentType",
        render: (text, record) =>
          record?.patient_profile?.caseType
            ? record?.patient_profile?.caseType
            : "--",
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => (
          <div className="actionsRecent">
            <img
              src="/images/edit.svg"
              onClick={() => {
                dispatch(
                  recordAction.changeSelectedPatient(
                    records?.patients?.find(
                      (patient) => patient._id === record?.patientId
                    )
                  )
                );

                navigate("/appointment/createnewappointment/editpatient");
              }}
              // navigate()
            />
            <img
              onClick={() => {
                showAppointHandler();
                setSingelAppointment(record);
              }}
              src="/images/view.svg"
              alt="view"
            />
          </div>
        ),
      },
    ],
    [showAppointHandler]
  );

  document.body.style.overflow = showAppoint ? "hidden" : "";
  return (
    <div className="allAppointsMain">
      {!appointments.length ?? <LoadingSpinner height="50vh" />}
      <Table
        onChange={() => {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }}
        loading={loading}
        dataSource={data}
        columns={columns}
      />
      {showAppoint && <AppointmentDetailModal data={singelAppointment} />}
    </div>
  );
};

export default AllAppointments;
