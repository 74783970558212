import { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Redirect from "../../components/Redirect";
import Circle from "../../components/Circle/Circle";
import { loginAdmin } from "../../redux/slices";
import "./login.css";
const Login = () => {
  /* Login data state */
  const [data, setData] = useState({ email: "", password: "" });

  const { loading, error } = useSelector((state) => state.auth);

  /* Hooks */
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(loginAdmin({ email: data.email, password: data.password }))
      .unwrap()
      .then(() => {
        alert.success("Logged In");
        navigate("/");
      })
      .catch((error) => alert.error(error));
  };

  return (
    <div className="loginPage">
      <Redirect />
      {/* {isAuthenticated && <Navigate to={"/"} />} */}
      <div className="loginWelcome">
        <div className="loginFirstWelcome">
          <img src="/images/icon1.svg" alt="icon" />
          <h1>Welcome! Let’s get started where you left.</h1>
        </div>
        <div className="loginSecondWelcome">
          <img src="/images/icon2.svg" alt="icon" />
        </div>
      </div>
      <div className="loginForm">
        <div className="logLogin">
          <img src="/images/iconBlue.svg" alt="icon" />
        </div>
        <form
          onChange={({ target: { id, value } }) => {
            setData({ ...data, [id]: value });
          }}
          onSubmit={onSubmit}
          className="loginFormInputs"
        >
          <h2>Patient Tracker Login</h2>
          <div className="loginEmail">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" placeholder="Email" required />
          </div>
          <div className="loginPassword">
            <label htmlFor="password">Password</label>
            <input
              required
              type="password"
              id="password"
              placeholder="Password"
            />
          </div>
          {error && <span style={{ color: "red" }}>{error}</span>}
          <button>{loading ? <Circle height="0px" /> : "Log in"}</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
