import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showModal, showModalActions } from "../../redux/showingModel";
import EditPatientDetail from "./EditPatientDetail";
import "./patient.css";

import { Table } from "antd";
import moment from "moment";
import PatientDetailCard from "./PatientDetailCard";

const AllPateint = () => {
  const [selectedPAtient, setSelectedPAtient] = useState();

  const { patients } = useSelector((state) => state.records);
  const dispatch = useDispatch();
  const showmodal = useSelector((state) => state.modal.showModel);
  const showEditDetails = useSelector((state) => state.modal.editDetails);
  const [data, setData] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null); // Default selected option
  const [filteredPatients, setFilteredPatients] = useState([]);

  useEffect(() => {
    setData(patients);
    return () => {
      setData({});
    };
  }, [patients]);

  const showDetails = useCallback(
    (patient) => {
      dispatch(showModalActions.toggle());
      setSelectedPAtient(patient);
    },
    [dispatch]
  );

  const showEditingDetails = useCallback(
    (patient) => {
      setSelectedPAtient(patient);
      dispatch(showModalActions.toggleDetails());
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
      },
      {
        title: "DOB",
        dataIndex: "dateOfBirth",
        key: "dateOfBirth",
        render: (text, record) =>
          record?.dateOfBirth
            ? moment(record?.dateOfBirth).format("YYYY-MM-DD")
            : "--",
      },
      {
        title: "Case Type",
        dataIndex: "caseType",
        key: "caseType",
        render: (text, record) => (!record?.caseType ? "--" : record?.caseType),
      },
      {
        title: "Phone Number",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        render: (text, record) =>
          !record?.phoneNumber || record?.phoneNumber == null
            ? "--"
            : record?.phoneNumber,
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => (
          <div className="actionsRecent">
            <img
              src="/images/edit.svg"
              alt="edit"
              onClick={() => showEditingDetails(record)}
            />
            <img
              src="/images/view.svg"
              alt="view"
              onClick={() => showDetails(record)}
            />
          </div>
        ),
      },
    ],
    [showDetails, showEditingDetails]
  );

  document.body.style.overflow = showmodal || showEditDetails ? "hidden" : "";

  // Function to filter patients by date range
  const filterPatientsByDate = (startDate, endDate) => {
    const filteredData = data.filter((patient) => {
      const patientDate = new Date(patient.dateOfAdmission);
      return patientDate >= startDate && patientDate <= endDate;
    });
    setFilteredPatients(filteredData);
  };

  useEffect(() => {
    setSelectedOption("all");
    displayAllRecords();
    // const thisWeekDates = getThisWeekDates();
    // filterPatientsByDate(thisWeekDates.startDate, thisWeekDates.endDate);
  }, [data]);

  // Function to handle radio button selection
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    switch (event.target.value) {
      case "week":
        const thisWeekDates = getThisWeekDates();
        filterPatientsByDate(thisWeekDates.startDate, thisWeekDates.endDate);
        break;
      case "month":
        const thisMonthDates = getThisMonthDates();
        filterPatientsByDate(thisMonthDates.startDate, thisMonthDates.endDate);
        break;
      case "year":
        const thisYearDates = getThisYearDates();
        filterPatientsByDate(thisYearDates.startDate, thisYearDates.endDate);
        break;
      case "all":
        displayAllRecords();
        break;
      default:
        setFilteredPatients([]);
        break;
    }
  };

  // Function to get the start and end dates for "This week"
  const getThisWeekDates = () => {
    const today = new Date();
    const firstDayOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay())
    );
    const lastDayOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay() + 6)
    );

    return { startDate: firstDayOfWeek, endDate: lastDayOfWeek };
  };

  // Function to get the start and end dates for "This month"
  const getThisMonthDates = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    return { startDate: firstDayOfMonth, endDate: lastDayOfMonth };
  };

  // Function to get the start and end dates for "This year"
  const getThisYearDates = () => {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const lastDayOfYear = new Date(today.getFullYear(), 11, 31);

    return { startDate: firstDayOfYear, endDate: lastDayOfYear };
  };

  const displayAllRecords = () => {
    setFilteredPatients(data);
  };

  // console.log("filteredPatients:", filteredPatients);
  // console.log("data:", data);

  // PDF Print data function

  function printIt() {
    var win = window.open();

    win.document.write("<" + "html" + "><" + "body" + ">");
    win.document.write(`<div> 

    <div style="display: flex; justify-content:space-between; align-items: center;">
    <div style="width: 40%;">
        <h2 style="text-align: center;">
            Weekly Front Desk  Control Sheet
        </h2>
        
    </div>
    <div style="width: 40%;">
        <p style="display: inline;">Number of:5 x/w = 4 <div style="border-bottom: 1px solid black; display: inline-block; width: 50px;"></div>/20 = <div style="border-bottom: 1px solid black; width: 50px; display: inline-block;"></div>% Compilance</p>
        <p style="display: inline;">Number of:4 x/w = 1 <div style="border-bottom: 1px solid black; display: inline-block; width: 50px;"></div>/4 = <div style="border-bottom: 1px solid black; width: 50px; display: inline-block;"></div>% Compilance</p>
        <p style="display: inline;">Number of:3 x/w = 41 <div style="border-bottom: 1px solid black; display: inline-block; width: 50px;"></div>/123 = <div style="border-bottom: 1px solid black; width: 50px; display: inline-block;"></div>% Compilance</p>
        

    </div>


</div>
<table  style="border:1px solid black; width:100%">
    <tr style="border-bottom:1px solid black;">
        <th>Last Name</th>
        <th>First Name</th>
        <th>OV/W</th>
        <th>Phone</th>
        <th>D/A</th>
        <th>D/E</th>
        <th>Case type</th>
        <th>Attorney</th>
        <th>P/U</th>
        <th>Disabled</th>
        <th>M</th>
        <th>T</th>
        <th>W</th>
        <th>Th</th>
        <th>F</th>
        <th>S</th>
        <th>Note</th></tr>`);
    filteredPatients?.map((item, idx) => {
      console.log("item", item);
      win.document
        .write(` <tr key={${idx}} style="border-bottom:1px solid black;">
        <td style="text-align: center;">${
          item?.lastName ? item?.lastName : "--"
        }</td>
        <td style="text-align: center;">${
          item?.firstName ? item?.firstName : "--"
        }</td>
        <td style="text-align: center;">${
          item?.oVisits ? item?.oVisits : "--"
        }</td>
        <td style="text-align: center;">${
          item?.phoneNumber == undefined || item?.phoneNumber == null
            ? "--"
            : item?.phoneNumber
        }</td>

        <td style="text-align: center;">${
          item?.dateOfAdmission
            ? new Date(item?.dateOfAdmission).toLocaleDateString("en-US")
            : "--"
        }</td>
        <td style="text-align: center;">${
          item?.dateOfEntry
            ? new Date(item?.dateOfEntry).toLocaleDateString("en-US")
            : "--"
        }</td>
        <td style="text-align: center;">${
          item?.caseType ? item?.caseType : "--"
        }</td>
        <td style="text-align: center;">${
          item?.referredBy ? item?.referredBy : "--"
        }</td>
        <td style="display: flex; justify-content:center;align-items:center;">
          <div style="border:1px solid black; width: 20px; height:16px;"></div>
        </td>
        <td>
          <div
            style="border: 1px solid black;
             width: 20px; height:16px;
             margin: auto;
             
             "
          ></div>
        </td>
        <td>
          <div
            style="border: 1px solid black;
             width: 20px; height:16px;
             margin: auto;
             
             "
          ></div>
        </td>
        <td>
          <div
            style="border: 1px solid black;
             width: 20px; height:16px;
             margin: auto;
             
             "
          ></div>
        </td>
        <td>
          <div
            style="border: 1px solid black;
             width: 20px; height:16px;
             margin: auto;
             
             "
          ></div>
        </td>
        <td>
          <div
            style="border: 1px solid black;
             width: 20px; height:16px;
             margin: auto;
             
             "
          ></div>
        </td>
        <td>
          <div
            style="border: 1px solid black;
             width: 20px; height:16px;
             margin: auto;
             
             "
          ></div>
        </td>
        <td>
          <div
            style="border: 1px solid black;
             width: 20px; height:16px;
             margin: auto;
             
             "
          ></div>
        </td>
        <td>
          <div
            style="border: 1px solid black;
             width: 90px; height:16px;
             margin: auto;
             
             "
          ></div>
        </td>
      </tr>`);
    });

    win.document.write(`</table></div>`);
    win.document.write("<" + "/body" + "><" + "/html" + ">");
    win.print();

    win.document.close();
    // win.close();
  }

  // print data pdf end

  return (
    <>
      {/* <TopSearchBar /> */}
      <div className="AllPateintMain">
        <div className="addPAt">
          <h1>Patients</h1>
          <Link to={"/patient/addpatient"}>
            <button>Add Patient</button>
          </Link>
        </div>
        <div className="patientsUrl">
          <p>
            Patients /<span>All patient data</span>
          </p>

          <div className="filters">
            <div>
              <label>
                <input
                  type="radio"
                  value="all"
                  checked={selectedOption === "all"}
                  onChange={handleRadioChange}
                />
                <span>All</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="week"
                  checked={selectedOption === "week"}
                  onChange={handleRadioChange}
                />
                <span>This Week</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="month"
                  checked={selectedOption === "month"}
                  onChange={handleRadioChange}
                />
                <span>This Month</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="year"
                  checked={selectedOption === "year"}
                  onChange={handleRadioChange}
                />
                <span>This Year</span>
              </label>
            </div>
            <div
              onClick={() => {
                printIt();
              }}
              className="printPlusIcon"
            >
              <img src="/images/printBlue.svg" alt="print" />
              <h5>Tracker</h5>
            </div>
          </div>
        </div>
        <div className="patientSearch">
          <img src="/images/srch.svg" alt="search" />
          <input
            onChange={({ target: { value } }) => {
              setData(
                patients.filter((patient) =>
                  `${patient.firstName?.toLowerCase()} ${patient.lastName?.toLowerCase()}`.includes(
                    value.toLowerCase()
                  )
                )
              );
            }}
            type="text"
            placeholder="Search Data"
          />
        </div>
        {/* <div className="mstRcent">
          <button>Most Recent</button>
          <img alt="arrow" src="/images/arrowForward.svg" about="arrow" />
        </div> */}
        {showmodal && <PatientDetailCard patient={selectedPAtient} />}
        {showEditDetails && <EditPatientDetail patient={selectedPAtient} />}

        <div className="recentPatients">
          <Table
            rowKey={(obj) => obj._id}
            dataSource={filteredPatients}
            columns={columns}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AllPateint;
