import { createSlice } from "@reduxjs/toolkit";

export const showModal = createSlice({
  name: "Modal",
  initialState: {
    showModel: false,
    editDetails: false,
    showAppointment: false,
  },
  reducers: {
    toggle(state) {
      state.showModel = !state.showModel;
    },
    toggleDetails(state) {
      state.editDetails = !state.editDetails;
    },
    toggleAppointment(state) {
      state.showAppointment = !state.showAppointment;
    },
  },
});

export const showModalActions = showModal.actions;
