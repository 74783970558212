import { Space, Table } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSpeciality,
  newSpeciality,
  updateSpeciality,
} from "../../api/functions";
import Circle from "../../components/Circle/Circle";
import UtilityTop from "../../components/utility/UtilityTop";
import { getSpeciality } from "../../redux/records";

const Specialty = () => {
  const { specialities } = useSelector((state) => state.records);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ name: "" });
  const [mode, setMode] = useState("add");

  const dispatch = useDispatch();

  const cleanup = () => {
    dispatch(getSpeciality()).then(() => setLoading(false));
    setFormData({ name: "" });
    setMode("add");
  };

  const removeSpecialist = async (id) => {
    setLoading(true);
    try {
      await deleteSpeciality(id);
    } catch (error) {}
    cleanup();
  };

  const addSpecialist = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      mode === "add" && (await newSpeciality(formData));
      mode === "update" && (await updateSpeciality(formData, formData?._id));
    } catch (error) {}
    cleanup();
  };
  const columns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },

      {
        title: "Action",
        key: "action",
        render: (text, record) => {
          return (
            <Space size="middle">
              <img
                onClick={() => {
                  setFormData({
                    _id: record?._id,
                    name: record?.name,
                  });
                  setMode("update");
                }}
                src="/images/editing.svg"
                alt="Edit"
                className="actionimg"
              />
              <img
                onClick={() => removeSpecialist(record?._id)}
                className="actionimg"
                src="/images/dlt.svg"
                alt="delete"
              />
            </Space>
          );
        },
      },
    ],
    []
  );

  console.log("specialities", specialities);

  // PDF Print data function

  function printIt() {
    var win = window.open();

    win.document.write("<" + "html" + "><" + "body" + ">");
    win.document.write(`<div> 
  
      <div style="display: flex; justify-content:space-between;">
      <div style="width: 40%;">
          <h2 style="">
          MriFacility Record
          </h2>
      </div>
  </div>
  <table  style=" width:100%">
      <tr style="border-bottom:1px solid black;">
          <th style="text-align: start;">Name</th>
          </tr>`);
    specialities?.map((item, idx) => {
      win.document
        .write(` <tr key={${idx}} style="border-bottom:1px solid black;">
          <td style="text-align: start; border-bottom:1px solid black;">${item?.name}</td>
        </tr>`);
    });

    win.document.write(`</table></div>`);
    win.document.write("<" + "/body" + "><" + "/html" + ">");
    win.print();

    win.document.close();
    // win.close();
  }

  // print data pdf end

  return (
    <>
      <UtilityTop name="Add Specialty" show="none" showInput="none" />
      <div className="specialtUtlity">
        <div className="addInputMainSpecialist">
          <div className="addInputSpecilist">
            <form
              onChange={({ target: { name, value } }) =>
                setFormData({ ...formData, [name]: value })
              }
              onSubmit={addSpecialist}
            >
              <div className="addInputMain">
                <h5>Specialty name</h5>
                <div className="addInputSpecilaty">
                  <button type="submit">
                    {loading ? (
                      <Circle height="0px" />
                    ) : mode === "add" ? (
                      "Add & Save"
                    ) : (
                      "Update"
                    )}
                  </button>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    placeholder="Enter specialty name"
                  />
                </div>
              </div>
              {/* <div>
                <h5>Specialty name</h5>

                <input
                  name="name"
                  type="text"
                  placeholder="Enter Specialty name"
                  value={formData.name}
                />
              </div>
             
              <div className="btnAddNsave">
                <button type="submit">
                  {loading ? (
                    <Circle height="0px" />
                  ) : mode === "add" ? (
                    "Add & Save"
                  ) : (
                    "Update"
                  )}
                </button>
              </div> */}
            </form>
          </div>
        </div>
        <div className="printPlusIcon-wrapper">
          <div
            onClick={() => {
              printIt();
            }}
            className="printPlusIcon"
          >
            <img src="/images/printBlue.svg" alt="print" />
            <h5>Print Sepecialities Data</h5>
          </div>
        </div>
        <div className="inputValueAdded">
          <Table
            rowKey={(obj) => obj?._id}
            loading={loading}
            dataSource={specialities}
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};

export default Specialty;
