import moment, { isMoment } from "moment";
import React, { useEffect, useState } from "react";
// import DateRangePicker from "react-bootstrap-daterangepicker";
import { NavLink } from "react-router-dom";
import { Button, DatePicker } from "antd";
import { useAlert } from "react-alert";
import PatientDropDown from "../../components/PatientDropDown/PatientDropDown";
import { getSpecialistsForAppointments } from "../../api/functions";

// import FilterAppointment from "./FilterAppointment";
// import { CSVLink } from "react-csv";
const { RangePicker } = DatePicker;
const resetBtn = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100px",
  height: "60px",
  paddingTop: "27px",
};
const AppointmentTop = ({
  data,
  setData,
  specialist,
  appointments,
  selectedOption,
  handleRadioChange,
  filteredAppointments,
}) => {
  const [filters, setFilters] = useState({
    caseType: null,
    type: null,
    dateOfAppointment: [],
    createdAt: [],
    selectedPatient: null,
  });
  const [print, setPrint] = useState([]);
  // console.log("appointments", appointments);
  const toast = useAlert();
  console.log("specialist", specialist);

  const dateRange = (e, type) => {
    setFilters({ ...filters, [type]: e });
    if (isMoment(e?.[0]) && isMoment(e?.[1])) {
      let start = moment(e[0]).format("YYYY-MM-DD");
      let end = moment(e[1]).format("YYYY-MM-DD");
      setData(
        appointments?.filter((res) => {
          let sd = new Date(start).getTime();
          let ed = new Date(end).getTime();
          let dataD = new Date(res.patient_profile?.[type]).getTime();
          if (start === end) return dataD === sd;
          else return dataD >= sd && dataD <= ed;
        })
      );
    } else {
      setData(appointments);
    }
  };
  const formate = (data) => {
    let arr = data?.map((item) => {
      let value = {};
      value = {
        patientId: item?.patientId?.patientId,
        firstName: item?.patientId?.firstName,
        lastName: item?.patientId?.lastName,
        phoneNumber: item?.patientId?.phoneNumber,
        internal:
          item?.mdAppointment?.initial.length > 0
            ? new Date(
                item?.mdAppointment?.initial[
                  item?.mdAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[
                item?.mdAppointment?.initial?.length - 1
              ]?.status
            : "",
        OMRI1:
          item?.mriAppointment?.initial.length > 0
            ? new Date(
                item?.mriAppointment?.initial[0]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[0]?.status
            : "",
        OMRI2:
          item?.mriAppointment?.initial.length > 1
            ? new Date(
                item?.mriAppointment?.initial[1]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[1]?.status
            : "",
        OMRI3:
          item?.mriAppointment?.initial.length > 2
            ? new Date(
                item?.mriAppointment?.initial[2]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[2]?.status
            : "",
        Ortho:
          item?.orthoAppointment?.initial.length > 0
            ? new Date(
                item?.orthoAppointment?.initial[
                  item?.orthoAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.orthoAppointment?.initial[
                item?.orthoAppointment?.initial?.length - 1
              ]?.status
            : "",
        neuroN:
          item?.nueroAppointment?.initial.length > 0
            ? new Date(
                item?.nueroAppointment?.initial[
                  item?.nueroAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.nueroAppointment?.initial[
                item?.nueroAppointment?.initial?.length - 1
              ]?.status
            : "",
        UEMG:
          item?.UMEG?.initial.length > 0
            ? new Date(
                item?.UMEG?.initial[item?.UMEG?.initial?.length - 1]?.date
              ).toLocaleDateString() +
              " " +
              item?.UMEG?.initial[item?.UMEG?.initial?.length - 1]?.status
            : "",
        LMEG:
          item?.LMEG?.initial.length > 0
            ? new Date(
                item?.LMEG?.initial[item?.LMEG?.initial?.length - 1]?.date
              ).toLocaleDateString() +
              " " +
              item?.LMEG?.initial[item?.LMEG?.initial?.length - 1]?.status
            : "",
        pain:
          item?.painAppointment?.initial.length > 0
            ? new Date(
                item?.painAppointment?.initial[
                  item?.painAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.painAppointment?.initial[
                item?.painAppointment?.initial?.length - 1
              ]?.status
            : "",
        spinal:
          item?.spinalAppointment?.initial.length > 0
            ? new Date(
                item?.spinalAppointment?.initial[
                  item?.spinalAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.spinalAppointment?.initial[
                item?.spinalAppointment?.initial?.length - 1
              ]?.status
            : "",
        np:
          item?.nursePractitioner?.initial.length > 0
            ? new Date(
                item?.nursePractitioner?.initial[
                  item?.nursePractitioner?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.nursePractitioner?.initial[
                item?.nursePractitioner?.initial?.length - 1
              ]?.status
            : "",
        cervical: item?.cervical?.date
          ? new Date(item?.cervical?.date).toLocaleDateString() +
            " " +
            item?.cervical?.status
          : "",
        lumberl: item?.lumberal?.date
          ? new Date(item?.lumberal?.date).toLocaleDateString() +
            " " +
            item?.lumberal?.status
          : "",
      };

      return value;
    });
    return arr;
  };
  useEffect(() => {
    setPrint(formate(data));
  }, [data]);

  // console.log("apointmentdata", data);
  // console.log("filteredAppointments", filteredAppointments);

  // PDF Print data function

  function printIt() {
    var win = window.open();

    win.document.write("<" + "html" + "><" + "body" + ">");
    win.document.write(`<div> 
  
      <div style="display: flex; justify-content:space-between; align-items: center;">
      <div style="width: 40%;">
          <h2 style="text-align: center;">
              Weekly Front Desk  Control Sheet
          </h2>
          
      </div>
      <div style="width: 40%;">
          <p style="display: inline;">Number of:5 x/w = 4 <div style="border-bottom: 1px solid black; display: inline-block; width: 50px;"></div>/20 = <div style="border-bottom: 1px solid black; width: 50px; display: inline-block;"></div>% Compilance</p>
          <p style="display: inline;">Number of:4 x/w = 1 <div style="border-bottom: 1px solid black; display: inline-block; width: 50px;"></div>/4 = <div style="border-bottom: 1px solid black; width: 50px; display: inline-block;"></div>% Compilance</p>
          <p style="display: inline;">Number of:3 x/w = 41 <div style="border-bottom: 1px solid black; display: inline-block; width: 50px;"></div>/123 = <div style="border-bottom: 1px solid black; width: 50px; display: inline-block;"></div>% Compilance</p>
      </div>
  </div>
  <table  style="border:1px solid black; width:100%">
      <tr style="border-bottom:1px solid black;">
          <th>Patient</th>
          <th>Case Type</th>
          <th>Phone</th>
          <th>D/A</th>
          <th>D/E</th>
          </tr>`);
    filteredAppointments?.map((item, idx) => {
      console.log("item", item);
      // debugger;
      win.document
        .write(` <tr key={${idx}} style="border-bottom:1px solid black;">
          <td style="text-align: center;">${
            !item?.patient_profile.lastName
              ? "--"
              : item?.patient_profile.lastName
          } ${
        !item?.patient_profile?.firstName
          ? "--"
          : item?.patient_profile?.firstName
      }</td>
          <td style="text-align: center;">${
            !item?.patient_profile?.caseType
              ? "--"
              : item?.patient_profile?.caseType
          }</td>
          <td style="text-align: center;">${
            !item?.patient_profile?.phoneNumber
              ? "--"
              : item?.patient_profile?.phoneNumber
          }</td>
          
          <td style="text-align: center;">${
            !item?.patient_profile?.dateOfAdmission
              ? "--"
              : new Date(
                  item?.patient_profile?.dateOfAdmission
                ).toLocaleDateString("en-US")
          }</td>
          <td style="text-align: center;">${
            item?.patient_profile?.dateOfEntry
              ? "--"
              : new Date(item?.patient_profile?.dateOfEntry).toLocaleDateString(
                  "en-US"
                )
          }</td>
          
        </tr>`);
    });

    // console.log("data", data)

    win.document.write(`</table></div>`);
    win.document.write("<" + "/body" + "><" + "/html" + ">");
    win.print();

    win.document.close();
    // win.close();
  }

  const specialistById = async (id) => {
    try {
      const response = await getSpecialistsForAppointments(id);
      const specialistData = response.data;
      // Process the specialist data or update the state with the retrieved details
      console.log(specialistData);
      setData(specialistData?.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  return (
    <>
      {/* <TopSearchBar /> */}
      <div className="appointHeading">
        <div className="headingApp">
          <h1>Appointments</h1>
          <NavLink to={"/appointment/createnewappointment/editpatient"}>
            <button>Create New Appointment</button>
          </NavLink>
        </div>
        <div className="appointUrl">
          <p>
            Appointment /<span>All Appointment</span>
          </p>
          <div className="filters">
            <div>
              <label>
                <input
                  type="radio"
                  value="all"
                  checked={selectedOption === "all"}
                  onChange={handleRadioChange}
                />
                <span>All</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="week"
                  checked={selectedOption === "week"}
                  onChange={handleRadioChange}
                />
                <span>This Week</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="month"
                  checked={selectedOption === "month"}
                  onChange={handleRadioChange}
                />
                <span>This Month</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="year"
                  checked={selectedOption === "year"}
                  onChange={handleRadioChange}
                />
                <span>This Year</span>
              </label>
            </div>
          </div>
        </div>

        <div className="appointSpecialty">
          <div className="patientSelect">
            <label>Select appointment specialty</label>
            <select
              onChange={(e) => {
                const a = e?.target?.value;
                if (a == "all") {
                  setData(appointments);
                } else {
                  setData(appointments?.filter((res) => res?.type == a));
                }
              }}
            >
              <option value={"all"}>All Appointments</option>
              <option value={"mdAppointment"}>MD Appointments</option>
              <option value={"mriAppointment"}>MRI Appointments</option>
              <option value={"OrthoAppointment"}>Ortho Appointments</option>
              <option value={"NurseAppointment"}>Nurse Practitioner</option>
              <option value={"NeuroAppointment"}>Neuro Appointments</option>
              <option value={"PainAppointment"}>Pain Managment </option>
              <option value={"SpinalAppointment"}>Spinal Surgeon</option>
              <option value={"PhsicalAppointment"}>Physical Therapy</option>
              <option value={"ChiropracticAppointment"}>Chiropractic</option>
              <option value={"OutsideAppointment"}>Outside Referral</option>
            </select>
          </div>
          <PatientDropDown
            selected={"Select a patient"}
            onChange={(id) =>
              setData(
                appointments?.filter((res) => res?.patient_profile?._id == id)
              )
            }
          />
          {/* <CSVLink
                headers={[
                  { label: "Patient ID", key: "patientId" },
                  { label: "First Name", key: "firstName" },
                  { label: "Last Name", key: "lastName" },
                  { label: "Phone Number", key: "phoneNumber" },
                  { label: "Internal", key: "internal" },
                  { label: "Cervical", key: "cervical" },
                  { label: "Lumberl", key: "lumberl" },
                  { label: "O MRI 1", key: "OMRI1" },
                  { label: "O MRI 2", key: "OMRI2" },
                  { label: "O MRI 3", key: "OMRI3" },
                  { label: "Ortho", key: "ortho" },
                  { label: "NP", key: "np" },
                  { label: "Neuro N", key: "neuroN" },
                  { label: "UEMG", key: "UEMG" },
                  { label: "LMEG", key: "LMEG" },
                  { label: "Pain", key: "pain" },
                  { label: "Spinal", key: "spinal" },
                ]}
                filename={"export.csv"}
                data={print}
              >
                <div className="iconPrint">
                  <img src="/images/printBlue.svg" alt="print" />
                  <p>Print Appointments</p>
                </div>
              </CSVLink> */}

          <div
            onClick={() => {
              printIt();
            }}
            className="printPlusIcon"
          >
            <img src="/images/printBlue.svg" alt="print" />
            <h5>Print Appointments</h5>
          </div>
        </div>

        <hr />
        <div className="filterAppointment">
          <form className="filtersForm">
            <div className="filterInputs">
              <div className="dateRange">
                <label>Choose the date</label>
                <RangePicker
                  name="createdAt"
                  className="rangePickerFilter"
                  value={filters.createdAt}
                  onChange={(date) => dateRange(date, "createdAt")}
                  allowClear={true}
                />
              </div>
              <div className="dateRange">
                <label>Specialist</label>
                <select
                  className="specialist-select"
                  required
                  name="specialist"
                  onChange={(e) => specialistById(e.target.value)}
                >
                  <option disabled selected hidden>
                    Select specialist
                  </option>
                  {specialist.map((specialist) => (
                    <option key={specialist._id} value={specialist?._id}>
                      {specialist?.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="dateRange">
                <label>Date Of Admission</label>
                <RangePicker
                  name="dateOfAdmission"
                  value={filters.dateOfAdmission}
                  onChange={(date) => dateRange(date, "dateOfAdmission")}
                  allowClear={true}
                  className="rangePickerFilter"
                />
              </div> */}
              <div className="resetAll" style={resetBtn}>
                <img src="/images/resetIcon.svg" alt="Reset" />
                <button>Reset all</button>
              </div>
            </div>
          </form>

          {/* <div className="filtersBtns">
            <div className="centerBtnFilter">
              <button className="activeBtn btnAll">All</button>
              <button className="btnUpcoming">Upcoming</button>
              <button className="btnCompleted">Completed</button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AppointmentTop;
