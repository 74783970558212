import React from 'react'

const NeuroApptDetail = () => {
  return (
    <div className="showPatientsDetail">
   
    <div className="modalAppointPatientDetail2">
      <div>
        <h5>Next neuro</h5>
        <p>9/6/1969</p>
      </div>

      <div>
        <h5>Neuro PRN</h5>
        <p>-</p>
      </div>

      <div>
        {/* FOr spacing no data will be entered here. */}
      </div>

      
      
    </div>
    <div className="modalAppointPatientDetail2">
      <div>
        <h5>Neuro</h5>
        <p>9/6/1969</p>
      </div>

      <div>
        <h5>UMEG</h5>
        <p>9/6/1968</p>
      </div>

      <div>
      <h5>Scan Done</h5>
        <p>-</p>
      </div>

      
      
    </div>
    <div className="modalAppointPatientDetail2">
      <div>
        <h5>Neuro FU1</h5>
        <p>9/6/1969</p>
      </div>

      <div>
        <h5>LEMG</h5>
        <p>9/6/1968</p>
      </div>

      <div>
      <h5>Scan Done</h5>
        <p>-</p>
      </div>

      
      
    </div>
    <div className="modalAppointPatientDetail2">
      <div>
        <h5>Neuro FU2</h5>
        <p>9/6/1969</p>
      </div>

     
      
      
    </div>
    <div className="modalAppointPatientDetail2">
      <div>
        <h5>Neuro FU3</h5>
        <p>9/6/1969</p>
      </div>

     
      
      
    </div>
    <div className="modalAppointPatientDetail2">
      <div>
        <h5>Neuro FU4</h5>
        <p>9/6/1969</p>
      </div>

     
      
      
    </div>
  </div>
  )
}

export default NeuroApptDetail