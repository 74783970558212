import { Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAllReports } from "../../api/functions";
import ReportTop from "../../components/reports/ReportTop";

const FlagAnalysis = () => {
  const { patients } = useSelector((state) => state.records);
  const [data, setData] = useState([]);

  const formate = (data) => {
    let arr = data?.map((item) => {
      let value = {};
      value = {
        patientId: item?.patientId?.patientId,
        specialist: item?.patientId?.specialist,
        firstName: item?.patientId?.firstName,
        lastName: item?.patientId?.lastName,
        phoneNumber: item?.patientId?.phoneNumber,
        internal:
          item?.mdAppointment?.initial.length > 0
            ? new Date(
                item?.mdAppointment?.initial[
                  item?.mdAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[
                item?.mdAppointment?.initial?.length - 1
              ]?.status
            : "",
        OMRI1:
          item?.mriAppointment?.initial.length > 0
            ? new Date(
                item?.mriAppointment?.initial[0]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[0]?.status
            : "",
        OMRI2:
          item?.mriAppointment?.initial.length > 1
            ? new Date(
                item?.mriAppointment?.initial[1]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[1]?.status
            : "",
        OMRI3:
          item?.mriAppointment?.initial.length > 2
            ? new Date(
                item?.mriAppointment?.initial[2]?.date
              ).toLocaleDateString() +
              " " +
              item?.mdAppointment?.initial[2]?.status
            : "",
        Ortho:
          item?.orthoAppointment?.initial.length > 0
            ? new Date(
                item?.orthoAppointment?.initial[
                  item?.orthoAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.orthoAppointment?.initial[
                item?.orthoAppointment?.initial?.length - 1
              ]?.status
            : "",
        neuroN:
          item?.nueroAppointment?.initial.length > 0
            ? new Date(
                item?.nueroAppointment?.initial[
                  item?.nueroAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.nueroAppointment?.initial[
                item?.nueroAppointment?.initial?.length - 1
              ]?.status
            : "",
        UEMG:
          item?.UMEG?.initial?.length > 0
            ? new Date(
                item?.UMEG?.initial[item?.UMEG?.initial?.length - 1]?.date
              ).toLocaleDateString() +
              " " +
              item?.UMEG?.initial[item?.UMEG?.initial?.length - 1]?.status
            : "",
        LMEG:
          item?.LMEG?.initial?.length > 0
            ? new Date(
                item?.LMEG?.initial[item?.LMEG?.initial?.length - 1]?.date
              ).toLocaleDateString() +
              " " +
              item?.LMEG?.initial[item?.LMEG?.initial?.length - 1]?.status
            : "",
        pain:
          item?.painAppointment?.initial.length > 0
            ? new Date(
                item?.painAppointment?.initial[
                  item?.painAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.painAppointment?.initial[
                item?.painAppointment?.initial?.length - 1
              ]?.status
            : "",
        spinal:
          item?.spinalAppointment?.initial.length > 0
            ? new Date(
                item?.spinalAppointment?.initial[
                  item?.spinalAppointment?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.spinalAppointment?.initial[
                item?.spinalAppointment?.initial?.length - 1
              ]?.status
            : "",
        np:
          item?.nursePractitioner?.initial.length > 0
            ? new Date(
                item?.nursePractitioner?.initial[
                  item?.nursePractitioner?.initial?.length - 1
                ]?.date
              ).toLocaleDateString() +
              " " +
              item?.nursePractitioner?.initial[
                item?.nursePractitioner?.initial?.length - 1
              ]?.status
            : "",
        cervical: item?.cervical?.date
          ? new Date(item?.cervical?.date).toLocaleDateString() +
            " " +
            item?.cervical?.status
          : "",
        lumberl: item?.lumberal?.date
          ? new Date(item?.lumberal?.date).toLocaleDateString() +
            " " +
            item?.lumberal?.status
          : "",
      };

      return value;
    });
    return arr;
  };

  // const AppointmentStatus = (data) => {
  //   let arr = data?.map((item) => {
  //     let value = {};
  //     value = {
  //       status:
  //         item?.mdAppointment?.initial[item?.mdAppointment?.initial?.length - 1]
  //           ?.status,
  //     };
  //     return value;
  //   });
  //   return arr;
  // };

  const getAppointments = async () => {
    const upcoming = await getAllReports("upcoming");

    console.log("prevdata", upcoming);

    setData(formate(upcoming?.data?.data));
  };

  useEffect(() => {
    getAppointments();
  }, []);

  const columns = useMemo(() => [
    { title: "Patient ID", key: "patientId", dataIndex: "patientId" },
    { title: "First Name", key: "firstName", dataIndex: "firstName" },
    { title: "Last Name", key: "lastName", dataIndex: "lastName" },
    { title: "Phone Number", key: "phoneNumber", dataIndex: "phoneNumber" },
    {
      title: "Internal",
      key: "internal",
      dataIndex: "internal",
      render: (value) => {
        let status = value.includes("missed");
        return <p className={status ? "redText" : ""}>{value}</p>;
      },
    },
    { title: "Cervical", key: "cervical", dataIndex: "cervical" },
    { title: "Lumberl", key: "lumberl", dataIndex: "lumberl" },
    { title: "O MRI 1", key: "OMRI1", dataIndex: "OMRI1" },
    { title: "O MRI 2", key: "OMRI2", dataIndex: "OMRI2" },
    { title: "O MRI 3", key: "OMRI3", dataIndex: "OMRI3" },
    { title: "Ortho", key: "ortho", dataIndex: "ortho" },
    { title: "NP", key: "np", dataIndex: "np" },
    { title: "Neuro N", key: "neuroN", dataIndex: "neuroN" },
    { title: "UEMG", key: "UEMG", dataIndex: "UEMG" },
    { title: "LMEG", key: "LMEG", dataIndex: "LMEG" },
    { title: "Pain", key: "pain", dataIndex: "pain" },
    { title: "Spinal", key: "spinal", dataIndex: "spinal" },
  ]);

  return (
    <>
      <ReportTop
        headers={[
          { label: "Patient ID", key: "patientId" },
          { label: "First Name", key: "firstName" },
          { label: "Last Name", key: "lastName" },

          { label: "Phone Number", key: "phoneNumber" },
          { label: "Internal", key: "internal" },
          { label: "Cervical", key: "cervical" },
          { label: "Lumberl", key: "lumberl" },
          { label: "O MRI 1", key: "OMRI1" },
          { label: "O MRI 2", key: "OMRI2" },
          { label: "O MRI 3", key: "OMRI3" },
          { label: "Ortho", key: "ortho" },
          { label: "NP", key: "np" },
          { label: "Neuro N", key: "neuroN" },
          { label: "UEMG", key: "UEMG" },
          { label: "LMEG", key: "LMEG" },
          { label: "Pain", key: "pain" },
          { label: "Spinal", key: "spinal" },
        ]}
        data={data}
        setData={setData}
        name="Flag analysis"
      />
      <div className="flagAnalysisMain">
        <Table
          rowKey={(obj) => obj._id}
          dataSource={data}
          columns={columns}
          pagination={{
            defaultPageSize: 8,
            showSizeChanger: true,
          }}
        />
      </div>
    </>
  );
};

export default FlagAnalysis;
