import ApptForm1 from "./components/ApptType/ApptForm1";
import Appointment from "./pages/appointments/Appointment";
import EditPatient from "./pages/appointments/appointTypes/EditPatient";
import CreateNewAppoinment from "./pages/appointments/CreateNewAppoinment";
import DashBoardPage from "./pages/dashboardPage/DashBoardPage";
import SideBar from "./pages/dashboardPage/SideBar";
import AddPatient from "./pages/patient/AddPatient";
import AllPatient from "./pages/patient/AllPateint";
import { ConfigProvider } from "antd";
import "./pages/reports/report.css";

import ApptForm2 from "./components/ApptType/ApptForm2";
import MakeNeuroAppt from "./pages/appointments/appointTypes/MakeNeuroAppt";

import MakeMdAppt from "./pages/appointments/appointTypes/MakeMdAppt";

import Spinner from "./components/Spinner/Spinner";
import MakeMRIappt from "./pages/appointments/appointTypes/MakeMRIappt";
import Login from "./pages/login/login";
import FlagAnalysis from "./pages/reports/FlagAnalysis";
import MriFacility from "./pages/utility/MriFacility";
import SpecialistUtil from "./pages/utility/SpecialistUtil";


import "react-datepicker/dist/react-datepicker.css";
import "./pages/dashboardPage/dashboard.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import ReportMRI from "./pages/reports/ReportMRI";
import AttorneyOffice from "./pages/utility/AttorneyOffice";
import {
  getAllAppointments,
  getAllAttornies,
  getAllMri,
  getPatients,
  getSpecialist,
  getSpeciality,
} from "./redux/records";
import { authSlice, loadProfile } from "./redux/slices";
import { useAlert } from "react-alert";
import MakePainAppt from "./pages/appointments/appointTypes/painAppt";
import NurseAppt from "./pages/appointments/appointTypes/NurseAppt";
import SpinalAppt from "./pages/appointments/appointTypes/spainelAppt";
import Spacility from "./pages/utility/Specility";
import Specialty from "./pages/utility/Specility";
import PatientList from "./pages/utility/PatientList";
function App() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const dispatch = useDispatch();
  const toast = useAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getSpecialist());
      dispatch(getPatients());
      dispatch(getAllAppointments());
      dispatch(getAllMri());
      dispatch(getSpeciality());
      dispatch(getAllAttornies()).then(() => setLoading(false));
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(loadProfile(token))
        .unwrap()
        .then()
        .catch((e) => {
          localStorage.removeItem("token");
          dispatch(authSlice.actions.logout())
          toast("Please Login Again");
          navigate("/login");
        });
    } else setLoading(false);
  }, [dispatch, token]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
          </Routes>
          <div className="MainPage">
            {/* <Login /> */}
            {location.pathname === "/login" ? null : <SideBar />}
            <div className="RoutesMain">
              <Routes>
                <Route path="/" element={<DashBoardPage />} />
                <Route path="/patient/allpatient" element={<AllPatient />} />
                <Route path="/patient/addpatient" element={<AddPatient />} />
                <Route path="/appointment">
                  <Route index element={<Appointment />} />
                  <Route
                    path="createnewappointment"
                    element={<CreateNewAppoinment />}
                  >
                    {/* <Route path="createnewappointment" element={<CreateNewAppoinment />}/> */}

                    <Route path="editpatient" element={<EditPatient />} />
                    <Route path="outsidereferral" element={<ApptForm1 />} />
                    <Route path="chiropractic" element={<ApptForm2 />} />
                    <Route
                      path="neuroappointment"
                      element={<MakeNeuroAppt />}
                    />
                    <Route path="specialist" element={<ApptForm1 />} />
                    <Route path="mdappointment" element={<MakeMdAppt />} />
                    <Route path="physicaltherapy" element={<ApptForm2 />} />
                    <Route path="nursepractionar" element={<NurseAppt />} />
                    <Route path="orthoappointment" element={<ApptForm1 />} />
                    <Route path="painmangement" element={<MakePainAppt />} />
                    <Route path="spinalsurgeon" element={<SpinalAppt />} />
                    <Route path="mriappointment" element={<MakeMRIappt />} />
                  </Route>
                </Route>
                <Route path="/report/mri" element={<ReportMRI />} />
                <Route path="/report/flaganalysis" element={<FlagAnalysis />} />
                <Route path="/utility">
                  <Route path="attorneyoffice" element={<AttorneyOffice />} />
                  {/* <Route path="specilaty" element={<Specialty />} /> */}
                  <Route path="mrifacility" element={<MriFacility />} />
                  <Route path="specilist" element={<SpecialistUtil />} />
                  <Route path="Specialty" element={<Specialty/>}/>
                  <Route path="patientlist" element={<PatientList/>}/>
                </Route>
              </Routes>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
