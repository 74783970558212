import { Space, Table, DatePicker } from "antd";
import moment, { isMoment } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { deleteAttorney } from "../../api/functions";
import AddAttorney from "../../components/utility/AddUtils";
import UpdateAttorney from "../../components/utility/UpdateAttorney";
import UtilityTop from "../../components/utility/UtilityTop";
import { getAllAttornies } from "../../redux/records";
import { useAlert } from "react-alert";
import { getMriAttorny } from "../../api/functions";
import "./utility.css";

const AttorneyOffice = () => {
  const { RangePicker } = DatePicker;
  const { attornies } = useSelector((state) => state.records);
  // const filteredAtt = attornies?.map()
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showUpdateAtt, setShowUpdateAtt] = useState(false);
  const [seleted, setSelected] = useState("");
  const [render, setRender] = useState(false);
  const [mriAttorny, setMriAttorny] = useState(null);

  // For data filteration
  const [selectedOption, setSelectedOption] = useState("all");
  const [filteredData, setFilteredData] = useState([]);

  const [dateRangeFilter, setDateRangeFilter] = useState([]);

  const toast = useAlert();
  console.log(searchText);
  const columns = useMemo(
    () => [
      {
        title: "Attorney",
        dataIndex: "attorney",
      },
      {
        title: "Address",
        dataIndex: "address",
      },

      {
        title: "Action",
        dataIndex: "action",
        render: (text, record) => {
          return (
            <Space size="middle">
              <img
                onClick={() => {
                  closeUpdateAttModal();
                  setSelected(record);
                }}
                src="/images/editing.svg"
                alt="Edit"
                className="actionimg"
              />
              <img
                onClick={() => removeAttorney(record?._id)}
                className="actionimg"
                src="/images/dlt.svg"
                alt="delete"
              />
            </Space>
          );
        },
      },
    ],
    []
  );

  const removeAttorney = async (id) => {
    setLoading(true);
    try {
      await deleteAttorney(id);

      setLoading(false);
      setRender(!render);

      toast.success("Attorney is deleted successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  const [showAttr, setShowAttr] = useState(false);
  const showAttorneys = () => {
    setShowAttr(!showAttr);
  };
  const addAttornyClose = () => {
    setShowAttr(!showAttr);
  };
  const closeUpdateAttModal = () => {
    setShowUpdateAtt(!showUpdateAtt);
  };
  useEffect(() => {
    getAllAttornies();
    console.log("attornies", attornies);
  }, [render]);

  // All attorney patients

  const getAttorneyPatients = async () => {
    try {
      const mriAttorneyRecord = await getMriAttorny();
      setMriAttorny(mriAttorneyRecord);
    } catch (error) {
      toast.error(error.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getAttorneyPatients();
  }, []);

  // debugger

  const AttorneyData = {
    data: [
      {
        patients: [
          {
            name: "John Doe",
            date: "2023-05-15",
          },
          {
            name: "Jane Smith",
            date: "2023-04-20",
          },
          // Add more patient objects here...
        ],
      },
    ],
  };

  useEffect(() => {
    filterData(selectedOption);
  }, [selectedOption, mriAttorny]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    filterData(event.target.value);
  };

  const filterData = (option) => {
    const currentDate = new Date();

    switch (option) {
      case "thisWeek":
        setFilteredData(
          mriAttorny?.data.map((item) => ({
            ...item,
            patients: item.patients.filter((patient) => {
              const patientDate = new Date(patient.dateOfAdmission);
              const diffTime = Math.abs(currentDate - patientDate);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              return diffDays <= 7; // 7 days in a week
            }),
          }))
        );
        break;
      case "thisMonth":
        setFilteredData(
          mriAttorny.data.map((item) => ({
            ...item,
            patients: item.patients.filter((patient) => {
              const patientDate = new Date(patient.dateOfAdmission);
              return (
                patientDate.getFullYear() === currentDate.getFullYear() &&
                patientDate.getMonth() === currentDate.getMonth()
              );
            }),
          }))
        );
        break;
      case "thisYear":
        setFilteredData(
          mriAttorny.data.map((item) => ({
            ...item,
            patients: item.patients.filter((patient) => {
              const patientDate = new Date(patient.dateOfAdmission);
              return patientDate.getFullYear() === currentDate.getFullYear();
            }),
          }))
        );
        break;
      case "all":
        setFilteredData(
          mriAttorny?.data.map((item) => ({
            ...item,
            patients: item.patients.filter((patient) => {
              return patient;
            }),
          }))
        );
        break;
      default:
        setFilteredData([]);
        break;
    }
  };

  // PDF Print data function

  function printIt() {
    var win = window.open();

    win.document.write("<" + "html" + "><" + "body" + ">");
    win.document.write(`<div> 

    <div style="display: flex; justify-content:space-between;">
      <div style="width: 40%;">
          <h2 style="">
              Attorney Record
          </h2>   
      </div>
    </div>
`);
    filteredData?.forEach((item, idx) => {
      win.document.write(`<h3>${item?._id}</h3>
    <table  style=" width:100%">
      <tr style="border-bottom:1px solid black;">
      <th style="text-align: start;">Date</th>
        <th style="text-align: start;">Patient Name</th>
        <th style="text-align: start;">Case Type</th>
      </tr>
        `);

      item.patients?.forEach((value, idx) => {
        if (dateRangeFilter.length === 0 || isDateInRange(value?.createdAt)) {
          win.document.write(`
          <tr key={${idx}} style="border-bottom:1px solid black;">
            <td style="text-align: start; border-bottom:1px solid black;">${new Date(
              value?.createdAt
            ).toLocaleDateString("en-US")}</td>
            <td style="text-align: start; border-bottom:1px solid black;">${
              value?.firstName
            } ${value?.lastName}</td>
            <td style="text-align: start; border-bottom:1px solid black;">${
              value?.caseType
            }</td>
          </tr>
          
          `);
        }
      });
      win.document.write(`</table>`);
    });

    win.document.write(`</div>`);
    win.document.write("<" + "/body" + "><" + "/html" + ">");
    win.print();

    win.document.close();
    // win.close();
  }

  const isDateInRange = (date) => {
    if (dateRangeFilter.length !== 2) {
      return true;
    }

    const startDate = dateRangeFilter[0].startOf("day");
    const endDate = dateRangeFilter[1].endOf("day");
    const currentDate = moment(date);

    return currentDate.isBetween(startDate, endDate, null, "[]");
  };

  // print data pdf end

  const [filters, setFilters] = useState({
    createdAt: [],
  });

  // const dateRange = (e, type) => {
  //   setFilters({ ...filters, [type]: e });
  //   if (isMoment(e?.[0]) && isMoment(e?.[1])) {
  //     let start = moment(e[0]).format("YYYY-MM-DD");
  //     let end = moment(e[1]).format("YYYY-MM-DD");
  //     // setFilteredData(
  //     //
  //     // );
  //   } else {
  //     setFilteredData(mriAttorny.data);
  //   }
  // };

  const dateRange = (dates) => {
    setDateRangeFilter(dates);
    filterData(selectedOption, dates);
  };

  return (
    <>
      <UtilityTop
        name="Add attorney office"
        BtnName="Add Attorney"
        onClick={showAttorneys}
        placeholder="Search By Attorney Name"
      />
      {showAttr && (
        <AddAttorney
          onClick={addAttornyClose}
          nameModal="Add Attorney"
          name="Attorney"
          placeholder="Enter attorney name"
          endpoint="create-attorney"
          label="attorney"
          setSearchText={setSearchText}
        />
      )}
      {showUpdateAtt && (
        <UpdateAttorney
          onClick={closeUpdateAttModal}
          nameModal="Update Attorney"
          data={seleted}
          setSelected={setSelected}
          name="Attorney"
          placeholder="Enter attorney name"
          endpoint="create-attorney"
          label="attorney"
        />
      )}
      <div className="printPlusIcon-wrapper-attorney">
        <div className="dateRange drange">
          <label>Choose the date</label>
          <RangePicker
            name="createdAt"
            className="rangePickerFilter"
            value={dateRangeFilter}
            onChange={dateRange}
            allowClear={true}
          />
        </div>
        <div className="filtersWrapper">
          <div className="filters">
            <div>
              <label>
                <input
                  type="radio"
                  value="all"
                  checked={selectedOption === "all"}
                  onChange={handleOptionChange}
                />
                All
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="thisWeek"
                  checked={selectedOption === "thisWeek"}
                  onChange={handleOptionChange}
                />
                This Week
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="thisMonth"
                  checked={selectedOption === "thisMonth"}
                  onChange={handleOptionChange}
                />
                This Month
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="thisYear"
                  checked={selectedOption === "thisYear"}
                  onChange={handleOptionChange}
                />
                This Year
              </label>
            </div>
          </div>

          <div
            onClick={() => {
              printIt();
            }}
            className="printPlusIcon"
          >
            <img src="/images/printBlue.svg" alt="print" />
            <h5>Print Attorney Data</h5>
          </div>
        </div>
      </div>
      <div className="AttorneyOfficeList">
        <Table
          pagination={{ defaultPageSize: 5, showSizeChanger: true }}
          dataSource={attornies}
          loading={loading}
          columns={columns}
        />
      </div>
    </>
  );
};

export default AttorneyOffice;
